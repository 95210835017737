import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { List } from 'react-virtualized';
//redux configs 
import { connect } from "react-redux"
import * as actions from "../../../actions/actions"
//sass
import '../../../sass/montlyDashboard/_month-view-page.scss'
//components
import Calendar from './parts/Calendar/Calendar'
import Completions from './parts/Completions/Completions'
import UpdateProccessPopup from '../../updatePopups/UpdateProccessPopup'
import OnDragPopup from '../../updatePopups/OnDragPopup'
import SpreadingPopup from '../../updatePopups/SpreadingPopup'
import ReasonPopup from '../../updatePopups/ReasonPopup'
//functions
import { appGetOrders, montlyUpdateOrders, montlyUpdateOrdersForConst, updateProcess, getWarnings, setEndDateForProcess, getStartAndEndDatesForProcess, getCompletion } from '../../../functions/api/orders'
import { replaceProcessInState, removeFractionsOnBacklogDrag, removeBacklogDuplications } from './MonthViewFunctions'
import { isSameDay } from '../../../functions/general/general'
//api calls
import { generalGetRequest } from '../../../functions/api/general'
//external variables
import ConstantPopup from '../../updatePopups/ConstantPopup';
import jwt_decode from "jwt-decode";
import { PER_USER, VIEW_ONLY, NO_FINANCIALS } from '../../../tools/keys/variables'
import * as momentBusinessDays from 'moment-business-days';
import Loader from '../../LoaderNew/Loader'
import { mobileMaxWidth } from '../../../constants/responsive-pop-up';
import { isWeekChange, calculateEndDateAccordingToDuration, closePopupOnBackButton, dateMatch, monthlyDataView, onScroll, splitDataIntoPratForUI } from '../../../hooks/helper';
import { SERVICE } from '../../../constants';
momentBusinessDays.updateLocale('us', {
    workingWeekdays: [0, 1, 2, 3, 4]
});


class MonthView extends Component {

    constructor() {
        super()

        this.state = {
            counter: 0,
            page: 0,
            stopApi: false,
            orders: [],
            currentDate: moment().subtract(1, 'weeks').startOf("week"),
            datesArray: [],
            prevState: [],
            popup: false,
            updateApiBody: null,
            errPopupState: null,
            updateProcessPopup: false,
            spreadingPopup: false,
            selectedProcess: {},
            warnings: [],
            loader: false,
            reasonPopup: false,
            spreadingBodyData: {},
            constant_spred: false,
            simpleDateArray1: [],
            simpleDateArray: [],
            end_dateChanged: false,
            forCacheData: [],
            apiFetchingTrack: {

            },
            completionData:[],
            completionCount:0,
            completionPage: 0,
            tempIdRelatedToComp:null,
            prevCompletionData:[],
            spreading: false,
            apiDataLastStart: null,
            apiDataLastEnd: null,
            hasMoreData: true,
            loading: false,
        }
        window.onpopstate = (event) => closePopupOnBackButton(this.state.updateProcessPopup, (data) => {data && this.closeUpdateMenu()});
    }
    

    componentWillMount() {
        let pathname = window.location.pathname
        this.props.setRouteLocation(pathname) // to update the location path in the header tabs
        // dynamic factory name in url 
        const dynamicFactoryName = this.props.login.user.factory_name
        let initDate = this.props.match.params.date

        if (!initDate) {
            let searchQuery = window.location.search
            this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}${searchQuery ? searchQuery : ''}`)
            // this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}`)
            this.initializeData()
        } else {
            this.setState({
                currentDate: moment(initDate).startOf("week"),
            }, () => {
                this.initializeData()
            })
        }
    }

    
    shouldComponentUpdate(nextProps, nextState){
        if(
        (nextState.updateProcessPopup !== this.state.updateProcessPopup)
         || (this.state.loader !== nextState.loader)
         || (this.state.datesArray !== nextState.datesArray)
         || (nextProps != this.props)
         || (nextState.popup !== this.state.popup)
         || (nextState.spreadingPopup !== this.state.spreadingPopup)
         || (this.state.completionData !== nextState.completionData)
         )return true;
        return false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.login.lastAddedOrderId !== nextProps.login.lastAddedOrderId) {
            this.initializeData()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.props.process.show_bids !== prevProps.process.show_bids)
            ||
            (prevProps.location.search !== this.props.location.search)
            ||
            (JSON.stringify(prevProps.login.selectedDepartment) !== JSON.stringify(this.props.login.selectedDepartment))
            ||
            (JSON.stringify(prevProps.login.selectedManager) !== JSON.stringify(this.props.login.selectedManager))
            ||
            (prevProps.login.selectedUser) !== (this.props.login.selectedUser)
            ||
            (prevProps.process.show_first_uncomplete_process !== this.props.process.show_first_uncomplete_process)
        ) {
            this.initializeData()
        }

        if (prevProps.mainPopup.eddited_order_id !== this.props.mainPopup.eddited_order_id && this.props.mainPopup.eddited_order_id){
            this.repositionConstantAfterEditInPopup(this.props.mainPopup)

        }

    }



    repositionConstantAfterEditInPopup = async (information) => {
        // console.log(information,'vfuhwefbvuwhefbvhuwrbfvuhwefb')
        if (isSameDay(new Date(information.order_data.due_date), new Date(information.new_due_date_after_edit_from_reservation_popup)))
            return

        //open the popup here
        this.props.updateConstantPopup(true)

        //api call to retrieve last process
        let res = await generalGetRequest(`/system/order-process/get-last-order-process?order_id=${information.order_id}`)
        // console.log(res,'gnrugurugrgrugrrgjugr')
        let selectedProcess = {
            _id: res.result._id,
            order_number: information.order_data.order_number,
            order_id: information.order_id,
            due_date: new Date(information.order_data.due_date),
            date: new Date(information.new_due_date_after_edit_from_reservation_popup),
            client_name: information.order_data.client_name,
            warnings: [],
            process: res.result,
        }

        this.setState({
            updateApiBody: selectedProcess,
            spreadingBodyData: selectedProcess
        })

    }

    updateConstantToSpred = (boolean) => {
        this.setState({
            constant_spred: boolean
        })
    }

    deleteBacklogsDuplications = (process) => {

        let newState = removeBacklogDuplications(process, this.state.datesArray)
        this.setState({ datesArray: newState })
    }

    //  paginated data on scroll
    loadMoreMonthlyViewData = async (next) => {
        let from;
        let to;
        console.log("NEXT", next, "DATE", this.state.apiDataLastEnd, "DAY", this.state.apiDataLastStart); 
        this.setState({ loader: true })
        if (next) {
            from = moment(this.state.apiDataLastEnd).add(0, 'days')
            to = moment(this.state.apiDataLastEnd).add(27, 'days');
        } else {
            to = moment(this.state.apiDataLastStart).subtract(1, 'days');
            from = moment(to).subtract(27, 'days');
        }
        console.log("to", to.toDate(), "from", from.toDate())
        let urlOrderNumber = window.location.search.replace('?order_number=', '')
        let selectedDepartment = this.props.login.selectedDepartment._id
        let employee_id = this.props.login.selectedManager._id || '';
        let started = this.props.process.show_bids
        // let selectedUser = this.props.login.selectedUser
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        const promises = []
        let i=1;
        const ordersData = []
        if (next) {
            while (i <= 4) {
                promises.push(appGetOrders(moment(from).add(7 * (i-1), 'days')._d, moment(from).add(7 * i, 'days')._d, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
                i +=1 
            }
            const responses = await Promise.all(promises)
            for (const response of responses) {
                ordersData.push(...response)
            }
        } else {
            while ( i <= 4) {
                promises.push(appGetOrders(moment(to).subtract(7 * (i), 'days')._d, moment(to).subtract(7 * (i-1), 'days')._d, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
                i +=1 
            }
            const responses = await Promise.all(promises)
            for (const response of responses) {
                ordersData.unshift(...response)
            }
        }

        // appGetOrders(from, to, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process).then(apiOrders => {
        let _datesArray = this.buildDatesArray(moment(from).add(0, 'days'), ordersData);
        console.log("_datesArray_datesArray", _datesArray)
        let resetAndMore = true
        let _datesArr = monthlyDataView(_datesArray, {reset:resetAndMore, addMore: resetAndMore});
        console.log("_datesArr_datesArr_datesArr_datesArr________", _datesArr)
        const apiDataLastStartEndObj = {}
        if (next) {
            apiDataLastStartEndObj.apiDataLastEnd = to;
        } else {
            apiDataLastStartEndObj.apiDataLastStart = from;
        }
        this.setState({
            datesArray:  next ? this.state.datesArray.concat(_datesArr) : _datesArr.concat(this.state.datesArray),
            forCacheData: _datesArr,
            loader: false,
            apiFetchingTrack:{ order_number: urlOrderNumber},
            ...apiDataLastStartEndObj,
        })
        setTimeout(() => this.props.setCsvMonthly(_datesArray),1)
        // }).catch((err) => {this.setState({ loader: false }); console.error('errr: ',err)})

    }


    // to load the data first time
    initializeData = async () => {
        if (!this.state.spreadingPopup)
        this.setState({ loader: true })
        // this.buildWorkingDatesArray(this.state.currentDate)
        //set loader if spread popup is closed only
        let from = this.state.currentDate._d
        let to =  moment(this.state.currentDate).add(28, 'days')._d;
        let urlOrderNumber = window.location.search.replace('?order_number=', '')
        let selectedDepartment = this.props.login.selectedDepartment._id
        let employee_id = this.props.login.selectedManager._id || '';
        let started = this.props.process.show_bids
        // let selectedUser = this.props.login.selectedUser
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        const promises = []
        let i=1;
        while ( i <= 4) {
            promises.push(appGetOrders(moment(from).add(7 * (i-1), 'days')._d, moment(from).add(7 * i, 'days')._d, 'L', urlOrderNumber, selectedDepartment, employee_id, started, selectedUser,false,2,2, -1, undefined, current_process))
            i +=1 
        }
        const ordersData = []
        const responses = await Promise.all(promises)
        for (const response of responses) {
            ordersData.push(...response)
        }
        console.log("LAST ORDER> > > > > > > > > ", ordersData[ordersData.length-1])
        // ordersData.then(apiOrders => {
        let _datesArray = this.buildDatesArray(this.state.currentDate, ordersData);
        console.log("_datesArray_datesArray_datesArray_datesArray> > > > > > > > > ", _datesArray)
        let resetAndMore = !!urlOrderNumber || !!this.state.apiFetchingTrack?.order_number || !!this.state.apiFetchingTrack?.next_date ||  this.state.datesArray.length === 0;
        let _datesArr = monthlyDataView(_datesArray, {reset:resetAndMore, addMore: resetAndMore});
        console.log("asdsadasdasdadads> > > > > > > > > ", _datesArr)
        this.setState({datesArray: _datesArray,
            forCacheData: _datesArray,
            loader: false,
            apiFetchingTrack:{ order_number: urlOrderNumber},
            apiDataLastStart: from,
            apiDataLastEnd: to,
        })
        setTimeout(() => this.props.setCsvMonthly(_datesArray),1)
        // })
        // .catch((err) => {this.setState({ loader: false }); console.error('errr: ',err)})

        let page = 0;
        let limit = this.state.completionPage > 0 ? 50 + this.state.completionPage : 50;
        
        getCompletion(limit, page, 'L', selectedDepartment, urlOrderNumber, employee_id, selectedUser, current_process).then(res => {
            let {data=[], count} = res.result;
            this.setState({
                completionData:data,
                completionCount:count
            });
        }).catch(error => {console.error('completion api failed!')});
        // set api data to redux for order adding api call to add order that was added to UI
        let addOrderApiPayload = {
            from,
            to,
            view: 'L',
            department_id: this.props.login.selectedDepartment ? this.props.login.selectedDepartment._id : null
        }
        this.props.setAddOrderPayload(addOrderApiPayload)
    }
    
    getProcessById = async (process) => {
        const response = await generalGetRequest(`/system/order-process/get-by-id?_id=${process._id}&start_date=${process.process_date}`);
        return response
    }

    fourWeeksDateChange = (operator) => {
        this.setState({ stopApi: false, orders: [], from: false, to: false, counter: 0, apiFetchingTrack:{ next_date: true} }, () => {

            if (operator === 'inc') {
                this.setState(prevState => ({
                    currentDate: (prevState.currentDate).add(28, 'day'),
                    loader: true
                }), async () => {
                    // dynamic factory name in url 
                    const dynamicFactoryName = this.props.login.user.factory_name
                    let searchQuery = window.location.search
                    this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}${searchQuery ? searchQuery : ''}`)
                    this.initializeData()
                })
            } else {
                this.setState(prevState => ({
                    currentDate: (prevState.currentDate).subtract(28, 'day'),
                    loader: true
                }), async () => {
                    // dynamic factory name in url 
                    const dynamicFactoryName = this.props.login.user.factory_name
                    let searchQuery = window.location.search
                    this.props.history.push(`/${dynamicFactoryName}/monthly/${this.state.currentDate}${searchQuery ? searchQuery : ''}`)
                    this.initializeData()
                })
            }
        })
    }

    buildWorkingDatesArray = (date) => {
        let holidayDatesArray = this.props.login.user.holidays.map(item => moment(item.date).format('DD/MM/YYYY'))
        let daysOffArray = this.props.login.user.off_days.map(item => item)
        let simpleDateArrayLocal = []
        let weekDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
        for (let i = 0; i < 28; i++) {

            let incrementedDate = moment(date).add(i, 'days')
            let dayData = []
            let holidayIndex = -1
            let holiday = false
            let dayOff = false
            
            for (let j = 0; j < holidayDatesArray.length; j++) {
                if (holidayDatesArray[j] === incrementedDate.format('DD/MM/YYYY')) {
                    holidayIndex = j
                    holiday = true
                    break
                }
            }

            for (let j = 0; j < daysOffArray.length; j++) {
                if (daysOffArray[j] === moment(incrementedDate).day()) {
                    dayOff = true
                    break
                }
            }

            if(!holiday && !dayOff)
                simpleDateArrayLocal.push(new Date(moment(incrementedDate).add(1, 'days')._d).toISOString().split('T')[0])

            
        }

        // console.log("simpleDateArrayLocal: ", simpleDateArrayLocal);
        this.setState({
            simpleDateArray1: [],
            simpleDateArray1: simpleDateArrayLocal
        })
    }

    resetSimpleDateArray = () => {
        this.setState({ 
            simpleDateArray: [],
            simpleDateArray1: [] 
        });
    }

    prepareSimpleDateArray = () => {
        this.resetSimpleDateArray()
        if(this.state.datesArray.length > 0) {
            let _simple_date_Array = []
            var that = this
            that.state.datesArray.map(day=>{ 
                if(!day.dayOff && !_simple_date_Array.includes(day.parsedDate)) {
                    _simple_date_Array.push(day.parsedDate)
                }
            });
            this.setState({ 
                simpleDateArray: _simple_date_Array,
            });
        }
    }

    // findHoliday = (simple_date) => {
    //     const { off_days, holidays } = this.props.login.user;
    //     let year = new Date(this.state.currentDate._d).getFullYear()
    //     let month = new Date(this.state.currentDate._d).getMonth()
    //     const holidayArray = holidays.map(item => {
    //         if(new Date(item.date).getFullYear() === year && new Date(item.date).getMonth() === month)
    //         return moment(item.date).format('DD/MM/YYYY')
    //     });
    //     const z_index = []
    //      simple_date.map((item, index) => {
    //         if(index % 5 === 0){
    //             if(holidayArray.includes(item)){
    //                 let increase = 0;
    //                 for(let i = index; i<=index+4; i++){
    //                     if(!holidayArray.includes(simple_date[i])){
    //                         z_index.push(index+increase)
    //                         return
    //                     };
    //                     increase++
    //                 }
    //             }else{
    //                 z_index.push(index);
    //             }
    //         }
    //     })
    //     return z_index;
    // }

    buildDatesArray = (date, _orders) => {
        console.log("CURR DATE", date);
        const factoryType = this.props.login.user.type_of_factory;
        const isService = factoryType === SERVICE
        let holidayDatesArray = this.props.login.user.holidays.map(item => moment(item.date).format('DD/MM/YYYY'))
        let daysOffArray = this.props.login.user.off_days.map(item => item)
        let newDateDataArray = []
        let weekDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
        for (let i = 0; i < 28; i++) {

            let incrementedDate = moment(date).add(i, 'days')
            let dayData = []
            let holidayIndex = -1
            let dayOff = false

            _orders.map(order => {
                order.processes.map((proccess, index) => {
                    if (
                        (this.checkIfSameDay(proccess.process_date, incrementedDate._d) && !proccess.backlog)
                        ||
                        (proccess.backlog && this.checkIfSameDay(incrementedDate._d, moment(date)._d))
                    ) {
                        dayData.push({
                            order_number: proccess.order_number,
                            order_id: proccess.order_id,
                            due_date: proccess.due_date,
                            client_name: proccess.client_name,
                            warnings: proccess.warnings,
                            proccess: { ...proccess },
                            // address: proccess.address,
                            // city: proccess.city
                        })
                    }
                })
            })

       dayData = dayData.map((day) => {
                const {proccess} = day;
                return ({
                 ...day,
                    proccess: {
                        backlog: proccess.backlog,
                        _id: proccess._id,
                        done: proccess.done,
                        warnings: proccess.warnings,
                        fraction: proccess.fraction,
                        client_name: proccess.client_name,
                        remark: proccess.remark,
                        order_number: proccess.order_number,
                        process_name: proccess.process_name,
                        is_detached: proccess.is_detached,
                        process_date: proccess.process_date,
                        resource: proccess.resource,
                        process_id: proccess.process_id,
                        colors_by_users: proccess.colors_by_users,
                        color: proccess.color
                    }
                })
            })

            for (let j = 0; j < holidayDatesArray.length; j++) {
                if (holidayDatesArray[j] === incrementedDate.format('DD/MM/YYYY')) {
                    holidayIndex = j
                }
            }


            for (let j = 0; j < daysOffArray.length; j++) {
                if (daysOffArray[j] === moment(incrementedDate).day()) {
                    dayOff = true
                }
            }
            // sort the array moving all backlog = tru to the end to not interfare with dnd indexs
            dayData.sort((x, y) => {
                return x.proccess.order - y.proccess.order; 
            });
            if((i > 0 && i < 28) && !isService){
                if(newDateDataArray[i-1].dayData.length > dayData.length){
                    dayData.length > 0 && newDateDataArray[i-1].dayData.map((item, index) => {
                       if(index >= (dayData.length)){
                            dayData.push({
                                dummy:true,
                                order_number: `dummy${index}`,
                                order_id: `dummy${index}`,
                                due_date: item.proccess.due_date,
                                client_name: 'dummy-client',
                                warnings: false,
                                proccess: { 
                                    original_duration: item.proccess.original_duration,
                                    order: item.proccess.order
                                },
                            });
                       }
                    })
                }
            }
            dayData.sort((x, y) => {
                return x.proccess.order - y.proccess.order; 
            });

            newDateDataArray.push({
                date: JSON.parse(JSON.stringify(incrementedDate._d)),
                parsedDate: incrementedDate.format('DD/MM/YYYY'),
                dateName: weekDays[incrementedDate.day()] ? weekDays[incrementedDate.day()] : incrementedDate.format('dddd'),
                holiday: holidayIndex !== -1 ? this.props.login.user.holidays[holidayIndex] : null,
                dayOff,
                warnings: this.getWarningFromDayData(dayData, false, 0),
                dayData
            })

        }
        return newDateDataArray
    }

    getWarningFromDayData = (inputArray,status, index) => {
        if(status || inputArray.length === index)return status;
        let { warnings } = inputArray[index]
        return this.getWarningFromDayData(inputArray, warnings, ++index);
    }

    checkIfSameDay = (d1, d2) => {
        let dateOne = new Date(d1)
        let dateTwo = new Date(d2)
        return dateOne.getFullYear() === dateTwo.getFullYear() &&
            dateOne.getMonth() === dateTwo.getMonth() &&
            dateOne.getDate() === dateTwo.getDate();
    }

    handlePopupChoice = async (param, constantData = false,) => {

        let { constant_spred , end_dateChanged,spreading } = this.state;

        if (param === 'yes') {
            let newBody = {
                _id: (this.state.updateApiBody.process.original || this.state.updateApiBody._id),
                date: this.state.updateApiBody.endDate,
                view: this.state.updateApiBody.view,
                from: this.state.updateApiBody.from,
                to: this.state.updateApiBody.to,
                order_employee_id: null,
                department_id: this.props.login.selectedDepartment._id,
                order_id: this.state.updateApiBody.order_id
            }
            this.setState({ spreadingPopup: false, popup: false, loader: true });
            if (this.state.updateApiBody.toBacklog) { // If process goes to  backlog;
                let res = await updateProcess(this.state.updateApiBody.process)
                !res.ok && this.initializeData();
                this.setState({ loader: false, popup: false, prevState: [], prevCompletionData: [] });
                return;
            }

            let res = {ok:false};
            let is_same_date = dateMatch(new Date(this.state.updateApiBody.process.process_date)) === dateMatch(new Date(this.state.updateApiBody.date));
            if (this.state.updateApiBody.process.constant && !constant_spred) {
                let body = { ...this.state.updateApiBody }
                if (constantData) {
                    body.reason = constantData.reason.value;
                    body.approved_by = constantData.approveName.value;
                }
                await   (this.state.updateApiBody.process);
                !is_same_date && (res = await montlyUpdateOrdersForConst(body, this.props.login.selectedDepartment._id));
            } else {
                let body = { ...this.state.updateApiBody }
                if (constantData) {
                    body.reason = constantData.reason.value;
                    body.approved_by = constantData.approveName.value;
                }
                if(
                        !this.state.updateApiBody.process.constant || spreading
                    ){
                    await updateProcess(this.state.updateApiBody.process);  
                    !is_same_date && (res = await montlyUpdateOrders(body, spreading, this.props.login.selectedDepartment._id));
                }else this.setState({datesArray: this.state.prevState});
                this.updateConstantToSpred(false); 
            }
            if (end_dateChanged) {
                let {_id: id} = res.result || {};
                let val = id ? {...newBody, _id: id} : {...newBody};
                let spread = this.state.updateApiBody.process.constant ? false : spreading               
                await setEndDateForProcess(val, spread).then(res2 => {
                    res = res2;
                }).catch(err => console.error(err));
                this.setState({end_dateChanged: false})
            }
            if (res.ok) {
                this.initializeData();
                this.setState({ counter: 0, orders: [], stopApi: false, from: null, to: null});
                this.props.setCsvMonthly(this.state.datesArray);
            } else {
                this.setState({ errPopupState: res.result , loader: false, popup: !is_same_date || res.result });
            }
            this.setState({prevState: [], prevCompletionData: [], spreading: spreading && false});
        } else {
            let prevState = this.state.prevState.map(item => item)

            if (!prevState || prevState.length === 0)
                prevState = JSON.parse(JSON.stringify(this.state.datesArray))
            this.setState({ datesArray: prevState, popup: false, errPopupState: null, loader: false, spreading: spreading && false })
        }
        if(param == 'no')await this.initializeData();
    }

    onDragEnd = async (dndData) => {
        console.log('yyyyyyy')
        this.updateOpacity(1,dndData.draggableId)
        let { droppableId: destinationDropableId } = dndData.destination || {};
        let { droppableId: sourceDropableId } = dndData.source || {};
        if(!destinationDropableId || !sourceDropableId ) return;
        if (!dndData.destination || (dndData.source.droppableId === dndData.destination.droppableId)) return;
        const getColumnNumber = (colId) => colId.split('-')[1]
        const destinationColNumber = getColumnNumber(dndData.destination.droppableId)
        const sourceColNumber = getColumnNumber(dndData.source.droppableId)
        const privileges = this.props.login.user.privileges
        const isViewOnlyOrPerUser = privileges.find(privilege => privilege === VIEW_ONLY)
        if (destinationColNumber > sourceColNumber && isViewOnlyOrPerUser) {
            return
        }

        const { datesArray, completionData } = this.state
        let daySrcIndex, dayDestIndex
        //clone original array and making date obj to moment obj back again (was modified in json parse)
        let datesArrayCopy = JSON.parse(JSON.stringify(datesArray));
        let _completion = JSON.parse(JSON.stringify(completionData));
        this.setState({ loader: true });
        let destination_date = moment(dndData.destination.droppableId, 'DD-MM-YYYY').isValid() ? moment(dndData.destination.droppableId, 'DD-MM-YYYY')._d : moment(dndData.source.droppableId, 'DD-MM-YYYY')._d;
        if (dndData.source.droppableId === 'completions__droppable') {
            let current_process = (_completion.find(_i => _i.proccess._id === dndData.draggableId)).proccess;
            _completion = _completion.filter(_i => _i.proccess._id !== dndData.draggableId);
            current_process.backlog = false;
            let _destination = dndData.destination.droppableId;
            let body = {
                order_id: current_process.order_id,
                _id: dndData.draggableId,
                date: destination_date,
                from: moment(this.state.currentDate)._d,
                to: moment(this.state.currentDate).add(28, 'days')._d,
                view: 'L',
                process: current_process,
            };
            datesArrayCopy.map((item, indexInDay) => {
                if(item.parsedDate === _destination){
                    item.dayData.push({
                        client_name: current_process.client_name,
                        due_date: current_process.due_date,
                        order_id: current_process.order_id,
                        order_number: current_process.order_number,
                        proccess: {...current_process},
                        warnings: current_process.warnings
                    });
                    item.dayData.sort((a, b) => a.proccess.original_duration - b.proccess.original_duration);
                }
            })
            this.setState({
                prevState: this.state.datesArray,
                prevCompletionData: this.state.completionData,
                spreadingBodyData: body,
                datesArray: datesArrayCopy,
                completionData: _completion,
                updateApiBody: {
                    ...body,
                }
            }, async () => {
                let diff = moment(moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()).diff(moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate(), 'days')
                let end_date = moment(body.process.process_endDate).set({hour: 14, minute: 0, second: 0}).add(diff,'day').toDate();
                if (this.props.login.popupActivation && !body.process.constant) {
                    if ((isWeekChange(body.process.process_date, body.date)
                        || 
                        isWeekChange(body.process.process_endDate, end_date, diff > 0 ? 1 : -1)) && !body.process.is_detached) {
                        this.setState({ spreadingPopup: true, loader: false });
                    } else {
                        this.spreadingFalse();
                    }
                }else {
                    if (body.process.constant) {
                        this.props.updateConstantPopup(true)
                        this.setState({ loader: false, spreadingBodyData: body })
                    } else {
                        this.setState({ spreadingPopup: true, spreadingBodyData: body, loader: false })
                    }
                }
            })
            return
        }

        //search source in dataArray (fit to explorer)
        for (let i = 0; i < datesArrayCopy.length; i++) {
            if (datesArrayCopy[i].parsedDate === dndData.source.droppableId) {
                daySrcIndex = i
                if (daySrcIndex && dayDestIndex) break;
            }

            if (datesArrayCopy[i].parsedDate === dndData.destination.droppableId) {
                dayDestIndex = i
                if (daySrcIndex && dayDestIndex) break;
            }
        }
        // create body to send to API
        let main_process = datesArrayCopy.map(item => item.dayData.filter(subitem => {
                                    return subitem.proccess._id === dndData.draggableId
                              })).filter(item => item.length > 0)[0][0];
        let current_process = main_process.proccess;

        let body = {
            order_id: current_process.order_id,
            _id: dndData.draggableId,
            date: destination_date,
            from: moment(this.state.currentDate)._d,
            to: moment(this.state.currentDate).add(28, 'days')._d,
            view: 'L',
            toBacklog: dndData.destination.droppableId === 'completions__droppable' ? true : false,
            process: current_process,
        }
        //search order_id in case source index is in completions
        if (!body.order_id)
        datesArrayCopy.map(day => {
            day.dayData.map(proccess => {
                if (proccess.proccess._id === dndData.draggableId)
                    body.order_id = proccess.order_id
            })
        })
        this.setState({ updateApiBody: body })
        
        //handle situation when deastination of drag element in completions (to completions)
        if (dndData.destination.droppableId === 'completions__droppable') {
            let originalId;
            let { order_id:o_id, process_id:p_id , _id} = current_process
            let lastIndex = daySrcIndex + (current_process.original_duration || 0)
            while(daySrcIndex < datesArrayCopy.length){
                datesArrayCopy[daySrcIndex].dayData = datesArrayCopy[daySrcIndex]?.dayData.filter((item) => {
                    let {order_id, process_id} = item.proccess;
                    if(o_id === order_id && p_id === process_id){
                        o_id = order_id; p_id = process_id;
                        item.proccess.backlog = true;
                        originalId = item.proccess.hasOwnProperty('original') ? item.proccess.original : item.proccess._id;
                        if (originalId) {
                            item.proccess._id = originalId
                        }
                    }else return item;
                }) || [];
                daySrcIndex++;
            }
            _completion.filter(i => i.proccess._id === main_process.proccess._id).length === 0 && _completion.push({...main_process})
            _completion.sort((a,b) => new Date(a.proccess.process_date).getTime() - new Date(b.proccess.process_date).getTime());
            this.setState({ 
                datesArray: datesArrayCopy,
                completionData: _completion,
                tempIdRelatedToComp: _id,
                prevState: this.state.datesArray }, async() => {
                this.handlePopupChoice('yes',)
            })

            return
        }

        // get item to push to new state
        let valueToPush = datesArrayCopy[daySrcIndex].dayData.filter((item, index) => {
            return dndData.source.index === index
        })[0]

        //push item to its new place 
        datesArrayCopy[dayDestIndex].dayData.splice(dndData.destination.index, 0, valueToPush)
        //remove item from source in array in order to push it to its new location according to DND
        datesArrayCopy[daySrcIndex].dayData.splice(dndData.source.index, 1)

        // sort the array moving all backlog = tru to the end to not interfare with dnd indexs
        datesArrayCopy = datesArrayCopy.map(item => {
            let newItemDayData = item.dayData.sort((x, y) => {
                return (x.proccess.backlog === y.proccess.backlog) ? 0 : x.proccess.backlog ? 1 : -1;
            })
            item.dayData = newItemDayData
            return item
        })

        //save prevstate to revert if user clicks cancel
        this.setState({
            prevState: this.state.datesArray,
            spreadingBodyData: body,
        }, () => {
            this.setState({ datesArray: datesArrayCopy })
        })

        let diff = moment(moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()).diff(moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate(), 'days')
        let end_date = moment(body.process.process_endDate).set({hour: 14, minute: 0, second: 0}).add(diff,'day').toDate();
        if (this.props.login.popupActivation && !body.process.constant) {
            if (    (isWeekChange(body.process.process_date, body.date)
                    || 
                    isWeekChange(body.process.process_endDate, end_date, diff > 0 ? 1 : -1)) 
                    && !body.process.is_detached
                ) {
                this.setState({ spreadingPopup: true, loader: false });
            } else {
                this.spreadingFalse();
            }
        } else {
            //check for warnings
            if (body.process.constant) {
                this.props.updateConstantPopup(true)
                this.setState({ loader: false, spreadingBodyData: body })
            } else {
                this.setState({ spreadingPopup: true, spreadingBodyData: body, loader: false })
            }
        }


    }
    //new for constant change - 
    resetReposition = () => {
        this.setState({ popup: true })
    }

    spreadingFalse = async () => {
        this.setState({ loader: true, spreadingPopup: false })
        let body = { ...this.state.spreadingBodyData }
        let warningsApi = await getWarnings(body)
        if (warningsApi.ok) {
            if (warningsApi.result.length > 0) {
                this.setState({ popup: true, loader: false, warnings: warningsApi.result, spreadingPopup: false })
            } else {
                this.handlePopupChoice('yes')
            }
        } else {
            this.setState({ popup: true, loader: false, errPopupState: warningsApi.result, spreadingPopup: false })
        }
    }

    spreadingTrue = async () => {
        this.setState({spreadingPopup: false})
        const { spreadingBodyData } = this.state
        let warningsApi = await getWarnings(spreadingBodyData, true)
        if (warningsApi.ok) {
            if (warningsApi && warningsApi.result && warningsApi.result.length > 0) {
                this.setState({ popup: true, loader: false, warnings: warningsApi.result })
            } else {
                this.setState({
                    spreading: true
                },() => {
                    if (this.props.login.user.reason_popup) {
                        this.updateWarningPopup(true)
                    }else{
                        this.handlePopupChoice('yes', false)
                    }
                })
            }
        } else {
            this.setState({ popup: true, loader: false, errPopupState: warningsApi.result })
        }


        // if (moment(spreadingBodyData.date).isSameOrBefore(moment(spreadingBodyData.process.process_date))) {
        //     this.setState({ loader: true, spreadingPopup: false })
        //     //check for warnings
        //     let warningsApi = await getWarnings(spreadingBodyData, true)
        //     if (warningsApi.ok) {
        //         if (warningsApi && warningsApi.result && warningsApi.result.length > 0) {
        //             this.setState({ popup: true, loader: false, warnings: warningsApi.result })
        //         } else {
        //             //old- constant change:
        //             // if(spreadingBodyData.process.constant){
        //             //     this.setState({popup:true, loader:false})
        //             // } else {
        //             this.handlePopupChoice('yes', false, true)
        //             // }
        //         }

        //     } else {
        //         this.setState({ popup: true, loader: false, errPopupState: warningsApi.result })
        //     }
        // } else {
        //     if (this.props.login.user.reason_popup) {
        //         this.updateWarningPopup(true)
        //     }else{
        //         this.handlePopupChoice('yes')
        //         this.setState({ popup: false, spreadingPopup: false })
        //     }
        // }
    }

    handleSpreadWithReason = async (data) => {

        let body = { ...this.state.spreadingBodyData }
        body.reason = data.reason.value
        body.approved_by = data.approveName.value
        //check for warnings

        let warningsApi = await getWarnings(body, true)
        if (warningsApi.ok) {
            if (warningsApi && warningsApi.result && warningsApi.result.length > 0) {
                this.setState({ popup: true, loader: false, warnings: warningsApi.result, reasonPopup: false })
            } else {
                this.setState({ updateApiBody: body, reasonPopup: false, spreading: true }, () => {
                    this.handlePopupChoice('yes', false)
                })
                // }
            }

        } else {
            this.setState({ popup: true, loader: false, errPopupState: warningsApi.result, reasonPopup: false })
        }

    }

    cancelReasonPopup = () => {
        let datesArrayCopy = JSON.parse(JSON.stringify(this.state.prevState))
        this.setState({ reasonPopup: false, datesArray: datesArrayCopy })
    }

    closeUpdateMenu = () => {
        this.setState({
            updateProcessPopup: false,
            selectedProcess: {},
        })
    }

    submitUpdatesFromPopup = async (body, constant, refetchData, newProcess = null) => {
        if (
            moment(body.date).isSame(moment(body.process.process_date)) && 
            !body.endDate
        ) {
            this.setState({updateProcessPopup: false, } , async () => {
                this.initializeData()
            });return;
        }
        const { end_dateChanged } = body;
        if(end_dateChanged) delete body.end_dateChanged;
        let diff = moment(moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()).diff(moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate(), 'days')
        let end_date = moment(body.process.process_endDate).set({hour: 14, minute: 0, second: 0}).add(diff,'day').toDate();
        this.setState({spreadingBodyData: body, updateApiBody: body, updateProcessPopup: false,end_dateChanged } , async () => {
            if(body.process.constant){
                let warningRes = await getWarnings(body);
                if (warningRes.ok && warningRes.result.length <= 0) {
                    this.handlePopupChoice('yes')
                } else {
                    this.setState({errPopupState: warningRes.result , popup: true,});
                } 
            }else {
                if (body.endDate && !body?.process?.is_detached && isWeekChange(body?.process?.process_endDate, body.endDate)
                ||
                isWeekChange(body.process.process_endDate, end_date, diff > 0 ? 1 : -1)) {
                    this.setState({ spreadingPopup: true});
                } else {
                    this.spreadingFalse();
                }
            }
        });
        // let newBody = {
        //     _id: body._id,
        //     date: body.endDate,
        //     view: body.view,
        //     from: body.from,
        //     to: body.to,
        //     order_employee_id: null,
        //     department_id: this.props.login.selectedDepartment._id
        // }
        // if(end_dateChanged)this.setState({ end_dateChanged: true})
        // console.log('udpateBody: ',this.state.updateApiBody)
        // let res;
        // if (constant) {
        //     let warningRes = await getWarnings(body)
        //     if (warningRes.ok) {
        //         if (warningRes.result.length > 0) {
        //             this.setState({ popup: true, updateApiBody: body, updateProcessPopup: false, warnings: warningRes.result })
        //         } else {
        //             this.setState({ spreadingBodyData: body, updateApiBody: body, updateProcessPopup: false })
        //             this.props.updateConstantPopup(true)
        //         }
        //     } else {
        //         let datesArrayCopy = JSON.parse(JSON.stringify(this.state.datesArray))
        //         this.setState({ errPopupState: res.result, popup: true, updateApiBody: body, prevState: datesArrayCopy, updateProcessPopup: false })
        //     }

        // } else {
        //     let movedToDate = moment(body.date).set({ hour: 14, minute: 0, second: 0 }).toDate()
        //     let currentDate = moment(body.process.process_date).set({ hour: 14, minute: 0, second: 0 }).toDate()
        //     let diff = moment(movedToDate).diff(currentDate, 'days')
        //     if (Math.abs(diff) < 7) {
        //         this.setState({spreadingBodyData: body, updateApiBody: body, updateProcessPopup: false });
        //         this.spreadingFalse()
        //     } else {
        //         this.setState({ spreadingPopup: true, spreadingBodyData: body, updateApiBody: body, updateProcessPopup: false });
        //     }
            
        // }

    }

    initProccessUpdatePopup = (data, date) => {
        let body = {
            from: moment(this.state.currentDate)._d,
            to: moment(this.state.currentDate).add(28, 'days')._d,
            view: "L",
            toBacklog: false,
            order_id: data.order_id,
            _id: data.proccess._id,
            process: data.proccess,
            date
        }
        this.setState({
            selectedProcess: data
        }, () => {
            this.setState({ updateProcessPopup: true })
        })

    }

    saveNewSelectedProcess = (process) => {
        let selectedProcess = { ...this.state.selectedProcess }
        selectedProcess.proccess = { ...process }
        this.setState({
            selectedProcess
        })
    }

    cancleSpreading = () => {
        let prevState = JSON.parse(JSON.stringify(this.state.prevState))
        let prevComp = JSON.parse(JSON.stringify(this.state.prevCompletionData))
        if(prevComp && prevComp.length > 0)this.setState({ completionData: prevComp});
        if (prevState && prevState.length > 0)this.setState({ datesArray: prevState })
        this.setState({ spreadingPopup: false });
        this.props.updateConstantPopup(false);
    }

    backToToday = () => {
        this.setState({ stopApi: false, orders: [], from: false, to: false, counter: 0 })
        // dynamic factory name in url 
        const dynamicFactoryName = this.props.login.user.factory_name
        let search = window.location.search
        this.props.history.push(`/${dynamicFactoryName}/monthly/${moment().toDate()}${search ? search : ''}`)
        this.setState({ currentDate: moment().startOf('week') }, () => {
            this.initializeData()
        })
    }

    updateWarningPopup = (value) => this.setState({popup: value})

    onBeforeCapture = ({ draggableId }) => {
        // let drag_element = document.getElementById(`${draggableId}x1y2`);
        // let width = drag_element?.style?.width.split('');
        // if(width) {
        //     if(Number(width[width.length - 2]) > 1){
        //         width[width.length - 2] = 1;
        //         drag_element.style.width = width.join('');
        //         this.updateOpacity(0.3,draggableId);
        //     }
        // }
    }

    updateOpacity = (opacity, draggableId) => {
        // let drag_element = document.getElementById(`${draggableId}x1y2`);
        // let class_name = drag_element?.classList;
        // if(!class_name)return;
        // class_name = class_name[class_name.length - 1];
        // if(!class_name.includes('xix'))class_name = class_name + 'xix';
        // let class_element = document.getElementsByClassName(class_name);
        // class_element = [...class_element];
        // if(class_element.length > 0){
        //     class_element.map(item => {
        //         item.style.opacity = opacity;
        //     });
        // }
    }

    getMoreDataOnScroll = () => {
        // console.log('yes this is called');
        // let _datesArr = monthlyDataView(this.state.forCacheData,false,() =>  this.setState({loader: true}));
        // _datesArr.length > 0 && this.setState({datesArray: _datesArr});
        // _datesArr.length > 0 && setTimeout(() => this.setState({loader: false}),0)
    }

    handleScroll = (event) => {
        const {
            scrollHeight,
            scrollTop,
            clientHeight,
        }  = event.target;

        if (scrollTop === 0 && !this.state.loading) {
            this.loadMoreMonthlyViewData(false)
        } else if ((scrollTop + clientHeight >=  scrollHeight - 50) && !this.state.loading) {

            this.loadMoreMonthlyViewData(true)
        }
    }

    addMoreCompletion = async() => {    
        let limit = 50;
        let {completionPage , completionData, completionCount, tempIdRelatedToComp} = this.state;
        if(limit + completionPage >= completionCount)return true;
        let selectedDepartment = this.props.login.selectedDepartment._id;
        let urlOrderNumber = window.location.search.replace('?order_number=', '');
        let employee_id = this.props.login.selectedManager._id;
        const user_id = jwt_decode(this.props.login.user.token)._id
        const selectedUser = this.props.login.user.privileges.includes(PER_USER) ? user_id : this.props.login.selectedUser;
        let current_process = this.props.process.show_first_uncomplete_process;
        this.setState({completionPage: limit + completionPage, loader: true});
        return getCompletion(limit, limit + completionPage, 'L', selectedDepartment, urlOrderNumber, employee_id, selectedUser, current_process).then(res => {
            let {data=[], count} = res.result;
            let filterCompletion = tempIdRelatedToComp ? completionData.filter(_i => _i.proccess._id !== tempIdRelatedToComp) : completionData;
            data = [...filterCompletion,...data].sort((a,b) => {
                let first = new Date(a.proccess.process_date).getTime();
                let second = new Date(b.proccess.process_date).getTime();
                if(first === second)return a.proccess.order - b.proccess.order;
                else return first - second;
            });
            this.setState({
                completionData:data,
                tempIdRelatedToComp:null,
                loader: false
            })
            return data;
        }).catch(error => {console.error('completion api failed!')});
    }

   loadMoreFunction = () => {
    this.loadMoreMonthlyViewData(true);
    //     this.setState({ loading: true })
    // setTimeout(() => {
    //     if (this.state.datesArray.length > 150) {
    //         this.setState({ loading: false, hasMoreData: false });
    //     } else {
    //         this.setState(prevState => ({
    //             datesArray: prevState.datesArray.concat(prevState.datesArray),
    //             loading: false,
    //             hasMoreData: true
    //         }));
    //     }
    // }, 500);
   
   }




    render() {
        const { currentDate,
            orders,
            datesArray,
            popup,
            errPopupState,
            updateProcessPopup,
            selectedProcess,
            loader,
            warnings,
            spreadingPopup,
            counter, 
            simpleDateArray,
            completionData,
        } = this.state;
        const { off_days, holidays, popupActivation } = this.props.login.user
        let disableDrag = this.props.login && Object.keys(this.props.login.user).length > 0 && this.props.login.user.privileges.includes(PER_USER) ? true : false
        const dynamicFactoryName = this.props.login.user.factory_name;
        const user_or_order_type = this.props.login.user.select_order_process_color_by;

        return (
            <Fragment>
                <div className="month-view-page">

                    {spreadingPopup ?
                        <SpreadingPopup
                            spreadingFalse={this.spreadingFalse}
                            spreadingTrue={this.spreadingTrue}
                            cancleSpreading={this.cancleSpreading}
                        />
                        :
                        null
                    }
                    {updateProcessPopup ?
                        <UpdateProccessPopup
                            warningApiPayload={this.state.updateApiBody}
                            selectedProcess={selectedProcess}
                            view={'L'}
                            closeUpdateMenu={this.closeUpdateMenu}
                            submitUpdatesFromPopup={this.submitUpdatesFromPopup}
                            offDays={off_days}
                            holidays={holidays}
                            saveNewSelectedProcess={this.saveNewSelectedProcess}
                            deleteBacklogsDuplications={this.deleteBacklogsDuplications}
                        />
                        :
                        null
                    }

                    {popup ?
                        <OnDragPopup
                            afterConfirmationError={errPopupState}
                            popupActionHandler={this.handlePopupChoice}
                            warningApiPayload={this.state.updateApiBody}
                            warnings={warnings}
                        />
                        :
                        null
                    }

                    {this.props.orders.constant_popup ?

                        <ConstantPopup
                            updateConstantToSpred={this.updateConstantToSpred}
                            resetReposition={this.resetReposition}
                            spreadingFalse={this.spreadingFalse}
                            spreadingTrue={this.spreadingTrue}
                            cancleSpreading={this.cancleSpreading}
                            popupActionHandler={this.handlePopupChoice}
                            handleWarningPopup = {this.updateWarningPopup}
                        />
                        :
                        null
                    }

                    <DragDropContext 
                        onDragEnd={this.onDragEnd} 
                        onBeforeCapture={this.onBeforeCapture}
                    >

                        <Calendar
                            initProccessUpdatePopup={this.initProccessUpdatePopup}
                            datesArray={datesArray}
                            checkIfSameDay={this.checkIfSameDay}
                            currentSelectedDate={currentDate}
                            buildDatesArray={this.buildDatesArray}
                            fourWeeksDateChange={this.fourWeeksDateChange}
                            orders={orders}
                            loader={loader}
                            daysOffNumber={off_days ? off_days.length : 0}
                            daysOff={off_days}
                            disableDrag={false}
                            backToToday={this.backToToday}
                            isCurrentProcess={this.props.process.show_first_uncomplete_process}
                            factoryName={dynamicFactoryName}
                            weeksLoader={counter}
                            userData={this.props.login.user}
                            simpleDateArray={simpleDateArray}
                            user_or_order_type={user_or_order_type}
                            getMoreDataOnScroll={onScroll(this.getMoreDataOnScroll)}
                            loadMore={this.loadMoreFunction}
                            hasMoreData={this.state.hasMoreData}
                            loading={this.state.loading}
                            handleScroll={this.handleScroll}
                            apiStart={this.state.apiDataLastStart}
                            apiEnd={this.state.apiDataLastEnd}
                        />

                        <Completions
                            initProccessUpdatePopup={this.initProccessUpdatePopup}
                            completionArrays={completionData}
                            buildDatesArray={this.buildDatesArray}
                            orders={orders}
                            daysOffNumber={off_days ? off_days.length : 0}
                            addMoreCompletion={onScroll(this.addMoreCompletion)}
                        />
                    </DragDropContext>

                </div>

                {
                    loader && <Loader />
                }

            </Fragment>
        )
    }

}


function mapStateToProps({ login, process, orders, mainPopup }) {
    return { login, process, orders, mainPopup }
}
export default withRouter(connect(mapStateToProps, actions)(MonthView))
