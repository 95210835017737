import { ADD_CHECK_LIST, ADD_SYSTEM, DELETE_SYSTEM, EDIT_SYSTEM, GET_CHECK_LIST_BY_SYSTEM_ID, GET_SYSTEMS_BY_ORDER_ID, GET_SYSTEMS_CHECK_LIST_MOBILE, GET_SYSTEMS_CHECK_LIST_TABLET, GET_SYSTEMS_FOR_ACCORDION, MOVE_SYSTEM_ROW, REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH, SELECTED_SYSTEM_BY_SYSTEM_ID, SET_SYSTEMS_FOR_ACCORDION, SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH } from "../../constants/customer/systemConstants";
import { getOrderSystemsList } from "../../functions/api/orders";
import * as types from "../types";


const getCustomersDataRequest = () => {
    return {
        type: types.GET_CUSTOMERS_INFO_REQUEST
    }
}
const getCustomersDataSuccess = (data) => {
    return {
        type: types.GET_CUSTOMERS_INFO_SUCCESS,
        payload: data
    }
}

const getCustomersDataError = (err) => {
    return {
        type: types.GET_CUSTOMERS_INFO_ERROR,
        error: err
    }
}

export const systemActionsCreator = {
    getSystemsCheckListMobile: ()=>({type: GET_SYSTEMS_CHECK_LIST_MOBILE}),
    getSystemsCheckListTablet: () => ({ type: GET_SYSTEMS_CHECK_LIST_TABLET}),
    getSystemsByOrderId: (orderId) => {
        return async (dispatch) =>{
           const response = await getOrderSystemsList(orderId);
            dispatch({ type: GET_SYSTEMS_BY_ORDER_ID, payload: response.result })
            dispatch({ type: SET_SYSTEMS_FOR_ACCORDION, payload: response.result })
        }
    },

    getSystemsForAccordion: () =>{ 
        return({type: GET_SYSTEMS_FOR_ACCORDION})},
    addSystem: (system) => ({
        type: ADD_SYSTEM, payload: system
    }),
    selectedSystemBySystemId: (selectedBy) => {
        return ({ type: SELECTED_SYSTEM_BY_SYSTEM_ID, payload: selectedBy })
    },
    editSystem : (system) => ({
        type: EDIT_SYSTEM, payload: system
    }),
    deleteSystem: (deleteBy) => {
       return ({
           type: DELETE_SYSTEM, payload: deleteBy
    })},
    getCheckListBySystemId: (systemId) => {
        return({
        type: GET_CHECK_LIST_BY_SYSTEM_ID, payload: systemId
    })},
    moveSystemRow: (currentRowInfo) => ({ type: MOVE_SYSTEM_ROW, payload: currentRowInfo }),
    addCheckList: () => ({type: ADD_CHECK_LIST}),
    setSystemForAccordion: () => {
        return ({
            type: SET_SYSTEMS_FOR_ACCORDION,
        })
    },
    setSystemsOpeningPath: (system) => ({ type: SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH, payload: system }),
    removeSystemFromOpeningPath: (system) => ({ type: REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH, payload: system }),

    
}