import React from 'react';
import "./AddMeasurements.scss";
import Button from '../../../Buttons/Button';
import "../../../TabletView/PopUps/AddSystem/AddSystemPopUp.scss";
import { Grid } from '@material-ui/core';
import Input from '../../../InputFields/Input';
import MeasurementInput from '../../../InputFields/MeasurementInput/MeasurmentInput';
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { PRODUCT_FORM } from '../../../../../constants/translations/customersPage';
import { ADD, CANCEL } from '../../../../../constants/translations/review-popup';


const add = {
    name: ADD,
    width: '42%',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '12px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    height: '36px',
    borderRadius: '20px',
}
const cancel = {
    name: CANCEL,
    width: '42%',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '12px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    height: '36px',
    borderRadius: '20px',
}
const AddDesktop = {
    ...add,
    fontSize: '16px',
    height: '52px',
};
const cancelDesktop = {
    ...cancel,
    fontSize: '16px',
    height: '52px',
}
const addTablet = {
    ...add,
    fontSize: '24px',
    height: '70px',
    borderRadius: '90px'
};
const cancelTablet = {
    ...cancel,
    fontSize: '24px',
    height: '70px',
    borderRadius: '90px'
}

const inputData = {
    label: '',
    placeholder: '_ _',
    value: 'Enter',
    type: 'number',
    width: '60%'
};
const inputDataDesktop = {
    width: {
        label: 'Width',
        placeholder: '6',
        value: 'Enter',
        type: 'number',
        width: '100%',
        height: '52px',
    },
    height: {
        label: 'Height',
        placeholder: '6',
        value: 'Enter',
        type: 'number',
        width: '100%',
        height: '52px',
    }
};


export default function AddMeasurements(props) {
    const {handleShowPopUp, view } = props;

    return (
        <Grid className='addSystem__main' style={{ left: view === 'desktop' && '15%' }}>
            <Grid xs={12}>
                <h4 className='addSystem__title'>{POP_UPS_TRANSLATION.ADD_MEASUREMENTS}</h4>
            </Grid>
            <Grid className='addSystem__uploadedInfo'>
                <Grid xs={12} spacing={0} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.SYSTEM_NAME}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>{POP_UPS_TRANSLATION.SYSTEM_NAME_VALUE}</span></Grid>
                </Grid>
                <Grid xs={12} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.FLOOR}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>6</span></Grid>
                </Grid>
                <Grid xs={12} spacing={0} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.LOCATION_DESCRIPTION}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>6</span></Grid>
                </Grid>
            </Grid>

            {
                view === 'desktop' ? <Grid xs={12} className='addSystemMeasurement__inputRow'>
                    <Grid xs={6}>
                        <Input data={inputDataDesktop.width} view={view}/>
                    </Grid>
                    <Grid xs={6}>
                        <Input data={inputDataDesktop.width} view={view} />
                    </Grid>

                </Grid> : <>
                    <Grid xs={12} className='addSystemMeasurement__measurement'>
                        <label>{POP_UPS_TRANSLATION.PLEASE_ENTER}{PRODUCT_FORM.WIDTH}, {POP_UPS_TRANSLATION.CM}</label>
                        <Grid className='addSystemMeasurement__inputs'>
                            <Grid className='addSystemMeasurement__rightInput'> <MeasurementInput data={inputData} view={view}/></Grid>
                            <div className='addSystemMeasurement__dot'>&#8226;</div>
                                <Grid className='addSystemMeasurement__leftInput'> <MeasurementInput data={inputData} view={view} /></Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className='addSystemMeasurement__measurement'>
                        <label>{POP_UPS_TRANSLATION.PLEASE_ENTER}{PRODUCT_FORM.HEIGHT}, {POP_UPS_TRANSLATION.CM}</label>
                        <Grid className='addSystemMeasurement__inputs'>
                                <Grid className='addSystemMeasurement__rightInput'> <MeasurementInput data={inputData} view={view}/></Grid>
                            <div className='addSystemMeasurement__dot'>&#8226;</div>
                                <Grid className='addSystemMeasurement__leftInput'> <MeasurementInput data={inputData} view={view}/></Grid>
                        </Grid>
                    </Grid>
                </>
            }

            {
                view === 'desktop' ? <Grid sx={12} className='addSystem__btn__container' style={{ position: view === 'desktop' && 'static' }}>
                    <Button data={AddDesktop} handleShowPopUp={handleShowPopUp} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancelDesktop} handleShowPopUp={handleShowPopUp} />
                </Grid> : 
                    <Grid sx={12} className='addSystem__btn__container'>
                        <Button data={add} handleShowPopUp={handleShowPopUp} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancel} handleShowPopUp={handleShowPopUp} />
                    </Grid>
            }
        </Grid>
    )
}
