import React, { useState } from 'react'
import MobileHeader from '../Header/MobileHeader'
import Footer from '../Footer/Footer'
import { Grid } from '@material-ui/core';
import "./Systems.scss";
import { SYSTEMS } from '../../../../constants/translations/review-popup';
import { MOBILE_LANDING_PAGE } from '../../../../constants/translations/customer/mobile/mobileView';
import SystemTab from '../../CustomerDetails/SystemTab/SystemTab';


export default function Systems(props) {
    const {
        handleClose, view, process, closePopup
    } = props;
    const footerData = {
        btnName: MOBILE_LANDING_PAGE.SYSTEM,
        field: 'system'
    }
    const [showPopUp, setShowPopUp] = useState(false);

    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }

    return (
        <Grid container className='system__main' style={{ background: view === 'mobile' && '#eeeeee' }}>
            <MobileHeader
                headerName={SYSTEMS}
                processName={process && process.process_name || 'Installation'}
                customerName={process && process.client_name || 'Zain bator'}
                orderNumber={process && process.order_number || ' S02100004B'}
                city={process && process.city}
                view={view}
                closePopup={closePopup}
                handleClose={handleClose}
            />
            <div className='system__dataContainer'>
                <SystemTab view={view} />
            </div>
            <Footer data={footerData} handleClose={handleClose} />
        </Grid>
    )
}
