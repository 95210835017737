

import {Grid} from '@material-ui/core'
import React, { useRef } from 'react'
import "../AddFilePopUp/AddFilePopUp.scss";
import "./DigitalSignaturePopUp.scss";
import Button from '../../Buttons/Button';
import ReactSignatureCanvas from 'react-signature-canvas';
import { CANCEL} from '../../../../constants/translations/review-popup'
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { uploadSignature } from '../../../../functions/api/orders';
import { dataURLtoBlob } from '../../../../utils';


const download = {
    name: POP_UPS_TRANSLATION.DOWNLOAD,
    width: '28%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    buttonName: 'AddIcon',
}
const cancel = {
    name: CANCEL,
    width: '28%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
}
const clearBtn = {
    name: POP_UPS_TRANSLATION.CLEAR_OUT,
    width: '100%',
    height: '34px',
    backgroundColor: '#FFF4F6',
    color: "#F67288",
    fontSize: '10px',
    fontWeight: 500,
    borderColor: '#fff',
    icon: true,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    buttonName: 'delete'
}
const downloadBtnMobile = {
    ...download,
    name: 'Add',
    fontSize: '12px',
    height: '36px',
    width: '42%',
};
const cancelBtnMobile = {
    ...cancel,
    fontSize: '12px',
    height: '36px',
    width: '42%',
}
const clearBtnDesktop = {
    ...clearBtn,
    fontSize: '20px',
    height: '56px',
}

export default function DigitalSignaturePopUp(props) {
    const { handleShowPopUp, view, orderId, handelSetUrl } = props;
    const canvasRef = useRef({});

    const handleSave = async () => {
        const canvas = canvasRef.current.getTrimmedCanvas()
        const height = canvas.height
        const width = canvas.width
        const blob = dataURLtoBlob(canvas.toDataURL("image/png"))
        const formData = new FormData()
        formData.append('signature', blob)
        const { result } = await uploadSignature(formData, orderId, width, height)
        if (result) {
            handelSetUrl(result)
            handleShowPopUp()
        }
    }

    const handleClearSign = () => {
        canvasRef.current.clear()
    }

    return (
        <Grid className={view === 'desktop' ? 'signatureContainer' : view === 'tablet' ? 'signatureContainer--tablet' :  'addFilePopUp__main'}>
            <Grid xs={12}>
                {
                    view === 'mobile' ? <h4 className='addFilePopUp__name'>{POP_UPS_TRANSLATION.ADD_SIGN}</h4> : <h4 className='addFilePopUp__name'>{POP_UPS_TRANSLATION.SIGNATURE}</h4>
                }
            </Grid>

            <Grid xs={12} className='digitalSignaturePopUp__signature__container'>
                <ReactSignatureCanvas
                    ref={canvasRef}
                    canvasProps={{ className: view === 'desktop' ? 'canvas-desktop' : view === 'tablet' ? 'canvas-tablet' : 'canvas' }}
                    backgroundColor={`rgba(255, 255, 255, 1)`}
                />

                <div className={view === 'desktop' || view === 'tablet' ? 'digitalSignaturePopUp__clearBtn--desktop' : 'digitalSignaturePopUp__clearBtn'}>
                    {
                        view === 'desktop' || view === 'tablet' ? <Button data={clearBtnDesktop} handelClear={handleClearSign} view='desktop' /> : <Button data={clearBtn} handelClear={handleClearSign}/>
                    }
                </div>
            </Grid>
 
            {
                view === 'mobile' ? <Grid sx={12} className='addFilePopUp__btn__container'>
                    <Button data={downloadBtnMobile} handelSubmit={handleSave} />
                    &nbsp;
                    &nbsp;
                    <Button data={cancelBtnMobile} handleShowPopUp={handleShowPopUp} />
                </Grid> : <Grid sx={12} className='addFilePopUp__btn__container'>
                    <Button data={download} handelSubmit={handleSave} />
                    &nbsp;
                    &nbsp;
                    <Button data={cancel} handleShowPopUp={handleShowPopUp} />
                </Grid>

            }

        </Grid>
    )
}
