import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../../Buttons/Button';
import Input from '../../../InputFields/Input';
import SelectInput from '../../../InputFields/SelectInput/SelectInput';
import "./AddSystemPopUp.scss";
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { systemActionsCreator } from '../../../../../actions/customer/systemActions';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSystemsRelatedToLayer } from '../../../../../hooks/useGetSystemsRelatedToLayer';
import { SET_SYSTEMS_FOR_ACCORDION } from '../../../../../constants/customer/systemConstants';
import { add, cancel, addSystemInputs, addMobile, cancelMobile, addDesktop, cancelDesktop } from "./AddSystemPopUpStatic";


export default function AddSystemPopUp(props) {
    const { handleShowPopUp, view, name, mode, systemId, layer, parentSystemId, addSystemActionLevel } = props;
    const { id, data } = useSelector((state) => state.customerSystems.singleSystem);
    const systemNameToEdit = data && data[0].items[0]?.details;
    const locationDescriptionToEdit = data && data[0].items[1]?.details;
    const floorToEdit = data && data[0].items[2]?.details;


    const [inputValue, setInputValue] = useState({
        systemName: mode === 'edit' ? systemNameToEdit && typeof (systemNameToEdit) === 'object' ? systemNameToEdit.name : systemNameToEdit : '',
        systemNameError: false,
        locationDescription: mode === 'edit' ? locationDescriptionToEdit && locationDescriptionToEdit : '',
        locationDescriptionError: false,
        floor: mode === 'edit' ? floorToEdit && floorToEdit : '',
        floorError: false,
    });

    const templateSystems = useGetSystemsRelatedToLayer(layer);

    const systemSelectionOptions = templateSystems.map((systemOption) => {
        return {
            id: systemOption._id,
            name: systemOption.description
        }
    })

    const dispatch = useDispatch();


    const handleChange = (name, value) => {
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    const submitForm = () => {
        const newSystemId = "id" + Math.random().toString(16).slice(2)
        const singleSystem = {
            // id: mode === 'edit' ? systemId : newSystemId,
            parentId: parentSystemId ? parentSystemId : null,
            systemName: inputValue.systemName,
            locationDescription: inputValue.locationDescription,
            floor: inputValue.floor,
            layer: layer && parentSystemId ? layer + 1 : 1
        }
        if (mode === 'edit') {
            dispatch(systemActionsCreator.editSystem(singleSystem))
        } else {
            dispatch(systemActionsCreator.addSystem(singleSystem))
        }
        handleShowPopUp();
        dispatch({ type: SET_SYSTEMS_FOR_ACCORDION })

        // setIsNewSystemSubmitted && setIsNewSystemSubmitted(true);
    }

    // useEffect(() => {
    //     dispatch(systemActionsCreator.selectedSystemBySystemId(systemId))
    // }, [systemId])

    const addSystemInputsToSend = {
        ...addSystemInputs,
        systemName: {
            ...addSystemInputs.systemName,
            selectOptions: systemSelectionOptions,
            //[{ name: 'system 1', id: 1 }, { name: 'system 2', id: 2 }, { name: 'system 3', id: 3 }],
            handleChange: handleChange,
            value: inputValue.systemName || { name: 'system 1' },
            placeholder: mode === 'edit' ? (typeof (systemNameToEdit) === 'object' ? systemNameToEdit.name : systemNameToEdit) : POP_UPS_TRANSLATION.CHOOSE,
            error: inputValue.systemNameError,
            errorText: inputValue.systemNameError && 'system has error'
        },
        locationDescription: {
            ...addSystemInputs.locationDescription,
            onChange: handleChange,
            value: inputValue.locationDescription,
            placeholder: mode === 'edit' && locationDescriptionToEdit,
            error: inputValue.locationDescriptionError,
            errorText: inputValue.locationDescriptionError && 'location has error'
        },
        floor: {
            ...addSystemInputs.floor,
            onChange: handleChange,
            value: inputValue.floor,
            placeholder: mode === 'edit' && floorToEdit,
            error: inputValue.floorError,
            errorText: inputValue.floorError && 'floor has error'
        }
    }

    return (
            <Grid spacing={0} className='addSystem__main' style={{ left: view == 'desktop' || view == 'desktop-popup' && '15%' }}>
                <Grid xs={12}>
                    <h4 className='addSystem__title'>{name}</h4>
                </Grid>
                <Grid className='addSystem__uploadedInfo'>
                    <Grid xs={12} spacing={0} className='addSystem__uploadedInfo-row'>
                        <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.CUSTOMER_NAME}</span></Grid>
                        <Grid xs={6} className='addSystem__uploadedInfo-value'><span>{POP_UPS_TRANSLATION.ZAIN_BATOR}</span></Grid>
                    </Grid>
                    <Grid xs={12} className='addSystem__uploadedInfo-row'>
                        <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.ORDER_NUMBER}</span></Grid>
                        <Grid xs={6} className='addSystem__uploadedInfo-value'><span>S02100004B</span></Grid>
                    </Grid>
                </Grid>
                <Grid sx={12} className='addSystem__inputsFields'>
                    <Grid>
                    <SelectInput data={addSystemInputsToSend.systemName} view={view} />
                    </Grid>
                    <Grid xs={12} className='addSystem__inputsRow'>
                        <Grid xs={12} md={7}>
                        <Input data={addSystemInputsToSend.locationDescription} view={view} />
                        </Grid>
                        <Grid xs={12} md={5} className='addSystem__inputsLeft'>
                        <Input data={addSystemInputsToSend.floor} view={view} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='addSystem__emptyBox'>

                </Grid>
                {
                    view === 'mobile' ? <Grid sx={12} className={`addSystem__btn__container`}>
                        <Button data={addMobile} handleShowPopUp={() => submitForm()} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancelMobile} handleShowPopUp={handleShowPopUp} />
                    </Grid>
                        : view === 'desktop' || view === 'desktop-popup' ? <Grid sx={12} className='addSystem__btn__container'>
                            <Button data={addDesktop} handleShowPopUp={() => submitForm()} />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button data={cancelDesktop} handleShowPopUp={handleShowPopUp} />
                        </Grid>
                            : <Grid sx={12} className='addSystem__btn__container'>
                                <Button data={add} handleShowPopUp={handleShowPopUp} />
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button data={cancelDesktop} handleShowPopUp={handleShowPopUp} />
                            </Grid>
                }

            </Grid>
    )    
}
