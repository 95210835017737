import React, { Component } from 'react'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { List } from 'react-virtualized';
// import LoaderNew from '../../../../LoaderNew/Loader'
//img
// import Drag from '../../../../../images/general/drag_icon.svg'
import circleChevronLeft from '../../../../../images/general/circle-chevron-left.svg'
import paintRoller from '../../../../../images/processTypes/paintRoller.svg'
import Lock from '../../../../../images/updatepopup/openLock.svg'
import caretLeft from '../../../../../images/general/caret-left.svg'
import caretRight from '../../../../../images/general/caret-right.svg'
import Arrow from '../../../../../images/general/white-arrow.svg'
import Warning from '../../../../../images/general/warning-sign.svg'
import circleAdd from '../../../../../images/general/circle-add.svg'
//icons
import { SvgIcon } from '@material-ui/core';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import Fade from '@mui/material/Fade';
import EventIcon from '@material-ui/icons/Event';
import problem_icon from "../../../../../images/general/problem.svg";
import {sortProcessesMonthlyView} from '../../../../../functions/data/sortProcesses'
import {createOrderProcessBackground} from '../../../../../functions/helpers/createOrderProcessBackground'
import { InformationPopup } from '../../../../updatePopups/InformationPopup';
import drag_icon from '../../../../../images/icons/drag_x.svg'

import  * as momentBusinessDays  from 'moment-business-days';
import { SERVICE } from '../../../../../constants';
import { getPosition, isMobileView, updateClass } from '../../../../../hooks/helper';
import InfiniteScroll from 'react-infinite-scroller';
import MonthViewNew from '../../MonthViewNew';
import "./Calendar.css"
import Loader from '../../../../LoaderNew/Loader';

momentBusinessDays.updateLocale('us', {
    workingWeekdays: [0, 1, 2, 3, 4 ] 
});

let uniqid = require("uniqid");
class Calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            infoPopup: false,
            processInfoData: {},
            infoPop: React.createRef()
        }
    }

    shouldComponentUpdate(_np, _ns){
        let _p = this.props; let _s = this.state;
        return (
                _np.fourWeeksDateChange !== _p.fourWeeksDateChange 
            ||  _np.user_or_order_type !== _p.user_or_order_type
            ||  _np.daysOffNumber !== _p.daysOffNumber
            ||  _np.disableDrag !== _p.disableDrag
            ||  _np.datesArray !== _p.datesArray
            ||  _np.daysOff !== _p.daysOff
            ||  _ns !== _s
        ) ? true : false;         
    }

    handleProcessMenu = (data,date) => {
        this.props.initProccessUpdatePopup(data,date)
    }

    goToDailyIfMobile = (day) => {
        const dynamicFactoryName = this.props.factoryName
        let dateNew = day.date
        dateNew = moment(dateNew).add(5, 'hours')._d
        this.props.history.push(`/${dynamicFactoryName}/daily/${dateNew} ${window.location.search}`)
    }

    setDayColor = (day) => {
        const { warnings } = day;
        return warnings ? 'red' : 'rgba(0, 0, 0, 0.6)'
    }

    backToToday = () => {
        this.props.backToToday()
    }

    findDayWarningForMobileUiHeader=()=>{
        const {datesArray} = this.props
        let datesArray_copy = JSON.parse(JSON.stringify(datesArray));
       
       let su_no_warning = datesArray_copy.filter(day=> day.dateName === "ראשון" && day.warnings).length <= 0
       let mo_no_warning = datesArray_copy.filter(day=> day.dateName === "שני" && day.warnings).length <= 0
       let tu_no_warning = datesArray_copy.filter(day=> day.dateName === "שלישי" && day.warnings).length <= 0
       let we_no_warning = datesArray_copy.filter(day=> day.dateName === "רביעי" && day.warnings).length <= 0
       let th_no_warning = datesArray_copy.filter(day=> day.dateName === "חמישי" && day.warnings).length <= 0
       let fr_no_warning = datesArray_copy.filter(day=> day.dateName === "שישי" && day.warnings).length <= 0
       let sa_no_warning = datesArray_copy.filter(day=> day.dateName === "שבת" && day.warnings).length <= 0
       let mobile_days_names = [su_no_warning, mo_no_warning,tu_no_warning, we_no_warning,th_no_warning,fr_no_warning,sa_no_warning]
       return mobile_days_names
    }

    previous_info_popup_id = '';
    initHandlerForInfoPopup = (event, popup_id) => {
        if(this.previous_info_popup_id){
            let tag = document.getElementById(this.previous_info_popup_id);
            tag && (tag.style.display = 'none');
        }
        if(event.target.offsetParent.nextSibling) {
            document.getElementById(event.target.offsetParent.nextSibling.id).style.display = 'block';
        } else if(event.target.parentElement.nextSibling) {
            document.getElementById(event.target.parentElement.nextSibling.id).style.display = 'block';
        } else {
            document.getElementById(event.target.parentElement.parentNode.nextSibling.id).style.display = 'block';
        }
        if(popup_id){
            let tag = document.getElementById(popup_id);
            tag && (tag.style.marginTop = this.setInfoTopView(event));
        }
        this.previous_info_popup_id = popup_id;
    };

    setInfoTopView = (event) => {
        let xPoint= event.clientX;
        let yPoint= event.clientY;
        let innerHeight = window.innerHeight;
        let height = innerHeight - yPoint;
        return (height < 160) ? '-78%' : '-12%';
    }

    closeHandlerInfoPopup = (event, popup_id) => {
        let is_hover_on_info = false;
        let popup = document.getElementById(popup_id)?.addEventListener('mouseenter', (e) => {
            is_hover_on_info = true;
        },false);
        setTimeout(() => {
            if(!is_hover_on_info){
                document.getElementById(popup_id).style.display = 'none';
            }
        },100)
    };

    checkIndexAndSetWidth = (index, data) => {
        const { process_width = 1} = data.proccess || {};
        return `calc(((100% + 0.9px) * ${process_width}) - 10px)`
    }

    checkIndexAt = (date, data) => {
        const { process_width = 1, fraction = true, zindex = false } = data.proccess || {};
        return (!fraction && process_width > 1) ? true : zindex
    }


    // getRowRender =  (day, disableDrag, user_or_order_type, day_index, info_popup_position) => ({index, provided, isDragging}) => {
    //     let ctr = 0;
    //     return sortProcessesMonthlyView(day.dayData).map((data, index) => {
    //         if (data !== undefined) {
    //             let resources_name = data?.proccess?.resource?.reduce((names, item) => {
    //                 names.push(item.name);
    //                 return names;
    //             }, []);
    //             let  resourcesString = resources_name?.reduce((name, value) => {
    //                 return name += (name ? ` / ${value}` : `${value}`);
    //             }, '') || '';
    //             if (data?.dummy) {
    //                 return <div key={data.order_id} style={{
    //                     height: '38px',
    //                     marginBottom: '5px'
    //                 }}>
    
    //                 </div>
    //             }
    //             if (!data.proccess.backlog) {
    //                 ctr += 1;
    //                 return <Draggable draggableId={data.proccess._id} index={ctr} key={data.proccess._id} isDragDisabled={false}>
    //                     {(provided, snapshot) => (
    //                         <React.Fragment>
    //                             <div
    //                                 onClick={() => { this.handleProcessMenu(data, day.date) }}
    //                                 className={data?.proccess?.warnings ? `month-view-page__calendar__table__day__process month-view-page__calendar__table__day__process--warning ${updateClass(data.proccess)}` : `month-view-page__calendar__table__day__process ${updateClass(data.proccess)}`}
    //                                 {...provided.draggableProps}
    //                                 {...provided.dragHandleProps}
    //                                 ref={provided.innerRef}
    //                                 data-is-dragging={snapshot.isDragging}
    //                                 style={{
    //                                     cursor: data.proccess.fraction ? 'pointer' : '',
    //                                     zIndex: this.checkIndexAt(day.parsedDate, data) ? '99' : '0',
    //                                     backgroundColor: data.proccess.done ? '#E8E9EB' : '',
    //                                     background: createOrderProcessBackground(data?.proccess, user_or_order_type),
    //                                     borderRadius: '20px',
    //                                     width: this.checkIndexAndSetWidth(day_index, data),
    //                                     marginRight: '5px',
    //                                     marginBottom: '5px',
    //                                     ...provided.draggableProps.style,
    //                                 }}
    //                                 id={`${data.proccess._id}x1y2`}
    //                             >
    
    //                                 <div className="drag__icon__container" onMouseEnter={(event) => this.initHandlerForInfoPopup(event, data.proccess._id)} onMouseLeave={(event) => this.closeHandlerInfoPopup(event, data.proccess._id)}>
    //                                     {" "}
    //                                     <img className="drag__icon" src={drag_icon} alt="drag icon"></img>
    //                                 </div>
    //                                 <section style={{ padding: '0px 10px' }}>
    //                                     <div>
    //                                         <p>
    //                                             <span className="month__process__client__name">
    //                                                 {data?.proccess?.client_name}
    //                                             </span>
    //                                             {data?.proccess?.remark ? '-' : ''}
    //                                             <span className="month__process__client__name four_characters_length">
    //                                                 {data?.proccess?.remark} -
    //                                             </span>
    //                                             <span className="month__process__number">
    //                                                 {data?.proccess?.order_number}
    //                                             </span>
    //                                             <span className="icones"></span>
    //                                         </p>
    //                                     </div>
    //                                     <div><p>{data?.proccess?.process_name} -<span className="month__proccess__employee"> {resourcesString}</span></p></div>
    //                                 </section>
    //                                 {
    //                                     data?.proccess?.is_detached ?
    //                                         <div className="month-view-page__calendar__table__day__process--menu"><img src={Lock} alt="menu" /></div>
    //                                         :
    //                                         null
    //                                 }
    //                                 {/* <span style={{ background: createOrderProcessBackground(data?.proccess, user_or_order_type) }} className="process--view"></span> */}
    //                             </div>
    //                             <div style={{ display: 'none', transform: `translateX(${getPosition(info_popup_position, data.proccess.process_date)}%)` }} className="info--popup" id={data.proccess._id}>
    //                                 <InformationPopup
    //                                     process={data.proccess}
    //                                     popupId={`${data.proccess._id}`}
    //                                 />
    //                             </div>
    //                         </React.Fragment>
    //                     )}
    //                 </Draggable>
    //             }
    //         }
    //     })
    // }
    render() {
        const {fourWeeksDateChange, datesArray, daysOffNumber, disableDrag, daysOff, user_or_order_type, getMoreDataOnScroll, handleScroll} = this.props
        let headerDatesString = datesArray && datesArray.length > 0 ? datesArray[0].parsedDate + ' - ' + datesArray[datesArray.length - 1].parsedDate:null
        let headerWeeksFirstString = datesArray && datesArray.length > 0 ? moment(datesArray[0].date).week(): null
        let headerWeeksEndString = datesArray && datesArray.length > 0  ? moment(datesArray[datesArray.length - 1].date).week() :null
        let mobile_days_names_warning_check = isMobileView() ? this.findDayWarningForMobileUiHeader() : []
        let mobileDaysNames = ['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת']
        let header_color = 'rgb(210 210 210)';
        let info_popup_position = {
            initial: -8,
            last: 100,
        }
        let blockDecrementation = false;

        return (
            <div className="month-view-page__calendar">
                
                <header className="month-view-page__calendar__header">
                    <figure id="mobile__dec__figure" style={blockDecrementation ? {pointerEvents:'none'}:{}} onClick={()=>{fourWeeksDateChange('dec')}}> <img className="monthly-view-arrows" src={caretRight} alt="right-arrow"/> </figure>
                    <figure id="mobile__dec__figure-left" onClick={()=>{fourWeeksDateChange('inc')}}> <img className="monthly-view-arrows" src={caretLeft} alt="left-arrow"/> </figure>
                    <button className="btn--back--today" onClick={()=>{this.backToToday()}} >
                        {/* <SvgIcon className="back--today--icon" component={EventIcon} /> */}
                        <p>חזור להיום</p>
                    </button>
                    {
                        this.props.apiStart && this.props.apiEnd 
                        ?   <React.Fragment>
                                <p style={{color: 'white'}}><span>{moment(this.props.apiStart).format('DD/MM/YYYY')}</span>-<span>{moment(this.props.apiEnd).format('DD/MM/YYYY')}</span></p>
                            </React.Fragment>
                        : ""
                    }
                    {/* <figure className="figure-arrows" style={blockDecrementation ? {pointerEvents:'none'}:{}} onClick={()=>{fourWeeksDateChange('dec')}}> <img className="monthly-view-arrows" src={caretRight} alt="right-arrow"/> </figure>
                    {!!(headerDatesString) && <h3>שבועות {headerWeeksFirstString} - {headerWeeksEndString} ( {headerDatesString} )</h3>}
                    <figure className="figure-arrows" onClick={()=>{fourWeeksDateChange('inc')}}> <img className="monthly-view-arrows" src={caretLeft} alt="left-arrow"/> </figure> */}
                </header>
                {
                    isMobileView() && <section className="month-view-page__calendar__mobile-days-panel">
                        {mobileDaysNames.map( (day,dayIndex) => {
                            if(!daysOff.includes(dayIndex))
                            return <div key={uniqid()} style={{width:`calc( 100% / ${ 7 - daysOffNumber})  `}}>
                                {mobile_days_names_warning_check[dayIndex] ? 
                                <p>{day}</p>
                                :
                                <p className="mobile__days__names__warning">{day} <img src={problem_icon} alt="problem"/> </p>
                                }
                                </div>
                        })}
                    </section>
                }

              
                    <section className="month-view-page__calendar__table" id="month-view-page__calendar__table_plan" onScroll={handleScroll}>
                    {/* <MonthViewNew/> */}

                    {/* <div className='month-view-page__calendar_infiniteScroll_container' style={{ height: '100vh', overflow: 'auto'}}> */}
                        {/* <InfiniteScroll
                            pageStart={1}
                            loadMore={this.props.loadMore}
                            hasMore={this.props.hasMoreData}
                            loader={this.props.loading && <div className="loader" key={0}><Loader /></div>}
                            useWindow={false}
                            // isReverse={true}
                            initialLoad={false}
                        > */}
                            {datesArray.map((day, day_index) => {
                                let hasWarnings = false
                                for (let i = 0; i < day.dayData.length; i++) {
                                    if (day.dayData[i].warnings.length > 0 && !day.dayData[i].proccess.backlog) {
                                        hasWarnings = true
                                        break;
                                    }
                                }

                                let isHalfDayBlank = false;
                                if (day.holiday) {
                                    if (day.dayData.length === 0) isHalfDayBlank = true;
                                }
                                let mobileParsedDate = day.parsedDate
                                mobileParsedDate = mobileParsedDate.substr(0, 5) + '/' + mobileParsedDate.substr(8)
                                let today_date = moment().format('L')
                                today_date = today_date.substr(0, 5) + '/' + today_date.substr(8)

                                if (!day.dayOff)
                                    return <div style={{ width: `calc( 100% / ${7 - daysOffNumber})`}} className={hasWarnings ? "month-view-page__calendar__table__day month-view-page__calendar__table__day--warning" : "month-view-page__calendar__table__day"} key={day.parsedDate} >

                                        <figure onClick={() => { this.goToDailyIfMobile(day) }}></figure>
                                        {day.holiday && day.holiday?.status === "Day Off" || day.dayOff ?
                                            <div style={{ marginBottom: '5px', color: this.setDayColor(day) }} onClick={() => { this.goToDailyIfMobile(day) }}>
                                                <header style={{ backgroundColor: '#cef8c1' }}>
                                                    <p>{day.dateName} {this.setDayColor(day) === 'red' ? <img className="warning_icon" src={Warning} alt="warning"></img> : ""}</p>
                                                    <p><span title={day.holiday?.name}>{'(יום חופש) '}</span> {day.parsedDate}</p>
                                                    <span className={String(mobileParsedDate) === String(today_date) ? "today__mark" : ""}>{mobileParsedDate}</span>
                                                </header>
                                            </div>
                                            :
                                            day.holiday && day.holiday?.status === "Half Day" ?
                                                <div style={{ marginBottom: '5px', color: this.setDayColor(day) }} onClick={() => { this.goToDailyIfMobile(day) }}>
                                                    <header style={{ backgroundColor: '#cef8c1' }}>
                                                        <p>{day.dateName} {this.setDayColor(day) === 'red' ? <img className="warning_icon" src={Warning} alt="warning"></img> : ""}</p>
                                                        <p><span title={day.holiday?.name}>{'(חצי יום)'}</span> {day.parsedDate}</p>
                                                        <span className={String(mobileParsedDate) === String(today_date) ? "today__mark" : ""}>{mobileParsedDate}</span>
                                                    </header>
                                                </div>
                                                :
                                                <div style={{ marginBottom: '5px', color: this.setDayColor(day) }} onClick={() => { this.goToDailyIfMobile(day) }}>
                                                    <header style={{ backgroundColor: header_color }}>
                                                        <p>{day.dateName} {this.setDayColor(day) === 'red' ? <img className="warning_icon" src={Warning} alt="warning"></img> : ""} </p>
                                                        <p> <span>{'(' + moment(day.date).week() + ') '}</span> {day.parsedDate}</p>
                                                        <span className={String(mobileParsedDate) === String(today_date) ? "today__mark" : ""}> {mobileParsedDate}</span>
                                                    </header>
                                                </div>
                                        }


                                        {
                                            day.holiday && day.holiday.status === 'Day Off' || day.dayOff ?
                                                <React.Fragment>
                                                    <div className="month-view-page__calendar__table__day--dayoff">
                                                        <p style={{ width: '100%' }}>{day.holiday.name}</p>
                                                    </div>
                                                </React.Fragment>
                                                :

                                                <Droppable 
                                                    droppableId={day.parsedDate} 
                                                    // mode="virtual"
                                                    // renderClone={(provided, snapshot, rubric) => (
                                                    //     <div
                                                    //       {...provided.draggableProps}
                                                    //       {...provided.dragHandleProps}
                                                    //       ref={provided.innerRef}
                                                    //       provided={provided}
                                                    //     >
                                                    //       Item id: 10
                                                    //     </div>
                                                    //   )}
                                                >
                                                    {(provided, snapshot) => (

                                                        <div
                                                            className="month-view-page__calendar__table__day__droppable"
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            style={{ height: (day.holiday && day.holiday.status !== 'Half Day') ? 'calc(100% - 10px)' : 'calc(100% - 30px)', ...provided.droppableProps.style, margin: '0px 0px', padding: '0px 0px' }}
                                                        >
                                                            {
                                                                !isHalfDayBlank ?
                                                                    <>
                                                                        {/* <List height={300} width={300} rowRenderer={this.getRowRender(day, disableDrag, user_or_order_type, day_index, info_popup_position)} rowCount={100} rowHeight={40} /> */}
                                                                        {sortProcessesMonthlyView(day.dayData).map((data, index) => {
                                                                            if (data !== undefined) {
                                                                                let resources_name = data?.proccess?.resource?.reduce((names, item) => {
                                                                                    names.push(item.name);
                                                                                    return names;
                                                                                }, []);
                                                                                let resourcesString = resources_name?.reduce((name, value) => {
                                                                                    return name += (name ? ` / ${value}` : `${value}`);
                                                                                }, '') || '';
                                                                                if (data?.dummy) {
                                                                                    return <div key={data.order_id} style={{
                                                                                        height: '38px',
                                                                                        marginBottom: '5px'
                                                                                    }}>

                                                                                    </div>
                                                                                }
                                                                                if (!data.proccess.backlog) {
                                                                                    return <Draggable draggableId={data.proccess._id} index={index} key={data.proccess._id} isDragDisabled={disableDrag || data.proccess.done}>
                                                                                        {(provided) => (
                                                                                            <React.Fragment>
                                                                                                <div
                                                                                                    onClick={() => { this.handleProcessMenu(data, day.date) }}
                                                                                                    className={data?.proccess?.warnings ? `month-view-page__calendar__table__day__process month-view-page__calendar__table__day__process--warning ${updateClass(data.proccess)}` : `month-view-page__calendar__table__day__process ${updateClass(data.proccess)}`}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    ref={provided.innerRef}
                                                                                                    style={{
                                                                                                        cursor: data.proccess.fraction ? 'pointer' : '',
                                                                                                        zIndex: this.checkIndexAt(day.parsedDate, data) ? '99' : '0',
                                                                                                        backgroundColor: data.proccess.done ? '#E8E9EB' : '',
                                                                                                        background: createOrderProcessBackground(data?.proccess, user_or_order_type),
                                                                                                        borderRadius: '20px',
                                                                                                        width: this.checkIndexAndSetWidth(day_index, data),
                                                                                                        marginRight: '5px',
                                                                                                        marginBottom: '5px',
                                                                                                        ...provided.draggableProps.style,
                                                                                                    }}
                                                                                                    id={`${data.proccess._id}x1y2`}
                                                                                                >

                                                                                                    <div className="drag__icon__container" onMouseEnter={(event) => this.initHandlerForInfoPopup(event, data.proccess._id)} onMouseLeave={(event) => this.closeHandlerInfoPopup(event, data.proccess._id)}>
                                                                                                        {" "}
                                                                                                        <img className="drag__icon" src={drag_icon} alt="drag icon"></img>
                                                                                                    </div>
                                                                                                    <section style={{ padding: '0px 10px' }}>
                                                                                                        <div>
                                                                                                            <p>
                                                                                                                <span className="month__process__client__name">
                                                                                                                    {data?.proccess?.client_name}
                                                                                                                </span>
                                                                                                                {data?.proccess?.remark ? '-' : ''}
                                                                                                                <span className="month__process__client__name four_characters_length">
                                                                                                                    {data?.proccess?.remark} -
                                                                                                                </span>
                                                                                                                <span className="month__process__number">
                                                                                                                    {data?.proccess?.order_number}
                                                                                                                </span>
                                                                                                                <span className="icones"></span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div><p>{data?.proccess?.process_name} -<span className="month__proccess__employee"> {resourcesString}</span></p></div>
                                                                                                    </section>
                                                                                                    {
                                                                                                        data?.proccess?.is_detached ?
                                                                                                            <div className="month-view-page__calendar__table__day__process--menu"><img src={Lock} alt="menu" /></div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    {/* <span style={{ background: createOrderProcessBackground(data?.proccess, user_or_order_type) }} className="process--view"></span> */}
                                                                                                </div>
                                                                                                <div style={{ display: 'none', transform: `translateX(${getPosition(info_popup_position, data.proccess.process_date)}%)` }} className="info--popup" id={data.proccess._id}>
                                                                                                    <InformationPopup
                                                                                                        process={data.proccess}
                                                                                                        popupId={`${data.proccess._id}`}
                                                                                                    />
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </Draggable>
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                    :
                                                                    <div className="month-resource-page__calendar__table__day--dayoff" style={{ height: 'inherit' }}>
                                                                        <p style={{ width: '100%' }}>
                                                                            {day.holiday?.name}
                                                                        </p>
                                                                    </div>
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>

                                        }
                                    </div>

                            })}
                        {/* </InfiniteScroll> */}
                    {/* </div> */}
                      
                    </section> 
               

               
            </div>
        )
    }
}

export default withRouter(Calendar)
