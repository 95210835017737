/* eslint-disable react-hooks/exhaustive-deps */

import { FormHelperText, Grid, Typography} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import "./AddFilePopUp.scss";
import Button from '../../Buttons/Button';
import UploadFileButton from '../../Buttons/UploadFileButton/UploadFileButton';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import Input from '../../InputFields/Input';
import SelectInput from '../../InputFields/SelectInput/SelectInput';
import { ADD, CANCEL } from '../../../../constants/translations/review-popup';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';
import { fileActionCreator } from '../../../../actions/customer/fileActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { uploadFile } from '../../../../functions/api/customer-page';
import { SmallLoader } from '../../../reused-components/SmallLoader';
import { FILE_ERRORS } from '../../../../constants/translations/customer/errors/errorMessage';
import { setCustomerData } from '../../../../actions/customers_table_actions';

const add = {
    name: ADD,
    width: '35%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
    borderRadius: '20px',
}
const cancel = {
    name: CANCEL,
    width: '35%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
    borderRadius: '20px',

}
const cancelBtnMobile = {
    ...cancel,
    width: '42%',
    height: '36px',
    fontSize: '12px',
}
const addBtnMobile = {
    ...add,
    width: '42%',
    height: '36px',
    fontSize: '12px',
    backgroundColor: '#CACBCC'
}

const fileTabBtnsTablet = {
    add: {
        ...add,
        width: '35%',
        backgroundColor: "#0091FF",
        fontSize: '24px',
        height: '70px',
        borderRadius: '90px',
    },
    cancel: {
        ...cancel,
        name: CANCEL,
        width: '35%',
        fontSize: '24px',
        height: '70px',
        borderRadius: '90px',
    }
}

let addFilePopUpInputs = {
    fileName: {
        label: POP_UPS_TRANSLATION.FILE_NAME,   // 'File name'
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        type: 'text',
        name: 'fileName'
    },
    fileType: {
        label: POP_UPS_TRANSLATION.FILE_TYPE,   //'File Type'
        placeholder: POP_UPS_TRANSLATION.ENTER,
        value: 'Enter',
        type: 'select',
        name: 'fileType',
    },
}
const extractFileName = (fileName) => {
    // let newFileName = fileName.split(" ")[0]
    return fileName;
}
export default function AddFilePopUp(props) { 
    const { handleShowPopUp, showPopUp, name, view, process, updateFilesList, mode, fileId, customerId, customerName, currentFileId } = props;
    const [file, setFile] = useState(null);
    const [pending, setPending] = useState(false);
    const [customerDataState, setCustomerDataState] = useState({});

    const dispatch = useDispatch()
    const fileToEdit = useSelector((state) => state.files.singleFile);

    const [inputValue, setInputValue] = useState({ fileName: mode === 'edit' ? fileToEdit.fileName : '', fileNameError: false, fileType: mode === 'edit' ? fileToEdit.fileType : '', fileTypeError: false, fileError: false });


    const handleFileUpload = async (e) => {
        setFile(e.name);
        const formData = new FormData()
        formData.append('file', e)
        setPending(true)
        const { ok, result } = await uploadFile(formData, customerId)
        if (ok && result) {
            setPending(false)
            setCustomerDataState(result);
            updateFilesList && updateFilesList(result?.attachments)
        }
    }

    const validation = ({ name, value }) => {
        let _isValid = true;
        const errors = {};
        if (name === 'fileName' || typeof name === 'undefined') {
            const isValid = isFileNameValid(value || inputValue.fileName);
            errors.fileNameError = !isValid;
            _isValid = isValid;
        }

        // file type check
        if (name === 'fileType' || typeof name === 'undefined') {
            const isValid = isFileTypeValid(value || inputValue.fileType);
            errors.fileTypeError = !isValid;
            _isValid = isValid;
        }

        if (typeof name === 'undefined') {
            const isValid = isFileValid();
            errors.fileError = !isValid;
            _isValid = isValid;
        }

        setInputValue((prev) => ({
            ...prev,
            ...errors
        }))
        return _isValid;
    }


    const isFileNameValid = (value) => {
        if (value.length < 4) {
            return false;
        }
        return true;
    }

    const isFileTypeValid = (value) => {
        if (typeof value === 'object') {
            return true;
        } else if (!value || !value.length) {
            return false;
        }
        return true;
    }

    const isFileValid = () => {
        if (!file) {
            return false;
        }
        return true;
    }

    let todayDate = new Date();
   todayDate = moment(todayDate).format("DD/MM/YYYY")

    const submitForm = () => {
        if (!validation({})) {
            return;
        }
        const newFileId = "id" + Math.random().toString(16).slice(2)
       
        const singleFile = {
            id: mode === 'edit' ? fileToEdit.id : newFileId,
            fileName: inputValue.fileName,
            fileType: inputValue.fileType,
            uploadedBy: customerName,
            uploadDate: todayDate,
            filePath: file && file
        }
        if (mode === 'edit') {
            // dispatch(fileActionCreator.editSelectedFileByFileId(singleFile))
        } else {
            // dispatch(fileActionCreator.addFileToFilesListTablet(singleFile))
        }
        dispatch(setCustomerData(customerDataState))
        handleShowPopUp()
    }

    useEffect(() => {
        dispatch(fileActionCreator.selectFileByFileId(fileId))
    }, [fileId])

    useEffect(() => {
        if (fileToEdit.fileName && mode === 'edit') {
            setFile(fileToEdit.fileName)
        }
    }, [fileToEdit])


    const handleChange = (name, value) => {
        if (inputValue[`${name}Error`]) {
            validation({ name, value })
        }
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    addFilePopUpInputs = {
        ...addFilePopUpInputs,
        fileName: {
            ...addFilePopUpInputs.fileName,
            value: inputValue.fileName,
            placeholder: mode === 'edit' && fileToEdit.fileName,
            onChange: handleChange,
            error: inputValue.fileNameError,
            errorText: inputValue.fileNameError && FILE_ERRORS.FILE_NAME_REQUIRED
        },
        fileType: {
            ...addFilePopUpInputs.fileType,
            value: inputValue.fileType,
            handleChange: handleChange,
            selectOptions: [{ name: 'png', id: '1' }, { name: 'jpg', id: '2' }, { name: 'jpeg', id: '3' }, { name: 'pdf', id: '4' }],
            placeholder: mode === 'edit' && fileToEdit.fileType && fileToEdit.fileType.placeholder,
            error: inputValue.fileTypeError,
            errorText: inputValue.fileTypeError && FILE_ERRORS.FILE_TYPE_REQUIRED
        }
    }

    return (
        <Grid className={`${view === 'tablet' && 'addFilePopUp__main--tablet'} addFilePopUp__main`} style={{ width: view === 'tablet' ? '700px !important' : view === 'desktop' && '580px', height: view === 'desktop' && '715px', left: view === 'desktop' && '15%' }}>
            <Grid xs={12}>
                <h4 className={`${view === 'tablet' && 'addFilePopUp__name--tablet'} addFilePopUp__name`}>{name}</h4>
            </Grid>
            <Grid className='addFilePopUp__uploadedInfo__main'>
                <Grid xs={12} className={`${view === 'tablet' && 'addFilePopUp__uploadedInfo__container--tablet'} addFilePopUp__uploadedInfo__container`}>
                    <Grid xs={6} className={`${view === 'tablet' && 'addFilePopUp__uploadedInfo__label--tablet'} addFilePopUp__uploadedInfo__label`}><span>{POP_UPS_TRANSLATION.UPLOADED_BY}</span></Grid>
                    <Grid xs={6} className={`${view === 'tablet' && 'addFilePopUp__uploadedInfo__value--tablet'} addFilePopUp__uploadedInfo__value`}><span>{customerName || POP_UPS_TRANSLATION.ZAIN_BATOR}</span></Grid>
                </Grid>
                <Grid xs={12} className='addFilePopUp__uploadedInfo__container'>
                    <Grid xs={6} className={`${view === 'tablet' && 'addFilePopUp__uploadedInfo__label--tablet'} addFilePopUp__uploadedInfo__label`}><span>{POP_UPS_TRANSLATION.UPLOADED_DATE}</span></Grid>
                    <Grid xs={6} className={`${view === 'tablet' && 'addFilePopUp__uploadedInfo__value--tablet'} addFilePopUp__uploadedInfo__value`}><span>{todayDate || '19/08/23'}</span></Grid>
                </Grid>
            </Grid>
            <Grid xs={12} className='addFilePopUp__input__container'>
                <Input data={addFilePopUpInputs.fileName} view={view} />
            </Grid>
            <Grid xs={12} className='addFilePopUp__input__container'>
                <SelectInput data={addFilePopUpInputs.fileType} view={view} />
            </Grid>

            <div className='errorAttachmentContainer'>
                {
                    inputValue.fileError ? <FormHelperText className='inputAttachmentError'>{FILE_ERRORS.FILE_ATTACHMENT_REQUIRED}</FormHelperText> : null
                }
            </div>

            {
                view === 'desktop' ? <Grid sx={12} className='addFilePopUp__textArea__container'>
                    {
                        pending ? <SmallLoader />
                            : file ?
                                <UploadedFilePreview handelClearFile={setFile} view={view} file={file} />
                                : <Typography className='addFilePopUp__textArea__label'>
                                    <UploadFileButton handleFileUpload={handleFileUpload} view="desktop" />
                                    <span>{DESKTOP_VIEW_TRANSLATION.DRAG_AND_DROP_YOUR_FILE_HERE_OR}</span>
                                </Typography>
                    }
                </Grid> : file ? <UploadedFilePreview handelClearFile={setFile} view={view} file={file} /> : <Grid sx={12} className={`${view === 'tablet' && 'addFilePopUp__uploadBtn__container--tablet'} addFilePopUp__uploadBtn__container`}>
                    <UploadFileButton handleFileUpload={handleFileUpload} view={view} />
                </Grid>
            }
            {
                view === 'mobile' ? <Grid sx={12} className='addFilePopUp__btn__container'>
                    <Button data={addBtnMobile} handelSubmit={submitForm} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancelBtnMobile} handleShowPopUp={handleShowPopUp} />
                </Grid>
                    : view === 'desktop' ? <Grid sx={12} className='addFilePopUp__btn__container addFilePopUp__btn--desktop'>
                    <Button data={add} handelSubmit={submitForm} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancel} handleShowPopUp={handleShowPopUp} />
                </Grid> 
                : <Grid sx={12} className='addFilePopUp__btn__container'>
                            <Button data={fileTabBtnsTablet.add} handelSubmit={submitForm} />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button data={fileTabBtnsTablet.cancel} handleShowPopUp={handleShowPopUp} />
                        </Grid>
            }

        </Grid>
    )

}

export const UploadedFilePreview = ({ handelClearFile, view, file }) => {
    return (
        <div className={view === 'desktop' && 'addFilePopUp__uploaded--position'}>
            <Grid xs={12} className={view === 'desktop' ? 'addFilePopUp__uploaded__container--desktop' : 'addFilePopUp__uploaded__container'}>
                {
                    view !== 'desktop' && <label>{POP_UPS_TRANSLATION.UPLOADED_FILE}</label>
                }
                <Grid xs={12} className={view === 'desktop' ? 'addFilePopUp__uploaded__infoContainer--desktop' : 'addFilePopUp__uploaded__infoContainer'}>
                    <Grid xs={10} className={view === 'desktop' ? 'addFilePopUp__nameType--desktop' : 'addFilePopUp__nameType'}>
                        <span className={view === 'desktop' ? 'addFilePopUp__icon__container-doc--desktop' : 'addFilePopUp__icon__container'}> <DescriptionIcon style={{ fontSize: view === 'desktop' ? '18px' : view === 'tablet'? '24px': '16px' }} /></span>
                        &nbsp;
                        <label>{extractFileName(file)}</label>
                    </Grid>
                    <Grid xs={2} className='addFilePopUp__remove'>
                        <span className={view === 'desktop' ? 'addFilePopUp__icon__container--desktop' : 'addFilePopUp__icon__container'} onClick={() => handelClearFile(null)} > <ClearIcon style={{ fontSize: view === 'desktop' ? '18px' : view === 'tablet' ? '24px' : '16px' }} /></span>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}
