import { Grid} from '@material-ui/core';
import React, { useState } from 'react';
import "./DetailsTab.scss";
import Input from '../../InputFields/Input';
import Button from '../../Buttons/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import DigitalSignaturePopUp from '../../PopUps/DigitalSignaturePopUp/DigitalSignaturePopUp';
import { TABLET_VIEW_TRANSLATION } from '../../../../constants/translations/customer/tablet/tabletView';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { useDigitalSignature } from '../../../../hooks/useDigitalSignature';
import { API } from '../../../../tools/keys/keys';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import Loader from '../../../LoaderNew/Loader';
import { updateProcess } from "../../../../functions/api/orders";
import { detailsTabInput, detailsTabBtnsMobile, detailsTabBtnsDesktop, detailsTabBtnsTablet } from "./DetailsTabStatic";


export default function DetailsTab(props) {
    const { view, closePopup, startTime, endTime, orderId, startDate, endDate, 
        processDuration, dataInfoInputValue, backlog, isParallelProcess, process, updateErrorPopupProcess, exitFullscreen } = props;
    const { url, setUrl, pending } = useDigitalSignature(orderId);
    const [showSignPopup, setShowSignPopup] = useState(false);

    const formatStartTime = (startHour) => {
        const startTime = moment({ hour: startHour, });
        const formattedStartTime = startTime.format('HH:mm');
        return formattedStartTime;
    }
    const calculateEndTime = (start, time) => {
        // create new date with hours and minutes from start time ("11:36") , adds process duration and calculate end hours and minutes
        if (start && time) {
            const [hours, minutes] = start.split(':')
            const startHours = parseInt(hours) || 0
            const startMinutes = parseInt(minutes) || 0

            const endTime = moment()
                .set({ hour: startHours, minute: startMinutes })
                .add(+time, 'hours')
                .format("HH:mm")
            return endTime
        }
    }

    const [inputValue, setInputValue] = useState(
        { 
        startDate: moment(endDate).format("YYYY-MM-DD"), 
        startDateError: false, 
        endDate: moment(endDate).format("YYYY-MM-DD"), 
        endDateError: false, 
        startTime: formatStartTime(startTime), 
        startTimeError: false, 
        endTime: calculateEndTime(formatStartTime(startTime), processDuration), 
        endTimeError: false, 
        totalHours: processDuration, 
        totalHoursError: false,
        remark: process?.remark || '', 
    });


    const handelShowSignPopup = () => {
        setShowSignPopup(!showSignPopup);
    }

    const validation = (name, value) => {
        let isValid = true;
        // start date check
        if (name === 'startDate' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, startDateError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, startDateError: false })
            }
        }

        // start date check
        if (name === 'endDate' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, endDateError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, endDateError: false })
            }
        }

        // start date check
        if (name === 'startTime' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, startTimeError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, startTimeError: false })
            }
        }

        // start date check
        if (name === 'endTime' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, endTimeError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, endTimeError: false })
            }
        }

        // start date check
        if (name === 'totalHours' && name in inputValue) {
            if (!value) {
                setInputValue({ ...inputValue, totalHoursError: true });
                isValid = false;
            } else {
                setInputValue({ ...inputValue, totalHoursError: false })
            }
        }

        return isValid;
    }
    const handleChange = (name, value) => {   
        validation(name, value)
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleTextAreaChange = (e) => {
        const {name, value} = e.target;
        console.log(name, value)
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

   

    const submitForm = async (event) => {
        event.stopPropagation();
        if (!(validation('startDate', inputValue.startDate) && validation('endDate', inputValue.endDate) && validation('startTime', inputValue.startTime) && validation('totalHours', inputValue.totalHours))) {
            return;
        }
        let dataInfo;
        if (!dataInfoInputValue.orderedError && !dataInfoInputValue.reportedError && !dataInfoInputValue.resourceNameError) {
            dataInfo = {
                quantity: dataInfoInputValue.ordered,
                finished: dataInfoInputValue.reported,
                resource: dataInfoInputValue.resourceName,
                backlog: backlog,
                should_occur_parallel: isParallelProcess
            }
        }

        const details = {
            id: Math.floor(Math.random() * 3),
            startDate: inputValue.startDate,
            endDate: inputValue.endDate,
            startTime: inputValue.startTime,
            endTime: inputValue.endTime,
            totalHours: inputValue.totalHours,
            duration: inputValue.processDuration,
            remark: inputValue.remark
        }

        const processToUpdate={
            ...process,
            backlog: dataInfo.backlog,
            finished: dataInfo.finished,
            should_occur_parallel: dataInfo.should_occur_parallel,
            process_date: moment(details.startDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            process_duration: details.duration,
            process_endDate: moment(details.endDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            quantity: dataInfo.quantity,
            remark: details.remark,
            resource: dataInfo.resource,
            startTime: details.startTime,
            endTime: details.endTime,
        }
        const {ok, result} = await updateProcess(processToUpdate);

        if (ok && result){
            closePopup();
            exitFullscreen();
            updateErrorPopupProcess(true, "הלקוח עודכן בהצלחה!", true)
        }else{
            closePopup();
            exitFullscreen();
            updateErrorPopupProcess(true, "ארעה שגיאה בעדכון לקוח, אנא נסה שנית")
        }
       
    }

    const detailsTabInputToSend = {
        ...detailsTabInput,
        startTime: {
            ...detailsTabInput.startTime,
            placeholder: startTime || '00 30:10',
            value: inputValue.startTime || '00 30:10',
            onChange: handleChange,
            error: inputValue.startTimeError,
            errorText: inputValue.startTimeError && 'start time is required'
        },
        endTime: {
            ...detailsTabInput.startTime,
            placeholder: endTime || '00 30:10',
            value: inputValue.endTime || '00 30:10',
            onChange: handleChange,
            error: inputValue.endTimeError,
            errorText: inputValue.endTimeError && 'end time is required'
        },
        totalHours: {
            ...detailsTabInput.totalHours,
            placeholder: 0, //moment(endTime - startTime).format("HH:mm") || 0,
            value: inputValue.totalHours, //moment(endTime - startTime).format("HH:mm") || 0,
            onChange: handleChange,
            error: inputValue.totalHoursError,
            errorText: inputValue.totalHoursError && 'total hours is required'
        },
        startDate: {
            ...detailsTabInput.startDate,
            placeholder: moment(endDate).format("YYYY-MM-DD") || '2023-06-14',
            value:inputValue.startDate || '2023-06-14',
            onChange: handleChange,
            error: inputValue.startDateError,
            errorText: inputValue.startDateError && 'start date is required'
        },
        endDate: {
            ...detailsTabInput.endDate,
            placeholder: moment(endDate).format("YYYY-MM-DD") || '2023-06-14',
            value: inputValue.endDate || '2023-06-14',
            onChange: handleChange,
            error: inputValue.endDateError,
            errorText: inputValue.endDateError && 'end date is required'
        }
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className='detailsTab'>
                <Grid item xs={12} className='detailsTab__row'>
                    <Grid item xs={12} sm={6} md={8}>
                        <Grid item xs={12} className='detailsTab__row-inputs'>
                            <Grid item xs={6} md={4}><Input data={detailsTabInputToSend.startDate}  view={view === 'tablet' ? 'desktop' : view} /></Grid>

                            &nbsp;
                            <Grid item xs={6} md={4}><Input data={detailsTabInputToSend.endDate}  view={view === 'tablet' ? 'desktop' : view} /></Grid>

                        </Grid>
                        <Grid item xs={12} className='detailsTab__row-inputs'>
                            <Grid item xs={4} md={3}>  <Input data={detailsTabInputToSend.startTime}  view={view === 'tablet' ? 'desktop' : view} /></Grid>

                            &nbsp;
                            <Grid item xs={4} md={3}>  <Input data={detailsTabInputToSend.endTime}  view={view === 'tablet' ? 'desktop' : view} /></Grid>

                            &nbsp;
                            <Grid item xs={4} md={3}> <Input data={detailsTabInputToSend.totalHours}  view={view === 'tablet' ? 'desktop' : view} /></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} className='detailsTab__left detailsTab__left--mobile'>
                        {
                            pending ? <Loader /> : <Grid item xs={12} className={view === 'desktop' || view === 'tablet' ? 'detailsTab__sign--desktop' : 'detailsTab__sign'}>
                                <label>
                                    {
                                        view === 'desktop' && <>&nbsp; &nbsp;</>
                                    }
                                    {TABLET_VIEW_TRANSLATION.CUSTOMER_SIGNATURE}
                                    {
                                        url ? <span className={view === 'desktop' ? 'detailsTab__sign-iconContainer--desktop' : 'detailsTab__sign-iconContainer'}>
                                            <CheckCircleIcon className='detailsTab__sign-icon' />
                                        </span> : <span className={view === 'desktop' ? 'detailsTab__sign-iconContainer--desktop' : 'detailsTab__sign-iconContainer'}>
                                            <CancelIcon className='detailsTab__sign-icon' />
                                        </span>
                                    }
                                </label>
                                {
                                    url ? <img src={`${API}${url}`} alt="signature" className='detailsTab__sign__img' /> : <Grid className={view === 'desktop' ? 'detailsTab__signContainer--desktop' : 'detailsTab__signContainer'}>
                                        <span className={view === 'desktop' || view === 'tablet' ? 'detailsTab__isSigned--desktop' : 'detailsTab__isSigned'}>{TABLET_VIEW_TRANSLATION.NOT_SIGNED}</span>
                                        <div className='detailsTab__signBtn'>
                                            {
                                                view === 'mobile' ? <Button data={detailsTabBtnsMobile.sign} handleShowPopUp={handelShowSignPopup} />
                                                    : view === 'desktop' ? <Button data={detailsTabBtnsMobile.sign} handleShowPopUp={handelShowSignPopup} view={view} />
                                                        : <Button data={detailsTabBtnsMobile.sign} view={'desktop'} handleShowPopUp={handelShowSignPopup} />
                                            }
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}
                 className={view === 'desktop' || view === 'tablet' ? 'detailsTab__textArea--desktop' : 'detailsTab__textArea'}
                 style={{marginBottom: view==='desktop' && '120px'}}
                 >
                    <label>{TABLET_VIEW_TRANSLATION.NOTES}</label>
                    <textarea placeholder={POP_UPS_TRANSLATION.ENTER} name='remark' value={inputValue.remark} onChange={handleTextAreaChange}>
                    </textarea>
                </Grid>
            </Grid>


            <div className={showSignPopup ? 'popUp__background' : ""}>
                {showSignPopup ?
                    <>
                        <DigitalSignaturePopUp showPopUp={showSignPopup} handleShowPopUp={handelShowSignPopup} handelSetUrl={setUrl} view={view} orderId={orderId} />
                    </> : null}
            </div>

            {
                view !== 'mobile' ? <Grid item xs={12} className={view === 'desktop' ? 'fileTab__btnRow--desktop' : 'fileTab__btnRow'}>
                    {
                        view === 'desktop' ? <Grid item xs={12} className='fileTab__btnRow-right' style={{ justifyContent: view === 'desktop' && 'left' }}>
                            <Button data={detailsTabBtnsDesktop.save} handelSubmit={submitForm} />
                            &nbsp;
                            &nbsp;
                            <Button data={detailsTabBtnsDesktop.cancel} handleShowPopUp={closePopup} />
                        </Grid>
                            : <Grid item xs={6} className='fileTab__btnRow-right'>
                                <span style={{ width: detailsTabBtnsTablet.save.width }} onClick={(event) => submitForm(event)}>
                                    <Button
                                        data={{...detailsTabBtnsTablet.save, width: '100%'}}
                                        // handelSubmit={(event) => submitForm(event)}
                                    />
                                 </span>
                                
                                &nbsp;
                                &nbsp;
                                <Button
                                    data={detailsTabBtnsTablet.cancel}
                                    handleShowPopUp={
                                        () => {
                                            closePopup();
                                            exitFullscreen();
                                        }
                                    }
                                />
                            </Grid>
                    }
                    {
                        view !== 'desktop' && <Grid item xs={6} className='fileTab__btnRow-left'>
                            <Button data={detailsTabBtnsTablet.startTimeCount} />
                        </Grid>
                    }
                </Grid> :
                    <Grid item xs={12} className={'fileTab__btnRow--mobile'}>
                        <Button data={detailsTabBtnsMobile.save} handelSubmit={submitForm} />
                        &nbsp;
                        &nbsp;
                        <Button data={detailsTabBtnsMobile.cancel} handleShowPopUp={closePopup} />
                    </Grid>
            }
        </Grid >
    )
}
