import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./CircularProgress.scss"


function CircularProgressWithLabel(props) {

    return (
        <div style={{ top: '0px', display: 'flex', margin: '1px', padding: '1px' }}>
            <div style={{ position: 'sticky', display: 'inline-flex', padding: '0px' }}>
                <CircularProgress 
                    size = "1rem"
                    variant="determinate" {...props} 
                    thickness="5" 
                    color="secondary" 
                    sx={{ color: "red", 
                    position: 'absolute', left: 0, top: 0, }} 
                    style={{ 'color': '#3C93D6', 
                    transform: 'scale(0.5) rotate(-90deg)', 
                    zIndex: 1,
                    margin: '0px',
                    padding: '0px',
                    transformOrigin: 'center',
                }} />

                <CircularProgress
                    variant="determinate"
                    thickness={5}
                    value={100}
                    style={{
                        transform: 'scale(0.5) rotate(-90deg)', 
                        position: 'absolute', 
                        color: '#efefef',
                        left: 0,
                        margin: '0px',
                        padding: '0px',
                        transformOrigin: 'center',
                        opacity: 0.99
                    }}
                />
                <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '10px' }}>
                    <p>
                        {props?.value? `${Math.round(props.value)}%`: '0%'}
                    </p>
                </div>
            </div>
        </div>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel(props) {
    const {data} = props;
    return <CircularProgressWithLabel value={data} />;
}