import React, { useState } from 'react'
import AccordionRow from './AccordionRow';
import AngleArrowImg from '../assets/AngleArrow-img.svg';
import "./AccordionMain.scss"

export default function AccordionMain(props) {
    const { data, onClickHandler, popupClickHandlers, systemId, rowIndex } = props;
    let { view } = props;
    const [isVisible, setIsVisible] = useState(false);

    const funcExpand = () => {
        setIsVisible(!isVisible);
    };

    // changing the view for this component only, so the UI will same fot both the case
    if (view === 'desktop-popup') {
        view = 'desktop'
    }

    return (
       data && data[0]?.data.length > 0 ? <>
            <div>
                <AccordionRow
                    rowIndex={rowIndex}
                    systemId={systemId && systemId}
                    data={data && data[0]}
                    isIcon={true}
                    showNext={funcExpand}
                    view={view}
                    onClickHandler={onClickHandler}
                    popupClickHandlers={popupClickHandlers}
                    nextChildSystemData={data}
                />
            </div>

            {
                view === 'desktop' ?
                    <>
                        {
                            isVisible && data.map((item, index) => {
                                return <div key={index} className='accordion__nextRow' style={{ marginRight: view === 'desktop' && '20px' }}>
                                    {data.length > 1 && index !==0 ? <span className='verticalLine'></span> : null}
                                    <span><img src={AngleArrowImg} alt='icon' /></span>
                                    <span style={{ width: '100%' }}>
                                        <AccordionMain rowIndex={index} systemId={systemId && systemId} showNext={funcExpand} data={item.data} view={view} onClickHandler={onClickHandler} popupClickHandlers={popupClickHandlers} />
                                    </span>
                                </div>
                            })
                        }
                    </> : <>
                        {
                            isVisible && data.map((item, index) => {
                                return <div key={index} style={{ marginRight: view === 'desktop' && '20px' }}>
                                    <AccordionMain rowIndex={index} showNext={funcExpand} data={item.data} view={view} onClickHandler={onClickHandler} popupClickHandlers={popupClickHandlers} />
                                </div>
                            })
                        }
                    </>
            }


        </> :
            <div>
                <AccordionRow
                    rowIndex={rowIndex}
                    data={data && data[0]}
                    isIcon={false}
                    showNext={funcExpand}
                    view={view}
                    onClickHandler={onClickHandler}
                    popupClickHandlers={popupClickHandlers}
                    nextChildSystemData={data}
                />
            </div>

    )
}
