import React, { useEffect, useState } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd';

function Completions(props) {
    const {completionArrays, addMoreCompletion, daysOffNumber, initProccessUpdatePopup} = props;

    const handleProcessMenu = (data,date) => {
        initProccessUpdatePopup(data,date)
    }
    return (
        <div className="month-view-page__completions" style={{width: `calc( 98% / ${ 7 - daysOffNumber + 1})`}}>
            <header>השלמות</header>
            <Droppable droppableId="completions__droppable">
                {(provided)=>(
                    <section className="month-view-page__completions__droppable" id='monthly-completion-ix' onScroll={addMoreCompletion} ref={provided.innerRef} {...provided.droppableProps}>
                            {completionArrays.map((data, index)=>{
                                return <Draggable draggableId={data.proccess._id} index={index} key={data.proccess._id}>
                                    {(provided)=>( 
                                        <div id={`${data.proccess._id}m-c`} onClick={()=>{handleProcessMenu(data, null)}} className="month-view-page__completions__draggable"  {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                            <section>
                                                <div>{data.proccess.order_number} - {data.proccess.client_name}</div>
                                                <div>{data.proccess.process_name}</div>
                                                <div>הושלמו {data.proccess.finished} מתוך {data.proccess.quantity}</div>
                                            </section>
                                        </div>
                                    )}
                                </Draggable> 
                            })}
                    </section>
                )}
            </Droppable>
        </div>
    )
}

export default Completions