import { Grid } from '@material-ui/core'
import React from 'react';
import './OpenedFilePopUp.scss'
import Button from '../../Buttons/Button';
import { CANCEL } from '../../../../constants/translations/review-popup';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { API } from '../../../../tools/keys/keys';

const cancel = {
    name: CANCEL,
    width: '42%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: true,
    isBoxShadow: true,
    borderRadius: '20px'
}
const cancelBtnTablet = {
    ...cancel,
    height: '70px',
    borderRadius: '90px',
    fontSize: '24px',
    color: '#455768'
}
const cancelBtnMobile = {
    ...cancel,
    height: '36px',
    borderRadius: '20px',
    fontSize: '12px'
}


export default function OpenedFilePopUp(props) {
    const { handleShowPopUp, view, attachmentFile, handleClick } = props;

    const getFileFullPath = `${API}${attachmentFile}`;

    return (
        <Grid container spacing={0} className={view === 'tablet' ? 'openedFilePopUp__main--tablet' : 'openedFilePopUp__main'} style={{ left: view === 'desktop' && '5%' }} onClick={handleClick}>
            <Grid xs={12}>
                <h4 className={view === 'tablet' ? 'addFilePopUp__title--tablet' : 'addFilePopUp__title'}>{POP_UPS_TRANSLATION.OPENED_FILE}</h4>
            </Grid>
            <Grid xs={12} className={view === 'tablet' ? 'openedFilePopUp__fileContainer--tablet' : 'openedFilePopUp__fileContainer'}>
                <Grid xs={12} style={{ background: view === 'desktop' && 'transparent'}} className={view === 'tablet' ? 'openedFilePopUp__skeletonContainer--tablet' : 'openedFilePopUp__skeletonContainer'} >
                    {
                        attachmentFile &&  getFileFullPath ? <div className='openedFilePopUp__attachment'>
                            <img src={getFileFullPath} alt='attachment file' />
                        </div> :
                            <>
                                <Grid xs={12}>
                                    <h4 className='openedFilePopUp__skeletonContainer__name'>File’s name</h4>
                                </Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__row'>
                                    <Grid xs={6} className='openedFilePopUp__skeletonContainer__mobile marginLeft' >
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '34px', marginBottom: '7px' }}></Grid>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '17px' }}></Grid>
                                    </Grid>
                                    <Grid className='openedFilePopUp__skeletonContainer__desktop marginLeft' xs={6}>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '14px' }}></Grid>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '14px' }}></Grid>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '14px' }}></Grid>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '14px' }}></Grid>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '14px' }}></Grid>
                                    </Grid>
                                    <Grid xs={6} className='marginRight'>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__fullBox' style={{ height: '20px' }}></Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__row desktopOnly openedFilePopUp__skeletonContainer__desktop'>
                                    <Grid xs={6} className='marginLeft'>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                    </Grid>
                                    <Grid xs={6} className='marginRight'>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__row mobileOnly openedFilePopUp__skeletonContainer__mobile' >
                                    <Grid xs={4} className='marginLeft'>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                    </Grid>
                                    <Grid xs={8} className='marginRight'>
                                        <Grid className='openedFilePopUp__skeletonContainer__fullBox'></Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__fullBox openedFilePopUp__skeletonContainer__mobile' style={{ height: '20px' }}></Grid>
                                <Grid xs={12} className='openedFilePopUp__skeletonContainer__fullBox openedFilePopUp__skeletonContainer__mobile'></Grid>
                            </>
                    }
                </Grid>
            </Grid>



            {
                view === 'mobile' ? <Grid sx={12} className={view === 'tablet' ? 'openedFilePopUp__btn__container--tablet' : 'openedFilePopUp__btn__container'}>
                    <Button data={cancelBtnMobile} handleShowPopUp={handleShowPopUp} />
                </Grid>
                    : view === 'tablet' ? <Grid sx={12} className={view === 'tablet' ? 'openedFilePopUp__btn__container--tablet' : 'openedFilePopUp__btn__container'}>
                        <Button data={cancelBtnTablet} handleShowPopUp={handleShowPopUp} />
                    </Grid>
                        : <Grid sx={12} className={view === 'tablet' ? 'openedFilePopUp__btn__container--tablet' : 'openedFilePopUp__btn__container'}>
                            <Button data={cancel} handleShowPopUp={handleShowPopUp} />
                        </Grid>
            }
        </Grid>

    )
}
