import { Grid, makeStyles, FormHelperText } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import "./Input.scss";


const useStyles = makeStyles({
    inputStyleCls: {
        height: '70px',
        fontSize: '24px',
        marginTop: '10px',
        background: 'red'
    }
});

export default function Input(props) {
    const { label, placeholder, value, name, type, width, error, errorText, onChange, id, disabled } = props.data;
    const {view} = props;

    const [inputValue, setInputValue] = useState(value ? value : '')

    const handleChange = (e) => {
        setInputValue(e.target.value)
        onChange(name, e.target.value);
    }

    useEffect(() => {
        if(value){
            setInputValue(value)
        }
    }, [value])

    return (
        <Grid className={`inputContainer ${type === 'number' ? 'input--removeArrows' : ''}  ${view === 'tablet' && 'inputContainer--tablet'}`}>
            <label style={{ fontSize: view === 'tablet' && '24px' }}>{label}</label>
            <input
                id={id && id}
                placeholder={placeholder}
                type={type} 
                value={inputValue}
                style={{ width: width ? width : '100%', fontSize: view === 'tablet' && '24px', cursor: type === 'date' || type === 'time' && 'pointer' }}
                onChange={handleChange}
                // error={error && error}
                // error={error && error.toString()}
                // helpertext={errorText && errorText.toString()}
                className={error ? 'input__error' : ''} 
                disabled={disabled && disabled}
                />
            {
                error ? <div className='errorContainer'><FormHelperText className='inputError'>{errorText}</FormHelperText></div> : null
            }
        </Grid>
    )
}
