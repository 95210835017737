import React, { useEffect, useState } from 'react'
import Header from './Header/TopHeader/Header'
import CustomerInfoEditSection from './CustomerInfoEditSection/CustomerInfoEditSection'
import { Grid } from '@material-ui/core'
import CustomerDetails from './CustomerDetails/CustomerDetails'
import TopStrap from './Header/Strap/TopStrap'
import BlackStrap from './Header/BlackStrap/BlackStrap'
import FileTab from './MobileViews/FileTab/FileTab'
import MobileHeader from '../Customer/MobileViews/Header/MobileHeader';
import "./Customer.scss";
import Footer from './MobileViews/Footer/Footer'
import Systems from './MobileViews/Systems/Systems'
import { makeStyles } from "@material-ui/core";
import TabletViewMainScreen from './TabletView/TabletViewMainScreen/TabletViewMainScreen'
import MobileLandingPage from './MobileViews/MobileLandingPage/MobileLandingPage'
import TaskTab from './MobileViews/TaskTab/TaskTab'
import DetailsTab from './MobileViews/DetailsTabMobile/DetailsTabMobile'
import { useDispatch, useSelector } from 'react-redux'
import { fileActionCreator } from '../../actions/customer/fileActions'
import { taskActionsCreator } from '../../actions/customer/taskActions'
import useQueryParams from '../../hooks/useQueryParams/useQueryParams'
import CustomerInfoSidebar from './CustomerInfoSidebar/CustomerInfoSidebar'



export default function Customer() {
  const dispatch = useDispatch();
  const fileTableMobileData = useSelector((state) => state.files.filesMobile)
  const taskTableMobileData = useSelector((state) => state.tasks.tasksMobile);
  const [initialView, setInitialView] = useState(() => getInitialView());
  // const [dynamicView, setDynamicView] = useState(initialView);

  let queryView = useQueryParams();
  const paramView = queryView.get("view");

  const useStyles = makeStyles(() => ({
    mainContainer: {
      width: '100%',
      height: 'auto',
      background: '#ffffff',
      paddingTop: '10px'
    }
  }));



  function getInitialView() {
    let view;
    if (window.innerWidth <= 600) {
      view = 'mobile';
    } else if (window.innerWidth >= 601 && window.innerWidth <= 1000) {
      view = 'tablet';
    } else {
      view = 'desktop';
    }
    return view;
  }

  function handleResize() {
    setInitialView(() => getInitialView())
  }

  const classes = useStyles();

  useEffect(() => {
    dispatch(fileActionCreator.getFilesListMobile());
    dispatch(taskActionsCreator.getTasksListMobile());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.addEventListener('resize', handleResize);
  }, [])

  // useEffect(() => { 
  //   setDynamicView(paramView);
  // }, [paramView]);

  // console.log("dyanmic view size", dynamicView)
  return (
    <>
      {/* 💻 Desktop view 💻 */}
      <div className={paramView === 'desktop' ? 'desktopView display__block' : paramView === 'tablet' || paramView === 'mobile' ? 'display__none' : 'desktopView'}>
        <>
          <Header />
          <TopStrap data={false} />
        </>
        <div className={classes.mainContainer}>
          <BlackStrap data={false} />

          <div style={{ margin: '10px 20px' }}>
            <CustomerInfoEditSection />
            <Grid container>
              <Grid xs={3} style={{ padding: '10px 0px 10px 10px' }}> 
              <CustomerInfoSidebar />
              </Grid>
              <Grid xs={9} style={{ width: '80%', height: '400px', padding: '10px 0px 10px 0px' }}>
                <CustomerDetails view={paramView ? paramView : initialView} />
              </Grid>
            </Grid>
          </div>
        </div>
      </div> 
      {/* 💻 Desktop view 💻


      {/* 🔖 Tablet view 🔖 */}

      <div className={paramView === 'tablet' ? 'tabletView display__block' : paramView === 'desktop' || paramView === 'mobile' ? 'display__none' : 'tabletView'}>
        <TabletViewMainScreen view={paramView ? paramView : initialView} />
      </div>

      {/* 🔖 Tablet view 🔖*/}


      {/* 📲  Mobile view  📲  */}

      <div className={paramView === 'mobile' ? 'mobileView display__block' : paramView === 'tablet' || paramView === 'desktop' ? 'display__none' : 'mobileView'}>
        {/* <Systems view={initialView} /> */}
        {/* <Systems /> */}
        <MobileLandingPage view={paramView ? paramView : initialView} />
        {/* <TaskTab data={taskTableMobileData} /> */}
        {/* <DetailsTab/> */}
        {/* <FileTab data={fileTableMobileData} /> */}
      </div>

      {/* 📲  Mobile view  📲  */}
      {/* </div> */}
    </>
  )
}
