import * as momentBusinessDays  from 'moment-business-days';
import { SERVICE } from '../constants';
import moment from "moment"
import { ACTIVE_TIME_STAMP, REFRESH_TIME, URL_HISTORY, URL_NAVIGATION_HISTORY } from '../constants/offline-mode';
import { mobileMaxWidth } from '../constants/responsive-pop-up';
let uniqId = require("uniqid");

momentBusinessDays.updateLocale('us', {
    workingWeekdays: [0, 1, 2, 3, 4 ] 
});

export const closePopupOnBackButton = (popup, callBack) => {
    if(popup){
        callBack(true);
    }else{
        callBack(false)
    }
}


export const createPopupUrl = (history) => {
    let isVerified =  ['customers-page','daily-vertical-processes','yearly','backlogs'].filter(item => window.location.pathname.includes(item)).length === 0;
    let isExist = window.location.pathname.includes('p8zi');
    let search = window.location.search;
    (isVerified && !isExist) && history.push(window.location.pathname+`/p8zi${uniqId()}${search}`);
}

export const removePopupUrl = (history) => {
    let is_popup_url = window.location.pathname.includes('p8zi')
    is_popup_url && history.goBack();
}

export const calculateEndDateAccordingToDuration = (processDate, process, user,original_date = null, original_duration = null) => {
    let {working_hours: workingHours, type_of_factory: typeOfFactory, holidays} = JSON.parse(JSON.stringify(user));
    // Start date and time + Duration + Quantity * order units
    const {duration_days: durationDays,initial_duration: actualDuration } = process
    // for SERVICE factory we have timer where calculation is in miliseconds, in other factories actual duration is in hours
    if(typeOfFactory !== SERVICE){
        let date = (moment(original_date || processDate).set('hours',16)).toDate();
        let endDate = findValidEndDate(date, holidays, original_duration, null)
        let endDateSelectedTime = moment(endDate).set('hours',16).toDate()
        return endDateSelectedTime
    }
}

const findValidEndDate = (start_date, holidays , duration, end_day) => {
    let endDate = momentBusinessDays(start_date, 'DD-MM-YYYY').businessAdd(duration)._d;
    let holiday_count = holidays.filter( item => (new Date(item.date) >= new Date(start_date) && new Date(item.date) <= new Date(endDate) && item.status !== 'Half Day'));
    if(holiday_count.length){
        return findValidEndDate(start_date, holidays.filter(i_data => !(holiday_count.filter(f_data => f_data === i_data).length > 0)),(duration + holiday_count.length));
    }
    return endDate
}

export const captureTimeStamp  = () => {
    let log_time_stamp = JSON.parse(localStorage.getItem(ACTIVE_TIME_STAMP));
    let current_time = new Date().getTime();
    (log_time_stamp && (current_time - log_time_stamp >= REFRESH_TIME)) && window.location.reload();
    localStorage.setItem(ACTIVE_TIME_STAMP, current_time);
}

export const HtmlTooltipStyled = () => {
    return {
        backgroundColor: 'white',
        color: '#455768',
        padding: '0px',
        margin: '0px !important',
        width: '222px',
        fontFamily: 'Rubik',
        borderRadius: '6px',
        border: '2px solid #BEBEBE',
    };
}

export const getPosition = (position, date) => new Date(date).getDay() === 0 ?  position.initial : position.last;
export const dateMatch = (date) => date.getFullYear() + date.getMonth() + date.getDate();
export const updateClass = (process) => process.duration_days !== process.original_duration ? `${process.process_id}${process.order_id}xix` : `${process.process_id}${process.order_id}`;
export function currentAndPrevUrlSnapShot(path) {
    let url_history = JSON.parse(localStorage.getItem(URL_HISTORY));
    let url = window.location.pathname;
    if(url !== url_history?.url)localStorage.setItem(URL_HISTORY,JSON.stringify({url:url,previous: url_history?.url}));
}
export const convertIntoCurrency = (amount, region) => new Intl.NumberFormat('en-US', {style: 'currency',currency: region,}).format(amount || 0);
export const getPercentAmount = (percentValue, amount) => Number(((Number(percentValue) / 100) * Number(amount)).toFixed(2));
export const getAmountIncludingExtraCharges = (amount, order_discount = 0) => Number((amount - getPercentAmount(order_discount, amount)).toFixed(2));
export const setNavigationUrl = (url) => {
    let navigation_url_history = JSON.parse(localStorage.getItem(URL_NAVIGATION_HISTORY)) || [];
    localStorage.setItem(URL_NAVIGATION_HISTORY, JSON.stringify([...navigation_url_history, url]))
}
export const reSetNavigationUrl = (input) => localStorage.setItem(URL_NAVIGATION_HISTORY, JSON.stringify([...input]))

export const goBack = (history) => {
    let url_history = JSON.parse(localStorage.getItem(URL_NAVIGATION_HISTORY)) || []
    url_history = [...url_history]
    let poped_url = url_history?.pop();
    if(poped_url) {
        history.push(poped_url);
        reSetNavigationUrl(url_history)
    }
}

export const getAllExtraCharges = (input) => {
    if(Array.isArray(input))
    return input.reduce((prev, { amount }) => {
        return Number(amount) + Number(prev);      
    }, 0)

    return 0;
}

export const isMobileView = () => window.innerWidth <= mobileMaxWidth;

const monthlyDataPagination = () => {
    const cache = {
        completedDay: -1,
        calendarDay: 6,
        viewSize: 50,
        fIndex: 0,
        next: true,
    };
    const resetCache = () => {cache.completedDay= -1; cache.calendarDay=6; cache.viewSize=50; cache.fIndex=0; cache.next=true};
    return (datesArray, {reset = false, addMore = true}, cb) => {
        reset && resetCache()
        let { completedDay, calendarDay, viewSize, fIndex, next } = cache;
        let target = false;
        if(datesArray.length > 0){
            let data = [];
            if(addMore)
            while (next) {
                for(fIndex; fIndex <= calendarDay; fIndex++){
                    if(datesArray[fIndex]?.dayData.length >= viewSize){
                        target = true;
                        next = false; 
                        break;
                    }
                }
                if(next){
                    fIndex = calendarDay;
                    calendarDay += 7;
                    next = fIndex < 27;
                    viewSize = 50;
                    completedDay += 7
                }
            }
            datesArray.map((item, index) => {
                if(index < calendarDay ){
                    data.push({
                        ...item,
                        dayData: item.dayData.filter((i, ind) => (index > completedDay) ? ind < viewSize : true),
                    });
                }
            });
            cache.completedDay = completedDay;
            cache.calendarDay = calendarDay;
            cache.viewSize = target ? viewSize += 50 : viewSize;
            cache.fIndex = fIndex;
            typeof cb === 'function' && cb();
            return data;
        } else {
            return []
        }
    }
}
export const monthlyDataView = monthlyDataPagination();

const weeklyDataPagination = () => {
    const cache = {
        completedIndex: -1,
        weeklyIndex: 0,
        viewSize: 20,
        next: true,
    };
    const resetCache = () => {cache.completedIndex= -1; cache.weeklyIndex=0; cache.viewSize=20; cache.next=true};
    return (weeklyArray, {reset = false, addMore = true}, cb) => {
        reset && resetCache()
        let { completedIndex, weeklyIndex, viewSize, next } = cache;
        let target = false;
        if(weeklyIndex <  weeklyArray.length && weeklyArray.length > 0){
            let data = [];
            if(addMore)
            while (weeklyIndex < weeklyArray.length && next) {
                for(let i=0; i<5; i++){
                    if(weeklyArray[weeklyIndex].processes[i].length >= viewSize){
                        target = true;
                        next = false; 
                        break;
                    }
                }
                if(next){
                    weeklyIndex++;
                    next = weeklyIndex < weeklyArray.length;
                    viewSize = 20;
                    completedIndex++;
                }
            }
            weeklyArray.map((item, index) => {
                if(index <= weeklyIndex ){
                    data.push({
                        ...item,
                        processes: item.processes.map(process => process.filter((i, ind) => (index > completedIndex) ? ind < viewSize : true)),
                    });
                }
            });
            cache.completedIndex = completedIndex;
            cache.weeklyIndex = weeklyIndex;
            cache.viewSize = target ? viewSize += 20 : viewSize;
            typeof cb === 'function' && cb();
            return data;
        }else return []
    }
}
export const weekDataView = weeklyDataPagination();

const onScrollHandler = () => {
    let cache = {
        isDataProcess: false,
    }
    return (cb) => async (event) => {
        let { scrollTop, scrollHeight, clientHeight } = event.target;
        let scrollBound = (5 / 100) * Number(scrollHeight || 0);
        if((scrollHeight - Math.ceil(scrollTop + clientHeight)) <= scrollBound && !cache.isDataProcess){
            cache.isDataProcess = true; 
            if(cb && typeof cb === 'function') {
                let response = cb(event);
                if(response && typeof response?.then === 'function'){
                    response.then(res => {
                        setTimeout(() =>{cache.isDataProcess = false;},0)
                    }).catch(err => setTimeout(() =>{cache.isDataProcess = false;},0));
                }else setTimeout(() =>{cache.isDataProcess = false;},0);
            }else setTimeout(() =>{cache.isDataProcess = false;},0)
        }
    }
}
export const onScroll = onScrollHandler();

export const getBusinessDays = (date, toFuture=1) => {
    while(!momentBusinessDays(date, 'MM-DD-YYYY').isBusinessDay()){
        date = moment(date).set({hour: 16}).add(toFuture,'day').toDate()
    }
    return date
}

export const isWeekChange = (currentDate, movedToDate,toFuture=1, option='days') =>  {
    if(!currentDate || !movedToDate)return false; 
    movedToDate = getBusinessDays(movedToDate,toFuture);
    let isDifferentWeek =  moment(movedToDate).week() !== moment(currentDate).week();
    let diff = moment(
        moment(movedToDate).set({ hour: 14, minute: 0, second: 0 }).toDate()
    ).diff(
        moment(currentDate).set({ hour: 14, minute: 0, second: 0 }).toDate(), option
    ) || 0;
    return Math.abs(diff) > 0 && isDifferentWeek;
}

