import { Box, Fade, Grid, Modal, Typography } from '@material-ui/core';
import React, {useState} from 'react';
import "./AddStepPopUp.scss"
import SelectInput from '../../InputFields/SelectInput/SelectInput';
import Input from '../../InputFields/Input';
import Button from '../../Buttons/Button';
const add = {
    name: 'Add',
    width: '40%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: false,
    isBoxShadow: true,
    buttonName: 'AddIcon'
}
const cancel = {
    name: 'Cancel',
    width: '40%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    inconContainer: false,
    border: true,
    isBoxShadow: true,
    buttonName: 'AddIcon'

}
const stepInputs = {
    stepName: {
        label: 'Step name',
        placeholder: 'Choose',
        value: 'Enter',
        type: 'select',
        // width: '10%'
    },
    percent: {
        label: 'Percent',
        placeholder: 'Choose',
        value: 'Enter',
        type: 'number',
        // width: '10%'
    },
    sum: {
        label: 'Sum',
        placeholder: 'Choose',
        value: 'Enter',
        type: 'number',
        // width: '10%'
    },
    reminder: {
        label: 'Reminder (days)',
        placeholder: 'Choose',
        value: 'Enter',
        type: 'number',
        // width: '10%'
    },

};
export default function AddStepPopUp(props) {
    const { handleShowPopUp, showPopUp, processesDataNames, processesOrder, processesDataIds, addProcessOrderStage } = props;
    console.log("processesOrder", processesOrder);
    const [percentage, setPercentage] = useState(null);
    const [name, setName] = useState(null);
    const [reminderInDays, setReminderInDays] = useState(0);
    const [sum, setSum] = useState(0);
    const [errors, setErrors] = useState({});


    const onChange = (name, value) => {
        if (name === 'percentage') {
            setPercentage(value);
        } else if (name === 'name') {
            setName(value);
        } else if (name === 'reminderInDays') {
            setReminderInDays(value);
        } else if (name ==='sum') {
            setSum(value);
        } else {
            console.log('no action');
        }
    }


    const handleAddStep = () => {
        const errors = {};
        // if (sum === 0) {
        //     errors['sum'] = '* required';
        // }

        if (!percentage) {
            errors['percentage'] = '* required';
        }

        if (reminderInDays === 0) {
            errors['reminderInDays'] = '* required';
        }

        if (!name) {
            errors['name'] = '* required';
        } else if (name) {
            const idx = processesOrder.stages.findIndex((obj) => {
                return obj.process_id === name.id
            });

            if (idx >=0) {
                errors['name'] = 'duplicate process';
            }
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
        } else {
            setErrors({});
            addProcessOrderStage({
                process_id: name.id,
                percentage: percentage,
            })
        }
    }

    return (
        <>
            {/* <Modal
           // className='addStepPopUp__main'
            open={showPopUp}
           // onClose={handleShowPopUp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={showPopUp}>
                <Box>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                </Box>
            </Fade>
        
            this is modal
        </Modal> */}
            <aside className="reservation-popup__curtain reservation-popup__curtain--active"></aside>
            <Grid container spacing={0} className='addStepPopUp__main'>
                <Grid xs={12}>
                    <h4 className='addStepPopUp__name'>Add a step</h4>
                </Grid>
                <Grid xs={12} className='addStepPopUp__inputRow'>
                    <Grid xs={6} className='addStepPopUp__inputRow__input__left'>
                        <SelectInput data={{...stepInputs.stepName, name: 'name', selectOptions: processesDataNames.map((name, index) => { return {name, id: processesDataIds[index]}}), handleChange: onChange, error: !!(('name' in errors) && errors['name'].length > 0), errorText: errors['name']}} />
                    </Grid>
                    <Grid xs={6} className='addStepPopUp__inputRow__input__right'>
                        <Input data={{...stepInputs.percent, name: 'percentage' , handleChange: onChange, error: !!(('percentage' in errors) && errors['percentage'].length > 0), errorText: errors['percentage']}} />
                    </Grid>
                </Grid>
                <Grid xs={12} className='addStepPopUp__inputRow'>
                    <Grid xs={6} className='addStepPopUp__inputRow__input__left'>
                        <SelectInput data={{ ...stepInputs.sum, name: 'sum', handleChange: onChange, error: !!(('sum' in errors) && errors['sum'].length > 0), errorText: errors['sum']}}  />
                    </Grid>
                    <Grid xs={6} className='addStepPopUp__inputRow__input__right'>
                        <Input data={{ ...stepInputs.reminder, name: 'reminderInDays',handleChange: onChange, error: !!(('reminderInDays' in errors) && errors['reminderInDays'].length > 0), errorText: errors['reminderInDays']}} />
                    </Grid>
                </Grid>
                <Grid xs={12} className='addStepPopUp__btn__container'>
                    <Button data={add} handleShowPopUp={handleAddStep}/>
                    &nbsp;
                    &nbsp;
                    <Button data={cancel} handleShowPopUp={handleShowPopUp} />
                </Grid>
            </Grid>
        </>
    )
}
