import { getWeeksBetweenDates } from "./calculateWeekBetweenDates";

export const addOverlapsToEachColumnByOrder = (order) => {
    const processMapByID = {};
    const processes = order.processes;
    for (const process of processes) {
        const id = process.process_id;
        processMapByID[id] = process;
    }

    for (let i = 0; i < order.columnOrder.length; i++) {
        const columnOrder = order.columnOrder[i];
        const processesInColumn = order.columns[columnOrder].processId;
        for (let m = 0; m < processesInColumn.length; m++) {
            const processId = processesInColumn[m];
            const processDetailsById = processMapByID[processId];
            const {
                process_date: startDate,
                process_endDate: endDate,
            } = processDetailsById;

            // const weekSpan =  startDate && endDate ? (moment(endDate).week() - moment(startDate).week() + 1): 0;
            const weekSpan = getWeeksBetweenDates(startDate, endDate);
            if (weekSpan === 1) {
                continue;
            }
            const currentColumnOrderInfo = order.columns[columnOrder];
            const currentColumnOrderOverlaps = 'overlaps' in currentColumnOrderInfo ? currentColumnOrderInfo.overlaps : 0;
            let j = 1;
            while (j < weekSpan && j < (order.columnOrder.length - i)) {
                const nextColumnOrder = order.columnOrder[i + j];
                const nextColumnOrderColumnInfo = order.columns[nextColumnOrder];
                nextColumnOrderColumnInfo.overlaps = (m + 1 + currentColumnOrderOverlaps);
                j++;
            }
        }
    }
};


export const addOverlaps = (orderData) => {
    const orders = orderData.orders;
    for (const order in orders) {
        addOverlapsToEachColumnByOrder(orders[order]);
    }
    return orderData;
}
