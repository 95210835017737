import { FormControl, Grid, MenuItem, Select, styled, makeStyles, FormHelperText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import "./SelectInput.scss"


const useStyles = makeStyles((theme) => ({
    select: {
        '& .MuiSelect-outlined.MuiSelect-outlined:focus': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.grey[500],
        },
    }
  }));

export default function SelectInput(props) {
    const classes = useStyles();
    const { 
        label, placeholder, value, type, width, height, border, inputFor, 
        selectOptions, handleChange: onChangeFn, name, error, errorText
     } = props.data;
    const [selectValue, setSelectValue] = useState(value);
    const {view} = props;
    const handleChange = (e) => {
        onChangeFn(name, e.target.value);
        setSelectValue(e.target.value);
    };

    useEffect(() => {
        if (value) {
            setSelectValue(value)
        }
    }, [value])

    function renderSelectedValue(selected){
        if(!selected){
            return placeholder;
        }
        if (typeof selected === 'object'){
            return selected.name ? selected.name : selected.full_name;
        }else {
            return selected
        }
    }

    return (
        <Grid xs={12} 
        className={`${view === 'tablet' && 'select-input-container--tablet'} select-input-container`} 
            style={{ height: height && height}}>
            <FormControl className="select-input-container__form" style={{ width: width && width}}>
                {label ? <label style={{ fontSize: view==='tablet' && '24px'}}>{label}</label> : null}
                <Select
                    className={`${view === 'tablet' && 'select-input-container__select--tablet'} select-input-container__select ${classes.select}`} 
                    value={selectValue}
                    name={name}
                    onChange={(e) => handleChange(e)}
                    style={{ width: width, border: border, height: height && height, textAlign: 'right'}}
                    MenuProps={{
                        disablePortal: true,
                    }}
                    error={error}
                    renderValue={(selected) => {
                       return renderSelectedValue(selected)
                    }}
                    variant='outlined'
                    displayEmpty
                   // sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                >
                    <MenuItem value={0} disabled style={{ fontSize: view ==='tablet' ? '24px' : '14px', }}>
                        {placeholder}
                    </MenuItem>
                    {
                        selectOptions && selectOptions.length > 0 
                        ?
                        selectOptions.map((selectionOption, index) => {
                            return <MenuItem key={index} value={selectionOption}>{selectionOption.name || selectionOption.full_name}</MenuItem>
                        })
                        : 
                        <MenuItem value={''}></MenuItem>
                    }
                    
                </Select>
                {
                    error ? <div className='errorContainer'><FormHelperText className='inputError'>{errorText}</FormHelperText></div>  : null
                }
            </FormControl>
        </Grid>
    )
}

{/* {label ? <label htmlFor="demo-customized-select-native" style={{ fontSize: inputFor === 'dataInforamtionInput' && '24px', marginBottom: view==='desktop' && '5px'}}>{label}</label> : null} */ }

// sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}