/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import "./PaymentDetails.scss";
import { Grid } from '@material-ui/core';
import Table from '../../Customer/Table/Table2';
import Button from '../../Customer/Buttons/Button';
import AddStepPopUp from '../../Customer/PopUps/AddStepPopUp/AddStepPopUp';
import { getOrderSystems } from '../../../functions/api/orders';

const paymentDetailsTableData = {
    popUpData: [

        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
        {
            label: "Move Up",
            icon: 'up'
        },
    ],
    columns: [
        {
            field: 'stepID',
            headerName: 'Step ID',
        },
        {
            field: 'stepName',
            headerName: 'Step Name',
        },
        {
            field: 'percent',
            headerName: 'Percent (%)',
        },
        {
            field: 'sum',
            headerName: 'Sum',
        },
        {
            field: 'reminder',
            headerName: 'Reminder (days)',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    rows: [
        { id: 1, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 2, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 3, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 4, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        { id: 5, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '50px', height: '20px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        // { id: 6, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '100px', height: '30px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        // { id: 7, stepID: 'S000111', stepName: 'Advance', percent: { label: '', placeholder: '10', width: '100px', height: '30px', border: 'none' }, sum: '22,236.80', reminder: '7' },
        // { id: 8, stepID: 'S000111', stepName: 'Advance', percent: '10', sum: '22,236.80', reminder: '7' },
    ],
}
const step = {
    name: 'Step',
    width: '30%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#186315",
    fontSize: '16px',
    fontWeight: 800,
    icon: true,
    iconContainer: true,
    border: false,
    isBoxShadow: true,
    buttonName: 'AddIcon'
}
const add = {
    name: 'Add',
    width: '35%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
}
const cancel = {
    name: 'Cancel',
    width: '35%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
}
export default function PaymentDetails(props) {
    const { handleShowPopUp, showPopUp, processesOrder, processesDataNames, processesDataIds, formattedValue, handleClosePopup, orderId } = props;
    const [showStepPopUp, setShowStepPopUp] = useState(false);
    const [paymentDetailsTableRows, setPaymentDetailsTableRows] = useState([])


    function handleShowStepPopUp() {
        setShowStepPopUp(!showStepPopUp)
    }


    // const _paymentDetailsTableData = [];
    // processesOrder && processesOrder.stages.forEach((stage, index) => {
    //     let pc = (stage.percentage / 100);
    //     pc = pc % 1 !== 0 ? pc.toFixed(2) : pc;
    //     const idx = processesDataIds.findIndex((item) => {
    //         return item === stage.process_id;
    //     });
    //     _paymentDetailsTableData.push({
    //         id: 1, stepID: 'S000111', stepName: processesDataNames[idx], percent: { label: '', placeholder: stage.percentage, width: '50px', height: '20px', border: 'none' }, sum: pc, reminder: '7',
    //     });

    // });


    // paymentDetailsTableData.rows = _paymentDetailsTableData;

    const addProcessOrderStage = (value) => {
        processesOrder.stages.unshift({
            ...value,
        });
        handleShowStepPopUp();
    }
    
    const paymentTableList = [];
    async function getFormData() {
        const formData = await getOrderSystems(orderId)
        const ordersPercentSelectionOptions = [10, 20, 30, 40]

        const data = formData.result?.collection_stages.length > 0 && formData.result?.collection_stages.forEach((stage, index) => {
         
            const idx = processesDataIds.findIndex((item) => {
                return item === stage.process_id;
            });
            let tableRow = {
                id: index, 
                stepID: 'S00011'+index, 
                stepName: processesDataNames[idx] ? processesDataNames[idx] : '-', 
                percent: { placeholder: stage.percentage, value: stage.percentage, selectionOptions: ordersPercentSelectionOptions }, 
                sum: stage.value? stage.value : '-', 
                reminder: stage.days? stage.days : '-'
            }
            paymentTableList.push(tableRow);
        })
       
        setPaymentDetailsTableRows(paymentTableList)
    }
    paymentDetailsTableData.rows = paymentDetailsTableRows;

  
    useEffect(() => {
        getFormData()
    }, [ orderId])


    return (
        <>
            <div className='paymentDetails__main'>
                <Table data={paymentDetailsTableData} tableFor="paymentDetails" view="desktop"/>
                <Grid xs={12} className='paymentDetails__btn__container addOrderFooter'>
                    <Grid xs={6} className='paymentDetails__btn__container__left'>
                        <Button data={step} handleShowPopUp={handleShowStepPopUp} />
                    </Grid>
                    <Grid xs={6} className='paymentDetails__btn__container__right'>
                        <Button data={add} handleShowPopUp={handleShowPopUp} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancel} handleShowPopUp={handleClosePopup} />
                    </Grid>
                </Grid>
            </div>
            {
                showStepPopUp ? <AddStepPopUp showPopUp={showStepPopUp} handleShowPopUp={handleShowStepPopUp} processesDataNames={processesDataNames} processesOrder={processesOrder} processesDataIds={processesDataIds} addProcessOrderStage={addProcessOrderStage} /> : null
            }

        </>
    )
}
