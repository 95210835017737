import { ADD_CHECK_LIST, ADD_SYSTEM, DELETE_SYSTEM, EDIT_SYSTEM, GET_CHECK_LIST_BY_SYSTEM_ID, GET_SYSTEMS_BY_ORDER_ID, GET_SYSTEMS_CHECK_LIST_MOBILE, GET_SYSTEMS_CHECK_LIST_TABLET, GET_SYSTEMS_FOR_ACCORDION, MOVE_SYSTEM_ROW, REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH, SELECTED_SYSTEM_BY_SYSTEM_ID, SET_SYSTEMS_FOR_ACCORDION, SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH } from "../../constants/customer/systemConstants";
import { mobileSystemsCheckList, systemsAccordion, tabletSystemsCheckList } from "../../constants/staticeData/systemsList";
import { get,} from "lodash";
 
const initialState = {
    systemsCheckListMobile: mobileSystemsCheckList,
    systemsCheckListTablet: tabletSystemsCheckList,
    systemsAllData: [],
    originSystemData: [],
    // systemsForAccordion: systemsAccordion,
    systemsForAccordion: [],
    singleSystem: {},
    singleSystemProgress: 0,
    systemsOpeningPath: []
}

// select system to delete and edit
function getSelectedSystemBySystemId(systems, systemId) {
    const selectedSystemSystemObj = {};
    for (let i = 0; i < systems?.length; i++) {
        const _systems = systems[i].systems;
        for (let _system of _systems) {
            if (_system.system_id === systemId) {
                return _system;
            }
        }
    }

    return selectedSystemSystemObj;
}

// delete system part
function deleteSystemFromSelectedLayerSystems(systemsOfLayers, systemId, parentSystemId) {
    const systems = systemsOfLayers?.systems;
    // console.log("systemsOfLayers", systemsOfLayers)
    const systemAfterDeleted = []
    const systemsAfterDelete = systems.map((_systems) =>{
        const __systems = _systems?.systems;
        const filteredSystemBySystemId = __systems.filter((__system) => __system.system_id !== systemId);
        return {
            parent_system_id: _systems?.parent_system_id, 
            systems: filteredSystemBySystemId 
        }
    })
    // for (let i = 0; i < systems?.length; i++) {
    //     const _systems = systems[i]?.systems;
    //     let remainingSystems;
    //     if (parentSystemId === null) {
    //         remainingSystems = _systems.filter((system) => system.system_id !== systemId);
    //         // console.log("reaminin null", systemId, parentSystemId, remainingSystems)
    //         systemAfterDeleted.push({ parent_system_id: parentSystemId, systems: remainingSystems })
    //     }
    //     if (systems[i].parent_system_id === parentSystemId) {
    //         remainingSystems = _systems.filter((system) => system.system_id !== systemId);
    //         // console.log("reaminin", systemId, parentSystemId , remainingSystems)

    //         systemAfterDeleted.push({ parent_system_id: parentSystemId, systems: remainingSystems })
    //     } else {
    //         systemAfterDeleted.push(systems[i])
    //     }
    // }
    const data = { layer: systemsOfLayers?.layer, systems: systemsAfterDelete }
    return data;
}

function newPushNewSystemToSystemsForAccordion(tree, newSystemData) {
    const { parentId, floor, locationDescription, systemName, layer } = newSystemData;
    const systemId = "id" + Math.random().toString(16).slice(2);

    const dataObject = {
        actual_system_id: systemId,
        actual_system_name: "",
        check_list: null,
        layer: layer,
        location_description: locationDescription,
        location_floor: floor,
        order_id: "63ac4b587fd3fa5e2c7b1e37",
        parent_system_id: parentId,
        progress: 22,
        system_id: systemId,
        system_name: systemName,
        template_system_id: "6073df6ee206baa7365fcbbb",
        _id: systemId,
    };
    const newSystemDataToSet = {
        parent_system_id: parentId,
        systems: [dataObject]
    }

    let systemByLayerWhereToAddNewSystem = tree[layer - 1] ? tree[layer - 1]?.systems : [];

    if (!systemByLayerWhereToAddNewSystem.length) {
        systemByLayerWhereToAddNewSystem.push(newSystemDataToSet)
        tree.push({ layer: layer, systems: systemByLayerWhereToAddNewSystem });
    }

    if(parentId){
        for (let i = 0; i < systemByLayerWhereToAddNewSystem.length; i++) {
            const _system = systemByLayerWhereToAddNewSystem[i];

            if (_system.parent_system_id === parentId){
                _system.systems.push(dataObject);
                break;
            }
            console.log(_system)              
        }
    }else{
        systemByLayerWhereToAddNewSystem.push(newSystemDataToSet)
    }
   
    // systemByLayerWhereToAddNewSystem[0].systems.push(dataObject)
   

    // console.log("tree", tree)
    return tree;
}


function editSystemById(data, systemId, payload) {
    for (const _data of data) {
        if (_data.systemId === systemId) {
            _data.items[0].details = payload.systemName;
            _data.items[1].details = payload.locationDescription;
            _data.items[2].details = payload.floor;
            break;
        }
        if (_data.data) {
            editSystemById(_data.data, systemId, payload);
        } else {
            return;
        }
    }
}


function getColorCodeByLayer(layer) {
    let color;
    let background;
    switch (layer) {
        case 1:
            color = "#81B03E";
            background = "#F2F5ED";
            break;
        case 2:
            color = "#3EB0A7";
            background = "#E3F3F2";
            break;
        case 3:
            color = "#BD1657";
            background = "#FCEEF3";
            break;
        case 4:
            color = "#3E54B0";
            background = "#E8EBF4";
            break;
        case 5:
            color = "#C532C4";
            background = "#FDEEFD";
            break;
        default:
            color = "#81B03E";
            background = "#F2F5ED";
            break;
    }
    return {
        color,
        background,
    }
}

// formatting the date to required from for UI 
function formattedDataByLayer(systemItem) {
    const formattedData = systemItem.systems[0]?.systems.map((systemInfo) => {
        const { color, background } = getColorCodeByLayer(systemItem.layer);
        return {
            data: [{
                hasMore: false,
                progress: systemInfo.progress,
                parentId: systemInfo.parent_system_id,
                systemId: systemInfo.system_id,
                checkList: systemInfo.check_list,
                layer: systemItem.layer,
                items: [
                    {
                        name: "System name",
                        details: systemInfo.system_name,
                        nameColor: color,
                        nameBackground: background
                    },
                    {
                        name: "Location description",
                        details: systemInfo.location_description,
                        nameColor: color,
                        nameBackground: background
                    },
                    {
                        name: "Floor",
                        details: systemInfo.location_floor,
                        nameColor: color,
                        nameBackground: background
                    },
                ]
            }]
        }
    })
    return formattedData;
}
function refactoringData(data) {
    return data && data.map((systemItem) => {
        if (systemItem.layer === 1) {
            const layerOneData = formattedDataByLayer(systemItem);
            return layerOneData;
        }
        if (systemItem.layer === 2) {
            const layerTwoData = formattedDataByLayer(systemItem);
            return layerTwoData;
        }
        if (systemItem.layer === 3) {
            const layerThreeData = formattedDataByLayer(systemItem);
            return layerThreeData;
        }
        if (systemItem.layer === 4) {
            const layerFourData = formattedDataByLayer(systemItem);
            return layerFourData;
        }
    })
}

function getChildSystems(systems, parentSystemId) {
    const children = systems.find(i => i.parent_system_id === parentSystemId)
    if (children) return children.systems
    return []
}

function getAllChildren(systemLayers, systemId, layer) {
    let parentSystems = systemLayers[layer - 1].systems[0].systems.filter((system) => system.system_id === systemId);
    let allSystems = parentSystems;
    for (let currentLayer = layer + 1; currentLayer <= systemLayers.length; currentLayer++) {
        const layerSystems = systemLayers.find(systemLayer => systemLayer.layer === currentLayer).systems
        let parentSystemsForNextIteration = [];
        for (let parentSystem of parentSystems) {
            const { _id } = parentSystem;

            const children = getChildSystems(layerSystems, _id)
            allSystems = [...allSystems, ...children]
            parentSystemsForNextIteration = [...parentSystemsForNextIteration, ...children]
        }
        parentSystems = [...parentSystemsForNextIteration]
    }
    return allSystems
}

function calculateCompletedPercentage(systems) {
    const systemsWithCheckLists = systems.filter(i => i?.check_list)
    const systemsWithDoneCheckLists = systemsWithCheckLists.filter(i => i?.check_list?.is_checked)
    const systemsTotal = systemsWithCheckLists.length
    const checkedSystemsTotal = systemsWithDoneCheckLists.length
    const completedStatus = (!checkedSystemsTotal && !systemsTotal) ? 0 : checkedSystemsTotal / systemsTotal
    return completedStatus.toFixed(2)
}

function reorderSystems(event, originalSystemsArray) {

    // console.log("originalSystemsArray for recursion", event, originalSystemsArray)
    const newIndex = event.newIndex + (event.direction === "up" ? (-1) : 1);
    const oldIndex = event.oldIndex;


    for (let i = 0; i < originalSystemsArray.length; i++) {
        // console.log("called=====", originalSystemsArray[i].data[0].layer, event.layer)

        if (originalSystemsArray[i].data[0].layer === event.layer) {

            // console.log("originalSystemsArray[i].data[0]", originalSystemsArray[i].data[0])
            const movedSystem = originalSystemsArray.find((system, index) => index === oldIndex);
            const remainingSystems = originalSystemsArray.filter((system, index) => index !== oldIndex);

            const reorderedItems = [
                ...remainingSystems.slice(0, newIndex),
                movedSystem,
                ...remainingSystems.slice(newIndex)
            ];
            return reorderedItems;
        } else if (originalSystemsArray[i].data[0]?.data) {
            let newLayer = event.layer;
            reorderSystems({ oldIndex: oldIndex, newIndex: newIndex, direction: event.direction, layer: newLayer }, originalSystemsArray[i].data[0].data);
        }
    }

    // const findCurrentRowLayer = originalSystemsArray.find((system, index) => system.data[0].layer === event.layer);
    // console.log("findCurrentRowLayer+++++", findCurrentRowLayer, event.layer)

    // const movedSystem = originalSystemsArray.find((system, index) => index === oldIndex);
    // const remainingSystems = originalSystemsArray.filter((system, index) => index !== oldIndex);

    // const reorderedItems = [
    //     ...remainingSystems.slice(0, newIndex),
    //     movedSystem,
    //     ...remainingSystems.slice(newIndex)
    // ];
    // return reorderedItems;


}

function getAdditionalDetailsToSystem(systemDataRows) {
    for (const systemDataRow of systemDataRows) {
        const {
            layer,
            systems
        } = systemDataRow;

        for (let i = 0; i < systems[0].systems.length; i++) {
            const systemId = systems[0].systems[i].system_id
            const systemsChain = getAllChildren(systemDataRows, systemId, layer);
            const completedStatus = calculateCompletedPercentage(systemsChain);
            systems[0].systems[i].progress = completedStatus * 100;
        }
    }
    return systemDataRows;
}

export default function customerSystemReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SYSTEMS_CHECK_LIST_MOBILE:
            return { ...state, systemsCheckListMobile: state.systemsCheckListMobile };
        case GET_SYSTEMS_CHECK_LIST_TABLET:
            return { ...state, systemsCheckListTablet: state.systemsCheckListTablet };
        case GET_SYSTEMS_BY_ORDER_ID:
            const firstSystemName = action.payload[0]?.systems[0]?.systems[0];
            const { layer, system_name } = firstSystemName;
           const defaultOpenedSystemPath = {
               systemName: system_name,
               layer: layer,
               isActive: true
            }
            return { ...state, systemsAllData: action.payload, originSystemData: action.payload, systemsOpeningPath: [...state.systemsOpeningPath, defaultOpenedSystemPath] };
        case GET_SYSTEMS_FOR_ACCORDION:
            return { ...state, systemsForAccordion: state.systemsForAccordion };
        case SET_SYSTEMS_FOR_ACCORDION:
            // console.log("SET_SYSTEMS_FOR_ACCORDION", state.originSystemData)
            // const systemsData = action.payload && action.payload?.[0]?.systems?.[0]?.systems?.[0];
            const systemsData = state.systemsAllData?.[0]?.systems?.[0]?.systems?.[0];
            if (!systemsData) return state;

           
            // refactoring the API data and extracting all layers Array into required form for UI
            const dataToRefractor = getAdditionalDetailsToSystem(state.originSystemData)
            const refactoredSystemDataFromApi = refactoringData(dataToRefractor);

            // mapping data layers, (if layer1's systemId === layer2's parentId, so that layers two obj will child of layer1's obj)

            // connecting layers 
            const tree = [];
            // const systemsApiData = action.payload;
            const systemsApiData = state.originSystemData;
            const parentIdMap = {};
            for (const system of systemsApiData) {
                const {
                    layer,
                    systems,
                } = system;
                const { color, background } = getColorCodeByLayer(layer)
                for (const _system of systems) {
                    const {
                        parent_system_id,
                        systems: _systems,
                    } = _system;
                  
                    if (parent_system_id == null) {
                        for (const __system of _systems) {
                            parentIdMap[__system.system_id] = `[${tree.length}].data.[0].data`; 
                            tree.push({data: [{
                                hasMore: true,
                                progress: __system.progress,
                                parentId: __system.parent_system_id,
                                systemId: __system.system_id,
                                checkList: __system.check_list,
                                layer,
                                items: [
                                    {
                                        name: "System name",
                                        details: __system.system_name,
                                        nameColor: color,
                                        nameBackground: background
                                    },
                                    {
                                        name: "Location description",
                                        details: __system.location_description,
                                        nameColor: color,
                                        nameBackground: background
                                    },
                                    {
                                        name: "Floor",
                                        details: __system.location_floor,
                                        nameColor: color,
                                        nameBackground: background
                                    },
                                ],
                                data:[]
                            }]
                            });
                        }
                    } else if (parent_system_id in parentIdMap) {
                        const idxInfo = parentIdMap[parent_system_id];
                        // console.log("parent_system_id++++++", parent_system_id)
                        for (const __system of _systems) {
                            parentIdMap[__system.system_id] = `${idxInfo}.[${get(tree, idxInfo).length}].data`; 
                            get(tree, idxInfo).push({
                                hasMore: true,
                                progress: __system.progress,
                                parentId: __system.parent_system_id,
                                systemId: __system.system_id,
                                checkList: __system.check_list,
                                layer,
                                items: [
                                    {
                                        name: "System name",
                                        details: __system.system_name,
                                        nameColor: color,
                                        nameBackground: background
                                    },
                                    {
                                        name: "Location description",
                                        details: __system.location_description,
                                        nameColor: color,
                                        nameBackground: background
                                    },
                                    {
                                        name: "Floor",
                                        details: __system.location_floor,
                                        nameColor: color,
                                        nameBackground: background
                                    },
                                ],
                                data:[]
                            });
                        }
                    }
                }
            }

            return { ...state, systemsForAccordion: tree, systemsAllData: refactoredSystemDataFromApi }

        case ADD_SYSTEM:
            const { parentId, id, floor, locationDescription, systemName } = action.payload;
            // console.log("action.payload", action.payload)

            const newAccordionTree2 = newPushNewSystemToSystemsForAccordion(state.originSystemData, action.payload)
       
            // const newSystemAdded = newPushNewSystemToSystemsForAccordion(state.originSystemData, action.payload)
            // return { ...state, systemsForAccordion: [...state.systemsForAccordion, newSystem] };
            return { ...state, systemsAllData: newAccordionTree2, originSystemData: newAccordionTree2 };

        case SELECTED_SYSTEM_BY_SYSTEM_ID:

            const selectSystemsByLayer = state.originSystemData[action.payload?.layer - 1];
            const selectedSystemBySystemId = getSelectedSystemBySystemId(selectSystemsByLayer?.systems, action.payload?.systemId);
            // console.log("selectSystemByLayer", action.payload, selectSystemByLayer)

            return { ...state, singleSystem: selectedSystemBySystemId }

            // const selectedSystem = state.systemsForAccordion.filter((system) => system.data[0].systemId === action.payload);
            // return { ...state, singleSystem: { ...state.singleSystem, ...selectedSystem[0] } }
        case EDIT_SYSTEM:
            const systemIdToEdit = action.payload.id;
            const currentSystems = [...state.systemsForAccordion];
            for (let i = 0; i < currentSystems.length; i++) {
                editSystemById(currentSystems[i].data, systemIdToEdit, action.payload);
            }
            return { ...state, systemsForAccordion: currentSystems };
        case DELETE_SYSTEM:
            const remainingSystemsAfterDelete = state.systemsForAccordion.filter((system) => system.id !== action.payload);
            return { ...state, systemsForAccordion: remainingSystemsAfterDelete };
        case GET_CHECK_LIST_BY_SYSTEM_ID:

            let filteredSystemByLayer = null;
            const systemsByLayer = state?.originSystemData[action.payload?.layer - 1]
            for (let _system of systemsByLayer.systems){
                for (let __system of _system.systems){
                    if (action.payload.systemId === __system.system_id) {
                        filteredSystemByLayer = __system.check_list
                    } 
                }
            }
            
            //update exiting hard cord checklist row with Api data

            // const checkListRows = filteredSystemByLayer.checkList && filteredSystemByLayer.checkList.data.map((row) => {
            //     return {
            //         id: row._id,
            //         description: row.row_title,
            //         normalAbnormal: { label: '', placeholder: 'Jakob Baptista', width: '100px', height: '30px', border: 'none' },
            //         causeOfAbnormality: '_',
            //         requiredAction: '_',
            //         fixedOnTheSpot: { name: '', with: '40px', border: 'none', isBoxShadow: false, background: 'transparent' },
            //         comments: '_',
            //         progress: '70%',
            //         photo: 'Upload'
            //     }
            // })

            // const checkListRows = filteredSystemByLayer.checkList && filteredSystemByLayer.checkList.data.map((row) => {
            //     return row.values?.map((col) => {
            //         return col;
            //     })
            // })
            const checkListRows = filteredSystemByLayer && filteredSystemByLayer.data?.map((row) => {
                return row;
                // return row.values?.map((col) => {
                //     return col;
                // })
            })

            return { ...state, systemsCheckListTablet: { ...state.systemsCheckListTablet, rows: checkListRows, actualSystemId: action.payload.systemId } }

        case MOVE_SYSTEM_ROW:
        // const reordredAccordionData = reorderSystems({ oldIndex: action.payload.index, newIndex: action.payload.index, direction: action.payload.direction, layer: action.payload.layer }, state.systemsForAccordion);
        // return { ...state, systemsForAccordion: reordredAccordionData };
        case ADD_CHECK_LIST:
            // let newCheckList = {
            //     id: Date.now(),
            //     description: 'description 1',
            //     normalAbnormal: { label: '', placeholder: 'Jakob Baptista', width: '100px', height: '30px', border: 'none' },
            //     causeOfAbnormality: '_',
            //     requiredAction: '_',
            //     fixedOnTheSpot: { name: '', with: '40px', border: 'none', isBoxShadow: false, background: 'transparent' },
            //     comments: '_',
            //     progress: '70%',
            //     photo: 'Upload'
            // }
            let newCheckList = [
                {
                    typeOfInput: null,
                    value: "תקין"
                },
                {
                    options: [],
                    typeOfInput: -11,
                    value: "תקין"
                },
                {
                    typeOfInput: null,
                    value: "",
                },
                {
                    typeOfInput: null,
                    value: false,
                },
                {
                    placeholder: "יש לציין זרם מדוד",
                    typeOfInput: "null",
                    value: ""
                },
                {
                    typeOfInput: "null",
                    value: []
                }
            ]
            let newRows = [];
            if (state.systemsCheckListTablet.rows) {
                newRows = [...state.systemsCheckListTablet.rows, newCheckList]
            } else {
                newRows = [newCheckList];
            }
            return { ...state, systemsCheckListTablet: { ...state.systemsCheckListTablet, rows: newRows } }

        case SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH: 
            // let { system_name , layer} = action.payload;
            const newOpenedSystemPath = {
                systemName: action.payload.system_name || action.payload.systemName,
                layer: action.payload.layer,
                isActive: true
            }
            // other systems should be active false
            const previousOpenedSystemsPath = state.systemsOpeningPath.map((system) => ({ ...system, isActive: false}))
            return { ...state, systemsOpeningPath: [...previousOpenedSystemsPath, newOpenedSystemPath] }
        case REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH:
            let resetPath = state.systemsOpeningPath.filter((system) => system.layer <=action.payload.layer);
            // after the removed system, last system from the remaining should be active true
            resetPath[0].isActive = true 
            return { ...state, systemsOpeningPath: resetPath }
        default:
            return state;

    }
}