import React, { Component } from 'react'
import moment from 'moment'
import { Droppable,Draggable } from 'react-beautiful-dnd';
//functions
import {hexToRgba} from '../../../../functions/general/general'
//images
import Lock from '../../../../images/updatepopup/openLock.svg'
import DragIcon from '../../../../images/general/drag_icon.svg'
import Warning from '../../../../images/general/problem.svg'
import Arrow from '../../../../images/general/white-arrow.svg'
//icons
import { SvgIcon } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import {sortProcessesWeeklyView} from '../../../../functions/data/sortProcesses'
import {createOrderProcessBackground} from '../../../../functions/helpers/createOrderProcessBackground'
import { InformationPopup } from '../../../updatePopups/InformationPopup';
import { getPosition, onScroll } from '../../../../hooks/helper';
import caretLeft from '../../../../images/general/caret-left.svg'
import caretRight from '../../../../images/general/caret-right.svg'
// import { styled } from '@material-ui/core';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import Fade from '@mui/material/Fade';
// import { HtmlTooltipStyled } from '../../../../hooks/helper';
let uniqid = require("uniqid");


// const HtmlTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
//   ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: HtmlTooltipStyled,
// }));

class WeeklyCalendar extends Component {

    constructor() {
        super()

        this.state = {
            holidays: []
        }
    }

    shouldComponentUpdate(_np, _ns){
        let _p = this.props; let _s = this.state;
        return (
                _np.currentSelectedDate !== _p.currentSelectedDate 
            ||  _np.weeklyOrders !== _p.weeklyOrders
            || _np.daysArray !== _p.daysArray
            ||  _ns !== _s
        ) ? true : false;         
    }

    openUpdateMenu = (process) => {
        this.props.openUpdateMenu(process)
    }

    componentDidMount() {
        const {daysArray} = this.props
        let holidays = []
        daysArray.map(item=>{
            if(item.holidays.length > 0  && item.holidays[0].status !== "Half Day" || item.offDay){
                let holidayDayIndex = moment(item.date).day()
                holidays.push(holidayDayIndex)
            }
        })

        this.setState({holidays})
    }

    componentDidUpdate(prevProps,prevState){
         if(prevProps.daysArray[0].date !== this.props.daysArray[0].date){
            let holidays = []
            this.props.daysArray.map(item=>{
            if(item.holidays.length > 0  && item.holidays[0].status !== "Half Day" || item.offDay){
                let holidayDayIndex = moment(item.date).day()
                holidays.push(holidayDayIndex)
            }
        }) 

        this.setState({holidays})

         }
    }
    
    checkIfHolidayOrDayOff = (index) =>{
        const {holidays} = this.state
        
        if(this.props.offDays.includes(index)){
            return 'day-off'
        }
        
        for(let i = 0; i< holidays.length; i++){
            if(holidays[i] === index)
            return 'holiday'
        }


        return false
    }

    backToToday = () => {
        this.props.backToToday()
    }
    
    setDayColor = (day) => {
        const {weeklyOrders} = this.props
        const warning = weeklyOrders.reduce((result, order) => {
            const {processes} = order
            processes.forEach(processGroup => {
                const warning = processGroup.find(process => process.warnings && moment(process.process_date).isSame(day.date, 'day'))
                if(warning) result = true
            })
            return result
        },false)

        return warning ? 'red' : '#000000'
    }

    checkWorkloadExceeded = (workload) => {
        const PERCENT_100 = 100
        return workload > PERCENT_100
    }

    previous_info_popup_id = '';
    initHandlerForInfoPopup = (event,popup_id) => {
      if(this.previous_info_popup_id){
          let tag = this.getElementStyle(this.previous_info_popup_id);
          tag && (tag.display = 'none');;
      }
      if(popup_id){
        let tag = this.getElementStyle(popup_id);
        tag && (tag.display = 'block');
      }
      if(popup_id){
        let tag = document.getElementById(popup_id);
        tag && (tag.style.marginTop = this.setInfoTopView(event));
        }
      this.previous_info_popup_id = popup_id;
    };

    setInfoTopView = (event) => {
        let xPoint= event.clientX;
        let yPoint= event.clientY;
        let innerHeight = window.innerHeight;
        let height = innerHeight - yPoint;
        return (height < 160) ? '-48px' : '34px';
    }
    
    closeHandlerInfoPopup = (event, popup_id) => {
        let is_hover_on_info = false;
        let popup = document.getElementById(popup_id)?.addEventListener('mouseenter', (e) => {
            is_hover_on_info = true;
        },false);
        setTimeout(() => {
            if(!is_hover_on_info){
                this.getElementStyle(popup_id).display = 'none';
            }
        },100)
    };

    getElementStyle = (_id) =>  document.getElementById(_id)?.style;

    checkIndexAndSetWidth = (data, index) => {
        // const { process_width = 2 } = data || {};
        const processDuration = moment(data.process_endDate).diff(data.process_date, 'days')
        let processWidth =  Math.min(processDuration - index + 1, 5);
        if (processWidth <= 0) {
            processWidth = 1;
        }
        return data.process_duration ? `calc(((100% + 0.3px) * ${processWidth}) - 15px)` : `calc(((100% - 8px)`
    }

    rightAlignment = (data, index) => {
        const processDuration = moment(data.process_endDate).diff(data.process_date, 'days')
        const processWidth =  Math.min(processDuration - index + 1, 5);
        if (processWidth <= 1) {
            return '0px'
        }
        return data.process_duration ? `calc(((100% + 0.3px) * ${(processWidth-1)/2}))` : `calc(((50% - 4px)))`
    }

    render() {
        let info_popup_position = {
            initial: -1,
            last: 97,
        }
        const {daysArray, currentSelectedDate, weeksDateChange, weeklyOrders, daysOffNumber, disableDrag, getMoreDataOnScroll} = this.props
        const {holidays} = this.state
        let selectedFirstDayInWeek = moment(currentSelectedDate).startOf('week')
        let todayFirstDayInWeek = moment().startOf('week');
        let blockDecrementation = false;

        return (
            <div className="week-view-page__calendar">
                <header className="week-view-page__calendar__header">   
                    <button className="btn--back--today" onClick={()=>{this.backToToday()}} >
                        <p>חזור להיום</p>
                    </button>
                    <figure className='weekly-arrow-right' style={blockDecrementation ? {pointerEvents:'none'}:{}} onClick={()=>{weeksDateChange('dec')}}><img src={caretRight} alt=""/></figure>
                    <h3>
                        <span>שבוע {daysArray && daysArray.length > 0 ? moment(daysArray[0].date).format('w'):''} &nbsp;</span>
                        <span>( {daysArray && daysArray.length > 0 ? daysArray[0].parsedDate:''} - {daysArray && daysArray.length > 0 ?  daysArray[daysArray.length - 1].parsedDate:''} )</span>
                    </h3>
                    <figure className='weekly-arrow-left' onClick={()=>{weeksDateChange('inc')}}><img src={caretLeft} alt=""/></figure>
                </header>

                <section className="week-view-page__calendar__dates">
                    <div className="week-view-page__calendar__dates--sections" style={{width: `calc( (100% / ${ 7 - daysOffNumber}) - 0.33334% )`}}>
                        <span>תאריכים</span>
                        {/* <span>חגים / חופשים</span> */}
                    </div>
                    <div className="week-view-page__calendar__dates--content">
                        {daysArray && daysArray.length > 0 ? 
                            daysArray.map(item=>{
                                if(!item.offDay)
                               return <div key={uniqid()} className="week-view-page__calendar__dates--content__day" style={{
                                   width: `calc( 100% / ${ 7 - daysOffNumber })`
                                   }}>
                                          <section style = {{color: this.setDayColor(item)}}> 
                                              {item.dateName} - {item.parsedDate}
                                          </section>
                                          <section>
                                                {item.offDay ? 
                                                    <span>סופ״ש</span>
                                                    :
                                                    null
                                                }

                                                {item.holidays.length > 0 ? 
                                                   <span><p>{item.holidays[0].name}</p></span>
                                                   :
                                                   null
                                                }
                                                
                                          </section>
                                      </div>
                            })
                            :
                            null
                        }
                    </div>
                </section>

                <section className="week-view-page__calendar__data" onScroll={getMoreDataOnScroll}>
                    { weeklyOrders.length > 0 ? weeklyOrders.map((item)=>{
                        return <div className="week-view-page__calendar__data__row" key={item._id}>
                                  <div className="week-view-page__calendar__data__row--name" style={{width: `calc( 100% / ${ 8 - daysOffNumber})`}}>
                                      <span>
                                           <p style={this.checkWorkloadExceeded(item.workload_percentage) ? {color:'#ff0000'} : {}}>{item.process_name}</p>
                                           <p style={this.checkWorkloadExceeded(item.workload_percentage) ? {color: '#ff0000'} : {}}>{item.workload_percentage ? `% ${item.workload_percentage}` : ''}</p>
                                      </span>
                                  </div>
                                  <div className="week-view-page__calendar__data__row--processes">
                                        {item.processes.map((subItem, index)=>{
                                            if(this.checkIfHolidayOrDayOff(index) === 'holiday'){
                                                return  <div key={item.process_name + index}  className="week-view-page__calendar__data__row--processes__droppable" style={{ width:`calc( 100% / ${ 7 - daysOffNumber})`}}/>
                                            } else { 
                                            return  <Droppable droppableId={item.process_name + index} key={item.process_name + index}>
                                                        {(provided)=>(
                                                            <div  className="week-view-page__calendar__data__row--processes__droppable" ref={provided.innerRef} {...provided.droppableProps} style={{ width:`calc( 100% / ${ 7 - daysOffNumber })` , ...provided.droppableProps.style}}>
                                                                {sortProcessesWeeklyView(subItem).map((process, subIndex)=>{
                                                                   let width = process.finished === '0' ? 0: (process.finished / process.quantity)*100 + '%'
                                                                   
                                                                   let resourcesNameString = process.resource.map(subitem=>subitem.name + ' / ').join(' ').replace(/,/gi, '')
                                                                   resourcesNameString = resourcesNameString.substr(0, resourcesNameString.length - 2)
                                                                   if(!process.backlog)
                                                                   return <Draggable draggableId={process._id} index={subIndex} key={process._id} isDragDisabled={disableDrag || process.done }>
                                                                               {(provided)=>(
                                                                                <>
                                                                                   <div 
                                                                                   onClick={()=>{this.openUpdateMenu(process)}} 
                                                                                   className="week-view-page__calendar__data__row--processes__draggable" 
                                                                                   {...provided.draggableProps} 
                                                                                   {...provided.dragHandleProps} 
                                                                                   ref={provided.innerRef}
                                                                                   style={{
                                                                                    //    zIndex: true ? 5-index + 99 : '0',
                                                                                    zIndex: 99,
                                                                                    width: this.checkIndexAndSetWidth(process, index),
                                                                                    // position: 'relative',
                                                                                    right: this.rightAlignment(process, index),
                                                                                        // marginTop: 100 * index + 'px',
                                                                                       ...provided.draggableProps.style,
                                                                                       fontSize: '7px'
                                                                                   }}
                                                                                   >
                                                                                    
                                                                                    {/* <p>{moment(process.process_endDate).diff(process.process_date, 'days')}</p>
                                                                                    <p>c: {JSON.stringify({
                                                                                        process_id: process.process_id,
                                                                                    })}</p> */}


                                                                                       <aside className="week-view-page__calendar__data__row--processes__draggable__progress-bar" style={{background:createOrderProcessBackground(process)}}>
                                            
                                                                                       </aside>

                                                                                       <section className="week-view-page__calendar__data__row--processes__draggable--section1" onMouseEnter={(event) => this.initHandlerForInfoPopup(event, process._id)} onMouseLeave={(event) => this.closeHandlerInfoPopup(event,process._id)}>
                                                                                            {" "}
                                                                                            <img src={DragIcon} alt="draggable"/>
                                                                                        </section>
                                                                                       <section className="week-view-page__calendar__data__row--processes__draggable--section2" style={process.done ? {color:'#d6d6d6'}:{}}>
                                                                                            <p className = 'number_and_remark_wrapper'><span>{process.order_number}</span><span className = 'remark_text four_characters_length'>{process.remark}</span> </p>
                                                                                            <p>{process.client_name}</p>
                                                                                            <p>{resourcesNameString}</p>
                                                                                       </section>
                                                                                       <section className="week-view-page__calendar__data__row--processes__draggable--section3">
                                                                                            {process.is_detached ?
                                                                                                <div>
                                                                                                   <img src={Lock} alt="menu"/>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {process.warnings ? <aside></aside>:<div style={{backgroundColor:'transparent'}}></div>}

                                                                                            <span style={process.done ? {color:'#d6d6d6'} : {color:hexToRgba(process.color,'1')}}>
                                                                                                {process.finished + '/' + process.quantity}
                                                                                            </span>
                                                                                       </section>
                                                                                   </div>
                                                                                   <div style={{display: 'none', transform: `translateX(${getPosition(info_popup_position, process.process_date)} - 5%)`, zIndex:100000}} className="info--popup" id={process._id}>
                                                                                                {/* <InformationPopup process={process} popupId={process._id} /> */}
                                                                                    </div>
                                                                                </>
                                                                               )}
                                                                          </Draggable>
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>}
                                            })
                                        }
                                  </div>
                               </div>
                    })
                     : 
                     [1].map((item,index)=>{
                        return <div key={item} className='week-view-page__calendar__data__row'>
                            <div className="week-view-page__calendar__data__row--name" style={{width: `calc(16.59%)`}}>
                                <span></span>
                            </div>
                            {
                                [0,1,2,3,4].map(_i => {
                                    return <Droppable droppableId={`${_i}default`} key={`090${_i}012`}>
                                        {(provided)=>(
                                            <div ref={provided.innerRef} {...provided.droppableProps} style={{ 
                                            height: '100px',
                                            width: '100%',
                                            display: 'flex',
                                            borderBottom: '1px solid darkgray', ...provided.droppableProps.style}}>
                                            </div>
                                        )}
                                    </Droppable>
                                })
                            }
                        </div>
                        })
                    }
                </section>
            </div>
        )
    }
}

export default WeeklyCalendar