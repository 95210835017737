import { Grid } from '@material-ui/core'
import React, { useState } from 'react';
import "./MeasurementInput.scss";

export default function MeasurementInput(props) { 
    const {view, data} = props;
    const { label, placeholder, value, type, width } = data;
    const [inputValue, setInputValue] = useState('')

    const handleChange = (e) => {
        setInputValue(e.target.value)
    }
    return (
        <Grid className={`measurementInputContainer ${type === 'number' ? 'input--removeArrows' : ''}  ${view === 'tablet' && 'measurementInputContainer--tablet'}`}>
            {
                label && <label style={{ fontSize: view === 'tablet' && '24px' }}>{label}</label>
            } 
            <input placeholder={placeholder} type={type} value={inputValue} style={{ width: width ? width : '100%', fontSize: view==='tablet' && '24px' }} onChange={handleChange} />
        </Grid>
    )
}
