import axios from "axios";
import { API } from "../../tools/keys/keys";
import { getCookie } from "../../tools/cookies/cookie";
import moment from "moment";
import { polyfill } from "es6-promise";
import {
  CACHED_EDIT_GENERAL_SECTION,
  DELAYED_CHECK_LIST_DATA,
  CURRENT_ORDER_ID,
  CACHED_CHECK_LISTS_DATA,
  ORDER_LIST,
  DEPARTMENTS,
  UNION_CHECK_LIST_DATA,
  TEST_FLOOR_CHECK_LIST_DATA
} from '../../constants/offline-mode'

polyfill();

// const token_from_cookie = getCookie("login_cookie")

// console.log(token_from_cookie)

export const getOrders = (
  view,
  limit,
  page,
  sort,
  department_id,
  employee_id,
  started,
  date = "",
  user_id
) =>
  new Promise((resolve) => {
    if (!date) date = " ";

    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    let url = `${API}/system/order/get?view=${view}&limit=${limit}&page=${page}&sort=${sort}&to=${date}`;
    if (department_id) url = url + `&department_id=${department_id}`;
    if (employee_id) url = url + `&order_employee_id=${employee_id}`;
    if (started) url = url + `&started=false`;
    if (user_id) url = url + `&user_id=${user_id}`;

    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output.result);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
  });

export const getOrdersCount = () =>
  new Promise((resolve) => {
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    axios
      .get(`${API}/system/order/count`, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output.result);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
  });

export const upateOrders = (body, spread = false) =>
  new Promise((resolve) => {
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    let url = `${API}/system/order/reposition?`;
    if (spread) {
      url = url + `spread=true`;
    }

    axios
      .post(url, body, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output);
        } else {
          resolve(output);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
  });

export const repositionForConst = (body) =>
  new Promise((resolve) => {
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    axios
      .post(`${API}/system/order/reposition-constant`, body, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output);
        } else {
          resolve(output);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
  });

// montly/weekly api calls

export const appGetOrders = async (
  from,
  to,
  view,
  order_number,
  department_id,
  employee_id,
  started,
  user_id,
  backlog = true,
  limit = 100,
  page = 0,
  sort = -1,
  order_id = '',
  current_process,
  zipped=false,
  // user_id,
) => {

  from = moment(from).startOf("day").toDate();
  to = moment(to).startOf("day").toDate();

  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/get?view=${view}&limit=${limit}&page=${page}&sort=${sort}&from=${from}&to=${to}`;
  if (zipped) {
    url += "&zipped=true"
  }
  if (order_number) url = url + `&order_number=${order_number}`;
  if (department_id) url = url + `&department_id=${department_id}`;
  if (employee_id) url = url + `&order_employee_id=${employee_id}`;
  if (backlog) url = url + `&backlog=true`;
  if (started) url = url + `&started=false`;
  if (user_id) url = url + `&user_id=${user_id}`;
  if (order_id) url = url + `&order_id=${order_id}`;
  if(current_process) url = url + `&current_process=${current_process}`;
  
  try {
    let res = await axios({
      method: "get",
      url: url,
      headers,
    });

    if (zipped) {
      res = res.json();
    }

    const { result, ok } = res.data;
    if (ok) {
      //  localStorage.setItem(ORDER_LIST,JSON.stringify(result))
      return result;
    }
  } catch (err) {
    console.log("Error has been occured in GET request", err);
     return JSON.parse(localStorage.getItem(ORDER_LIST))
    if (err) return undefined;
  }
};

export const montlyUpdateOrders = async (
  body,
  spread,
  department_id = false
) => {
  //set hour to 16 to prevent problems of GMT
  body.date = new Date(body.date.setHours(16));

  if (spread && body.process.original) body._id = body.process.original;

  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/reposition?`;

  if (department_id) url = url + `&department_id=${department_id}`;

  if (spread) url = url + `&spread=true`;

  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const montlyUpdateOrdersForConst = async (
  body,
  department_id = false
) => {
  //set hour to 16 to prevent problems of GMT
  body.date = new Date(body.date.setHours(16));
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/reposition-constant?`;

  if (department_id) url = url + `&department_id=${department_id}`;

  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

// get warnings
export const getWarnings = async (body, spread = false) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/reposition-warnings`;

  if (spread) url = url + `?spread=true`;

  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

//

export const updateProcess = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/update`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

//

export const replaceOrderEmployee = (body) =>
  new Promise((resolve) => {
    console.log("replace Order Employee");
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    axios
      .post(`${API}/system/order/replace-employee`, body, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          //   console.log(output.result)
          resolve(output.result);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.log("Error has been occured in POST request", err);
        resolve({ ok: false });
      });
  });

// popup report done

export const reportDone = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/done`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

// get departments

export const getDepartments = async () => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/departments/get`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    localStorage.setItem(DEPARTMENTS , JSON.stringify(res.data))
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    return JSON.parse(localStorage.getItem(DEPARTMENTS))
    if (err) return undefined;
  }
};

//get notes

export const getNotesForProcess = async (id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/get-note?order_process_id=${id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return {ok:false};
  }
};

//get bids
export const getBids = async (page, limit, order, sort) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order/pagination?page=${page}&limit=${limit}&order=${order}&sort=${sort}&type=0`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const getIsRecipientReasone = async () => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order/get-is-recipient-reasone`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const getEndDateForProcess = async (id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/get-end-date?_id=${id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const getStartAndEndDatesForProcess = async (id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/get-process-start-and-end-dates?_id=${id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return {ok:false};
  }
};

export const setEndDateForProcess = async (body, spread) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/set-end-date?spread=${spread}`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const getSearchAutoComplete = (page, keyword, limit) =>
  new Promise((resolve) => {
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };

    let url = `${API}/system/order/search-auto-complete?page=${page}&keyword=${keyword}&limit=${limit}`;

    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output);
        } else {
          console.log("error");
          resolve(false);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
  });

export const getProcessById = async (id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/get-by-id?_id=${id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const getResources = async (id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-process/get-resources?_id=${id}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return {ok:false};
  }
};

export const getWarningsDashboard = (
  page,
  limit,
  order_number,
  department_id,
  employee_id
) =>
  new Promise((resolve) => {
    const token_from_cookie = getCookie("login_cookie");

    const headers = {
      "access-token": token_from_cookie,
    };
    let url = `${API}/system/dashboard/get-warnings?page=${page}&limit=${limit}`;
    if (order_number) url = url + `&order_number=${order_number}`;
    if (department_id) url = url + `&department_id=${department_id}`;
    if (employee_id) url = url + `&order_employee_id=${employee_id}`;

    axios
      .get(url, { headers })
      .then((res) => {
        const { ok, result } = res.data;
        const output = {
          ok,
          result,
        };
        if (ok) {
          resolve(output);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        resolve({ ok: false });
      });
  });

export const getReport = async () => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/admin/get_financial-report`;
  try {
    const res = await axios.get(url, {
      headers,
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
      timeout: 1000000000,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const isExistReport = async () => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/admin/financial-report-is-exist`;
  try {
    const res = await axios.get(url, {
      headers,
    });
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const generateReport = async (from, to) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/admin/financial-report?from=${from}&to=${to}`;
  try {
    const res = await axios.get(url, {
      headers,
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
      timeout: 1000000000,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};



export const getOrderSystems = async (id) =>  new Promise(resolve => {
  const token_from_cookie = getCookie("login_cookie")


  const headers = {
      'access-token': token_from_cookie
      }

  axios.get(`${API}/system/order/get-document?_id=${id}`, {headers} ).then(res => {
      const {
          ok,
          result
      } = res.data
      const output = {
          ok,
          result
      }

      if(ok){
          resolve(output)
      }else{
          resolve(false)
      }
  }).catch(err => {
      resolve({ ok: false })
  })
})


export const getOrderSystemsList = async (id) =>  new Promise(resolve => {
  const token_from_cookie = getCookie("login_cookie")


  const headers = {
      'access-token': token_from_cookie
      }
      
  axios.get(`${API}/system/order-systems/get-order-systems-and-check-lists?order_id=${id}`, {headers} ).then(res => {
      const {
          ok,
          result
      } = res.data
      const output = {
          ok,
          result
      }

      if(ok){
        const unsentCheckLists = localStorage.getItem(DELAYED_CHECK_LIST_DATA)
        if(unsentCheckLists){
          resolve(unsentCheckListsChecker(id, output))
        }else{
          cacheResponce(id, output)
          resolve(output)
        }
      }else{
          resolve(false)
      }
  }).catch(err => {
      const responce = responceOnError(id)
      resolve(responce)
      // resolve({ ok: false })
  })
})

// export const getSystemCheckList = async (actual_system_id, system_id, order_id ,customer_id) =>  new Promise(resolve => {
//   const token_from_cookie = getCookie("login_cookie")


//   const headers = {
//       'access-token': token_from_cookie
//       }

//   axios.get(`${API}/system/order-systems/get-system-check-list-section?order_id=${order_id}&customer_id=${customer_id}&actual_system_id=${actual_system_id}&system_id=${system_id}`, {headers}).then(res => {
//       const {
//           ok,
//           result
//       } = res.data
//       const output = {
//           ok,
//           result
//       }

//       if(ok){
//           resolve(output)
//       }else{
//           resolve(false)
//       }
//   }).catch(err => {
//       resolve({ ok: false })



export const uploadPhoto = async(body) => new Promise(resolve => {
  const token_from_cookie = getCookie("login_cookie")
  // axios(`${API}/system/order-systems/upload-file`, {headers} )

  const headers = {
      'access-token': token_from_cookie
      }

      const url = `${API}/system/order-systems/upload-file`

      const res = axios({
        method: "post",
        url,
        headers,
        data:body,
      }).then(res => {
      const {
          ok,
          result
      } = res.data
      const output = {
          ok,
          result
      }

      if(ok){
          resolve(output)
      }else{
          resolve(false)
      }
  }).catch(err => {
      resolve({ ok: false })
  })
})


export const updateGeneralSection = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-systems/update-general-section`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    updateIfIdMatch(body)
    return res.data;
  } catch (err) {
    return updateGeneralSectionFailedProcess(body)
  }
};

const updateIfIdMatch = (body) => {
  let data = new Map(JSON.parse(localStorage.getItem(CACHED_EDIT_GENERAL_SECTION)))
  if(data.has(body.actual_system_id)){
    updateGeneralSectionFailedProcess(body)
  }
}

const updateGeneralSectionFailedProcess = (body) => {
  const body_map = new Map();
  const storedData = JSON.parse(localStorage.getItem(CACHED_EDIT_GENERAL_SECTION));
  const new_body = {
    actual_system_id: body.actual_system_id,
    data: body.data,
    _id: body._id
  }
  if(!storedData){
    body_map.set(new_body.actual_system_id , new_body)
    const data = JSON.stringify([...body_map])
    localStorage.setItem(CACHED_EDIT_GENERAL_SECTION,data)
  }else{
    const data = new Map(storedData);
    data.set(new_body.actual_system_id, new_body)
    const result = JSON.stringify([...data])
    localStorage.setItem(CACHED_EDIT_GENERAL_SECTION,result)
  }
  return {
    is_checked: 'pending',
    ...JSON.parse(localStorage.getItem(CACHED_CHECK_LISTS_DATA))
    }
}

// ------check list section mobile ----------------------------------
export const updateActualSystemCheckListSectionMobile = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-systems/update-check-list`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data
  } catch (err) {
    return processFailedUpdateActualSystemRequests(body)
    // processFailedUpdateActualSystemRequests(body)
    // console.log("Error has been occured in POST request", err);
    // if (err) return undefined;
  }
};


export const updateSubSystemCheckListSectionMobile = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-systems/update-sub-system-check-list-section-mobile`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data
  } catch (err) {
    return processFailedUpdateActualSystemRequests(body)
    // console.log("Error has been occured in POST request", err);
    // if (err) return undefined;
  }
};

export const processFailedUpdateActualSystemRequests = (body) => {
  const storedData = localStorage.getItem(DELAYED_CHECK_LIST_DATA)
  // const {_id} = body
  // find in local storage all check lists data
  // body.isPending = true
  // for yellow color that is waiting to be send
  // find by _id in systems list and set body instead
  // save into the local storage

  if(!storedData){
    const data = JSON.stringify([body])
    localStorage.setItem(DELAYED_CHECK_LIST_DATA,data)
  }else{
    const data = JSON.parse(storedData)
    const result = JSON.stringify([...data, body])
    localStorage.setItem(DELAYED_CHECK_LIST_DATA,result)
  }
  body.is_checked = 'pending'
  return {ok:true, result:body}

  // return updated data form local storage

}

const unsentCheckListsChecker = (id, data) =>{
  const currentOrderId = localStorage.getItem(CURRENT_ORDER_ID)
  if(id === currentOrderId){
    return JSON.parse(localStorage.getItem(CACHED_CHECK_LISTS_DATA))
  }
  return data

}

const cacheResponce = (id, output) => {
  localStorage.setItem(CACHED_CHECK_LISTS_DATA,JSON.stringify(output))
  localStorage.setItem(CURRENT_ORDER_ID,id)
}

const responceOnError = (id) => {
  const currentId = localStorage.getItem(CURRENT_ORDER_ID)
  if(currentId === id) return JSON.parse(localStorage.getItem(CACHED_CHECK_LISTS_DATA))
  return {ok:false}
  
}

export const getSubSystemsListForSystems = (actualSystemId, orderId) => new Promise(resolve => {
  const token_from_cookie = getCookie("login_cookie")


  const headers = {
      'access-token': token_from_cookie
      }

  axios.get(`${API}/system/order-systems/get-sub-systems-for-order-actual-system?actual_system_id=${actualSystemId}&order_id=${orderId}`, {headers}).then(res => {
      const {
          ok,
          result
      } = res.data
      const output = {
          ok,
          result
      }

      if(ok){
          resolve(output)
      }else{
          resolve(false)
      }
  }).catch(err => {
      resolve({ ok: false })
  })
})

export const uploadSignature = async(body, orderId, width, height) => new Promise(resolve => {
  const token_from_cookie = getCookie("login_cookie")
  
  const headers = {
    'access-token': token_from_cookie
    }
  
      const url = `${API}/system/order/add-signature?order_id=${orderId}&width=${width}&height=${height}`

      const res = axios({
        method: "post",
        url,
        headers,
        data:body,
      }).then(res => {
      const {
          ok,
          result
      } = res.data
      const output = {
          ok,
          result
      }

      if(ok){
          resolve(output)
      }else{
          resolve(false)
      }
  }).catch(err => {
      resolve({ ok: false })
  })
})


export const changeSystemsOrder = async (body) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-systems/change-systems-order`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    return res.data;
  } catch (err) {
    console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
};

export const getOrderById = async (
  orderId,
  view,
  from,
  to,
) => {

  from = moment(from).startOf("day").toDate();
  to = moment(to).startOf("day").toDate();

  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/get-order-after-split?order_id=${orderId}&view=${view}&backlog=${true}`;
  try {
    let res = await axios({
      method: "get",
      url: url,
      headers,
    });

    const { result, ok } = res.data;
    if (ok) {
       localStorage.setItem(ORDER_LIST,JSON.stringify(result))
      return result;
    }
  } catch (err) {
    console.log("Error has been occured in GET request", err);
     return JSON.parse(localStorage.getItem(ORDER_LIST))
    if (err) return undefined;
  }
};

export const getOrderByIdAgain = async (
  orderId,
  view,
  from,
  to,
) => {

  from = moment(from).startOf("day").toDate();
  to = moment(to).startOf("day").toDate();

  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/get-order-after-split?order_id=${orderId}&view=${view}&again=${`again`}&backlog=${true}`;
  try {
    let res = await axios({
      method: "get",
      url: url,
      headers,
    });

    const { result, ok } = res.data;
    if (ok) {
       localStorage.setItem(ORDER_LIST,JSON.stringify(result))
      return result;
    }
  } catch (err) {
    console.log("Error has been occured in GET request", err);
     return JSON.parse(localStorage.getItem(ORDER_LIST))
    if (err) return undefined;
  }
};



export const updateUnionChecklist = async (body,order_id, actual_system_id, row_title) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order-systems/update-union-check-list`;
  try {
    const res = await axios({
      method: "post",
      url,
      headers,
      data: body,
    });
    if(order_id && actual_system_id && row_title){
      updateFailedUnionCheckList(undefined,order_id, actual_system_id, row_title,body);
    }
    return res.data
  } catch (err) {
    if(order_id && actual_system_id && row_title){
      updateFailedUnionCheckList(undefined,order_id, actual_system_id, row_title,body);
    }
    // processFailedUpdateActualSystemRequests(body)
    // console.log("Error has been occured in POST request", err);
    if (err) return undefined;
  }
  
};

export const updateFailedUnionCheckList = (response=undefined, order_id, actual_system_id, row_title,body=undefined) => {
  const storedData = JSON.parse(localStorage.getItem(UNION_CHECK_LIST_DATA)) || {};
  let union_data_key = `${order_id}${actual_system_id}${row_title}`
  let union_data = {...storedData};
  if(response){
    union_data = {...union_data,[union_data_key]: response};
  }
  if(body){
    union_data[union_data_key].data = JSON.parse(JSON.stringify(body));
    union_data[union_data_key].delayed = true
  }
  const data = JSON.stringify(union_data)
  localStorage.setItem(UNION_CHECK_LIST_DATA,data)
}

export const updateOfflineTestFloorCheckList = (response=null, test_per_floor_id, _id, order_id, actual_system_id,body=null,delayed) => {
  const storedData = JSON.parse(localStorage.getItem(TEST_FLOOR_CHECK_LIST_DATA)) || {};
  let union_data_key = `${test_per_floor_id}${_id}${order_id}${actual_system_id}`
  let union_data = {...storedData};
  if(response){
    union_data = {...union_data,[union_data_key]: response};
  }
  if(body){
    union_data[union_data_key].data = JSON.parse(JSON.stringify(body.data))
    delayed && (union_data[union_data_key].delayed = delayed)
  }
  const data = JSON.stringify(union_data)
  localStorage.setItem(TEST_FLOOR_CHECK_LIST_DATA,data)
}

export const getQuotePrice = async (page, limit, sort) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order/quote-pagination?page=${page}&limit=${limit}&sort=${sort}`;
  try {
    const res = await axios({
      method: "get",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error in getQuotePrice", err);
    if (err) return {ok: false};
  }
}

export const deleteQuote = async (order_id) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/customer-page/delete-quote?order_id=${order_id}`;
  try {
    const res = await axios({
      method: "delete",
      url,
      headers,
    });
    return res.data;
  } catch (err) {
    console.log("Error in delete Quote", err);
    if (err) return {ok: false};
  }
}

export const getCompletion = async (limit, page, view, department_id, order_number, employee_id, selectedUser,current_process, orderProcessId) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  let url = `${API}/system/order/get-completion?limit=${limit}&page=${page}&view=${view}&department_id=${department_id}&order_number=${order_number}&employee_id=${employee_id}&user_id=${selectedUser}&current_process=${current_process}`;
  if (orderProcessId) {
    url += `&order_process_id=${orderProcessId}`
  }
  return axios({
    method: "get",
    url,
    headers
  }).then(res => res?.data || []).catch(err => err);
} 


export const getOrderReports = async ({
  limit,
  page,
  type,
  orderId,
}) => {
  const token_from_cookie = getCookie("login_cookie");
  const headers = {
    "access-token": token_from_cookie,
  };
  const url = `${API}/system/order/report`;
  const params = {
    page,
    limit,
  };

  if (type && type !== 'all') {
    params.type = type;
  }

  if (orderId) {
    params.orderNumber = orderId;
  }
  
  return axios({
    method: "get",
    url,
    headers,
    params,
  })
    .then((res) => res.data || null)
    .catch((err) => err);
}; 

