import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../../Buttons/Button';
import Input from '../../../InputFields/Input';
import SelectInput from '../../../InputFields/SelectInput/SelectInput';
import "../AddSystem/AddSystemPopUp.scss";
import { POP_UPS_TRANSLATION } from '../../../../../constants/translations/customer/PopUps/PopUps';
import { useDispatch, useSelector } from 'react-redux';
import { taskActionsCreator } from '../../../../../actions/customer/taskActions';
import { TASK_ERRORS } from '../../../../../constants/translations/customer/errors/errorMessage';
import moment from 'moment';
import { add, cancel, addBtnDesktop, cancelBtnDesktop, addBtnMobile, cancelBtnMobile, addTaskInputs } from "./AddTaskPopupStatic";


export default function AddTaskPopUp(props) {
    const { handleShowPopUp, view, name, process, mode, taskId } = props;

    const dispatch = useDispatch();
    const taskToEdit = useSelector((state) => state.tasks.singleTask);

    const [inputValue, setInputValue] = useState({
        taskName: mode === 'edit' ? taskToEdit.fileName : '',
        taskNameError: false,
        dueDate: mode === 'edit' ? moment(taskToEdit.dueDate).format("YYYY-MM-DD") : '',
        dueDateError: false,
        resourceName: mode === 'edit' ? taskToEdit.resourceName : '',
        resourceNameError: false,
        status: mode === 'edit' ? taskToEdit.fileType : '',
        statusError: false
    });

    const isFormInputDataValid = ({
        name,
        value,
    }) => {
        let _isValid = true;
        const errors = {};
        if (name === 'taskName' || typeof name === 'undefined') {
            const isValid = isTaskNameValid(value || inputValue.taskName);
            errors.taskNameError = !isValid;
            _isValid = _isValid && isValid;
        }
        if (name === 'dueDate' || typeof name === 'undefined') {
            const isValid = isDueDateValid(value || inputValue.dueDate);
            errors.dueDateError = !isValid;
            _isValid = _isValid && isValid;
        }
        if (name === 'resourceName' || typeof name === 'undefined') {
            const isValid = isResourceNameValid(value || inputValue.resourceName);
            errors.resourceNameError = !isValid;
            _isValid = _isValid && isValid;
        }
        if (name === 'status' || typeof name === 'undefined') {
            const isValid = isStatusValid(value || inputValue.status);
            errors.statusError = !isValid;
            _isValid = _isValid && isValid;
        }

        if (Object.keys(errors).length > 0) {
            setInputValue((prev) => ({
                ...prev,
                ...errors
            }));
        }

        return _isValid;
    }

    const isTaskNameValid = () => {
        if (inputValue.taskName.length < 4) {
            return false;
        }
        return true;
    }
    const isDueDateValid = (value) => typeof value !== 'undefined' ? !!value : !!inputValue.dueDate;
    const isResourceNameValid = (value) => typeof value !== 'undefined' ? !!value : !!inputValue.resourceName;
    const isStatusValid = (value) => typeof value !== 'undefined' ? !!value : !!inputValue.status;


    const handleChange = (name, value) => {
        if (inputValue[`${name}Error`]) {
            isFormInputDataValid({ name, value });
        }
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    const submitForm = () => {
        if (!isFormInputDataValid({})) {
            return;
        }
        const singleTask = {
            id: mode === 'edit' ? taskToEdit.id : "id" + Math.random().toString(16).slice(2),
            taskName: inputValue.taskName,
            dueDate: inputValue.dueDate,
            resourceName: inputValue.resourceName,
            status: inputValue.status
        }
        if (mode === 'edit') {
            dispatch(taskActionsCreator.editSelectedTaskByTaskId(singleTask))
        } else {
            dispatch(taskActionsCreator.addTaskToTasksListTablet(singleTask))
        }
        handleShowPopUp();
    }

    useEffect(() => {
        dispatch(taskActionsCreator.selectedTaskByTaskId(taskId))
    }, [taskId])

    let addTaskInputsToSend = {
        ...addTaskInputs,
        taskName: {
            ...addTaskInputs.taskName,
            onChange: handleChange,
            value: inputValue.taskName,
            placeholder: mode === 'edit' && taskToEdit && taskToEdit.fileName,
            error: inputValue.taskNameError,
            errorText: inputValue.taskNameError && TASK_ERRORS.TASK_NAME_REQUIRED
        },
        resourceName: {
            ...addTaskInputs.resourceName,
            selectOptions: process && process.resource,
            handleChange: handleChange,
            value: inputValue.resourceName || [{ name: 'resource 1' }],
            placeholder: mode === 'edit' && taskToEdit.resourceName && taskToEdit.resourceName.placeholder,
            error: inputValue.resourceNameError,
            errorText: inputValue.resourceNameError && TASK_ERRORS.RESOURCE_NAME_REQUIRED
        },
        status: {
            ...addTaskInputs.status,
            selectOptions: [{ name: process && process.finished === 0 ? 'Not Done' : 'Not Done', id: '1' }, { name: process && process.done === true ? 'Done' : 'Done', id: '2' }, { name: process && process.finished === process.quantity ? 'Finished' : 'Finished', id: '3' }, { name: process && process.finished > 0 ? 'In Progress' : 'In Progress', id: '4' }],
            handleChange: handleChange,
            value: inputValue.status,
            placeholder: mode === 'edit' && taskToEdit.fileType && taskToEdit.fileType.placeholder,
            error: inputValue.statusError,
            errorText: inputValue.statusError && TASK_ERRORS.STATUS_REQUIRED
        },
        dueDate: {
            ...addTaskInputs.dueDate,
            onChange: handleChange,
            value: inputValue.dueDate,
            placeholder: mode === 'edit' && taskToEdit && taskToEdit.dueDate,
            error: inputValue.dueDateError,
            errorText: inputValue.dueDateError && TASK_ERRORS.DATE_REQUIRED
        }
    }

    return (
        <Grid spacing={0} className='addSystem__main' style={{ left: view === 'desktop' && '15%' }}>
            <Grid xs={12}>
                <h4 className='addSystem__title'>{name}</h4>
            </Grid>
            <Grid className='addSystem__uploadedInfo'>
                <Grid xs={12} spacing={0} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.CUSTOMER_NAME}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>{process && process.client_name || POP_UPS_TRANSLATION.ZAIN_BATOR}</span></Grid>
                </Grid>
                <Grid xs={12} className='addSystem__uploadedInfo-row'>
                    <Grid xs={6} className='addSystem__uploadedInfo-label'><span>{POP_UPS_TRANSLATION.ORDER_NUMBER}</span></Grid>
                    <Grid xs={6} className='addSystem__uploadedInfo-value'><span>{process && process.order_number || 'S02100004B'}</span></Grid>
                </Grid>
            </Grid>
            <Grid sx={12} className='addSystem__inputsFields'>
                <Grid>
                    <Input data={addTaskInputsToSend.taskName} view={view} />
                </Grid>
                <Grid>
                    <SelectInput data={addTaskInputsToSend.resourceName} view={view} />
                </Grid>
                <Grid xs={12} className='addSystem__inputsRow'>
                    <Grid md={6} xs={12}>
                        <Input data={addTaskInputsToSend.dueDate} view={view} />
                    </Grid>
                    <Grid md={6} xs={12}>
                        <SelectInput data={addTaskInputsToSend.status} view={view} />
                    </Grid>
                </Grid>
            </Grid>
            {
                view === 'mobile' ? <Grid sx={12} className='addSystem__btn__container'>
                    <Button data={addBtnMobile} handelSubmit={submitForm} />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Button data={cancelBtnMobile} handleShowPopUp={handleShowPopUp} />
                </Grid>
                    : view === 'desktop' ? <Grid sx={12} className='addSystem__btn__container'>
                        <Button data={addBtnDesktop} handelSubmit={submitForm} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancelBtnDesktop} handleShowPopUp={handleShowPopUp} />
                    </Grid>
                        : <Grid sx={12} className='addSystem__btn__container'>
                            <Button data={add} handelSubmit={submitForm} />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Button data={cancel} handleShowPopUp={handleShowPopUp} />
                        </Grid>
            }
        </Grid>
    )
}
