/* eslint-disable react-hooks/exhaustive-deps */

import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Input from '../../Customer/InputFields/Input'
import SelectInput from '../../Customer/InputFields/SelectInput/SelectInput'
import Button from '../../Customer/Buttons/Button'
import CheckButton from '../../Customer/Buttons/CheckButton/CheckButton';
import "./OrderDetails.scss"
import { ADD, CANCEL } from '../../../constants/translations/review-popup'
import { CREATE_CUSTOMER_POPUP, CUSTOMERS_PAGE, QUOTE } from '../../../constants/translations/customersPage'
import { POP_UPS_TRANSLATION } from '../../../constants/translations/customer/PopUps/PopUps'
import { getOrderSystems } from '../../../functions/api/orders'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setOrderDataForPopup } from '../../../actions/mainPopup_actions'
import { generalGetRequest } from '../../../functions/api/general'

function formateDate(date){
    let newDate = moment(date).format("YYYY-MM-DD")
    return newDate
}

const proposalCheckBtn = {
    label: CUSTOMERS_PAGE.PROPOSAL,
    name: 'proposal',
    // name: 'started',
    width: '20%',
    isBoxShadow : true,
    border: true,
    isChecked: false,
}
const cancelReservationCheckBtn = {
    label: CUSTOMERS_PAGE.CANCEL_RESERVATION,
    name: 'cancelled',
    width: '20%',
    isBoxShadow: true,
    border: true,
    isChecked: false
}
const add = {
    name: ADD,
    width: '20%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#0091FF",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: false,
    isBoxShadow: true,
}
const cancel = {
    name: CANCEL,
    width: '20%',
    height: '52px',
    backgroundColor: '#fff',
    color: "#6C7389",
    fontSize: '16px',
    fontWeight: 800,
    icon: false,
    iconContainer: false,
    border: true,
    isBoxShadow: true,
}

const inputs = {
    orderDetails: [
        {
            label: CREATE_CUSTOMER_POPUP.CUSTOMER_NUMBER,
            field: 'Customer Number',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'customerNumber'
        },
        {
            label: POP_UPS_TRANSLATION.ORDER_NUMBER,
            field: 'Order Number',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'text',
            // name: 'orderNumber'
            name: 'order_number'
        },
        {
            label: CUSTOMERS_PAGE.ORDER_TYPE,
            field: 'Order type',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'select',
            name: 'typeOfReservation',
            // name: 'type'
        },
        {
            label: CUSTOMERS_PAGE.CATEGORY,
            field: 'Category',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'select',
            name: 'category',
        },
        {
            label: CUSTOMERS_PAGE.NUMBER_OF_DEVELOPERS,
            field: '# of developers',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'number',
            // name: 'noOfDevelopers'
            name: 'quantity',
        },
        {
            label: CUSTOMERS_PAGE.DELIVERY_DATE,
            field: 'Delivery date',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'date',
            name: 'due_date'
        },
    ],
    contactsAddress: [
        {
            label: CUSTOMERS_PAGE.CUSTOMER_NAME,
            field: 'Customer Name',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'client_name'
        },
        {
            label: CREATE_CUSTOMER_POPUP.CITY,
            field: 'City',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'city',
        },
        {
            label: CREATE_CUSTOMER_POPUP.ADDRESS,
            field: 'Address',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'address'
        },
        {
            label: QUOTE.PHONE_NUMBER,
            field: 'Phone number',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'client_phone'

        },
        {
            label: CREATE_CUSTOMER_POPUP.EMAIL,
            field: 'Email',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: 'Enter',
            type: 'email',
            name: 'client_email'
        },
    ],
    customerDetails: [
        {
            label: CUSTOMERS_PAGE.INSPECTOR_NAME,
            field: 'Inspector name',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'inspectorName'
        },
        {
            label: CUSTOMERS_PAGE.MOBILE_INSPECTOR,
            field: 'Mobile Inspector',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'mobileInspector'
        },
        {
            label: CUSTOMERS_PAGE.ARCHITECT_NAME,
            field: 'Architect name',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'text',
            name: 'architectName'
        },
        {
            label: CUSTOMERS_PAGE.MOBILE_ARCHITECT,
            field: 'Mobile Architect',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'mobileArchitect'
        },
        {
            label: CUSTOMERS_PAGE.PROJECT_MANAGER,
            field: 'Project manager',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'select',
            // name: 'projectManager'
            name: 'employees'
        },
        {
            label: CUSTOMERS_PAGE.SALES_REPRESENTATIVE,
            field: 'Sales Representative',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'select',
            name: 'salesRepresentative'
        },
    ],
    otherDetails: [
        {
            label: CUSTOMERS_PAGE.NUMBER_OF_SHUTTERS,
            field: 'Number of shutters',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            name: 'numberOfShutters'
        },
        {
            label: CUSTOMERS_PAGE.SETTLEMENT,
            field: 'Settlement',
            placeholder: POP_UPS_TRANSLATION.CHOOSE,
            value: '',
            type: 'select',
            name: 'settlement'
        },
        {
            label: CUSTOMERS_PAGE.TRANSACTION_VALUE,
            field: 'Transaction value',
            placeholder: POP_UPS_TRANSLATION.ENTER,
            value: '',
            type: 'number',
            // name: 'transactionValue'
            name: 'value'
        },

    ],
}

function getProjectMangerOfProject(projectManagersList, employeesList=[]){
    const projectManagersMap = {};
    const employeesMap = {};
    for (let pm = 0; pm < projectManagersList.length; pm++){
        if (!projectManagersMap[projectManagersList[pm]._id]){
            projectManagersMap[projectManagersList[pm]._id] = projectManagersList[pm]
        }
    }
    for (let e = 0; e < employeesList.length; e++) {
        if (!employeesMap[employeesList[e]._id]) {
            employeesMap[employeesList[e]._id] = employeesList[e]
        }
    }

    let projectManager; 
    for (let id in employeesMap){
        if (projectManagersMap[id]){
            projectManager = projectManagersMap[id]
        }else{
            projectManager = {}
        }
    }

    return projectManager
}

// doAction needs to be called
export default function OrderDetails(props) {
    const { handleShowPopUp, showPopUp, categoryOptions, typeOptions, updateFormData, updateFormDataCategory, 
        handleAddOrder, errors, projectManagers, handleClosePopup, orderId, updateFormDataCheckBox 
    } = props;
    const [orderDetailsOfForm, setOrderDetailsOfForm] = useState({})
    const [currentCustomer, setCurrentCustomer] = useState({customer_number: ''})

    const projectManager = getProjectMangerOfProject(projectManagers?.employees || [], orderDetailsOfForm.employees)

    const dispatch = useDispatch();

   async function getFormData(){
        const formDataRes = await getOrderSystems(orderId)
       dispatch(setOrderDataForPopup(formDataRes.result))
       setOrderDetailsOfForm(formDataRes.result)
    }

    
    useEffect(() => {
        getFormData()
    }, [orderId])

    async function getCustomersList(currentFactory_id){
     const {result} = await generalGetRequest(`/system/order-systems/get-customers-list?factory_id=${currentFactory_id}`)
         // or we can match by  email, in some the cases name and number both are same
        const customer = result ? result.find((_customer) => _customer._id === orderDetailsOfForm.customer_id) : {}
        if (customer) setCurrentCustomer(customer);
    }
    useEffect(() => {
        getCustomersList(orderDetailsOfForm.factory_id)
     }, [orderDetailsOfForm.factory_id])

    return (
        <Grid className='orderDetails__main'>
            <Grid xs={12} className='orderDetails__checkBtnContainer'>
                <CheckButton 
                data={{ 
                    ...cancelReservationCheckBtn, 
                    isChecked: orderDetailsOfForm.cancelled,
                    updateFormDataCheckBox: updateFormDataCheckBox 
                    }} />
                &nbsp;
                &nbsp;
                <CheckButton 
                data={{ 
                    ...proposalCheckBtn, 
                    isChecked: !orderDetailsOfForm.started,
                    updateFormDataCheckBox: updateFormDataCheckBox 
                    }} />
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.ORDER_DETAILS}</span></Grid>
      
                <Grid className='orderDetails__inputRow__inputs'>
                    {
                        inputs.orderDetails?.map((input, index) => {
                            if (input.type === 'select') {
                                if (input.field === 'Category') {
                                    return <Grid xs={2} key={index} style={{ marginLeft: '4px' }}>
                                    <SelectInput 
                                    data={{
                                        ...input, 
                                    value: (categoryOptions.length && orderDetailsOfForm.order_category_id) ? categoryOptions?.find((_category) => _category._id === orderDetailsOfForm?.order_category_id) : '',
                                    selectOptions: categoryOptions, 
                                    handleChange: updateFormData, 
                                    error: !!(errors?.category && errors.category.length), 
                                    errorText: errors?.category 
                                    }} 
                                    />
                                    </Grid>
                                } else if (input.field === 'Order type') {
                                    
                                    return <Grid xs={2} key={index} style={{marginLeft: '4px'}}>
                                        <SelectInput
                                            data={{
                                                ...input,
                                                value: orderDetailsOfForm.type ? orderDetailsOfForm.type : '',
                                                // placeholder: orderDetailsOfForm.type?.name,
                                                selectOptions: typeOptions,
                                                handleChange: updateFormData,
                                            }}
                                        />
                                    </Grid>
                                }
                                return <Grid xs={2} key={index}><SelectInput data={ input } /></Grid>
                            }else{
                                if (input.field === 'Order Number'){
                                    return <Grid xs={2} key={index}>
                                        <Input
                                            data={{
                                                ...input,
                                                value: orderDetailsOfForm.order_number,
                                                onChange: updateFormData,
                                                // disabled : true,
                                                error: !!(errors[input.name] && errors[input.name].length),
                                                errorText: errors[input.name],
                                            }}
                                        /></Grid>
                                } else if (input.field === 'Delivery date') {
                                    return <Grid xs={2} key={index}>
                                        <Input
                                            data={{
                                                ...input,
                                                value: formateDate(orderDetailsOfForm.due_date),
                                                onChange: updateFormData
                                            }} /></Grid>
                                } else if (input.field === '# of developers'){
                                    return <Input
                                        key={index}
                                        data={{
                                            ...input,
                                            value: orderDetailsOfForm?.quantity, 
                                            error: !!(errors[input.name] && errors[input.name].length),
                                            errorText: errors[input.name],
                                            onChange: updateFormData
                                        }}
                                    />
                                } else if (input.field === 'Customer Number') {
                                    return <Input
                                        key={index}
                                        data={{
                                            ...input,
                                            value: currentCustomer.customer_number ? currentCustomer.customer_number : '',
                                            error: !!(errors[input.name] && errors[input.name].length),
                                            errorText: errors[input.name],
                                            onChange: updateFormData
                                        }}
                                    />
                                }
                                else{
                                    return <Grid xs={2} key={index}><Input data={input} /></Grid>
                                }
                            }
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.CONTACT_AND_ADDRESS}</span></Grid>
                <Grid className='orderDetails__inputRow__inputs'>
                    {
                        inputs.contactsAddress.map((input, index) => {
                            if (input.field === 'Customer Name'){
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, 
                                        value: orderDetailsOfForm.client_name, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'City') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input,
                                        value: orderDetailsOfForm.city,
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'Phone number') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, 
                                        value: orderDetailsOfForm.client_phone, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'Email') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, value: orderDetailsOfForm.client_email, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            } else if (input.field === 'Address') {
                                return <Input
                                    key={index}
                                    data={{
                                        ...input, 
                                        error: !!(errors[input.name] && errors[input.name].length),
                                        errorText: errors[input.name],
                                        onChange: updateFormData
                                    }}
                                />
                            }
                            return <Input key={index} data={{ ...input, error: !!(errors[input.name] && errors[input.name].length), errorText: errors[input.name], handleChange: updateFormData  }} />
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.CUSTOMER_DETAILS}</span></Grid>
                <Grid className='orderDetails__inputRow__inputs'>
                    {
                        inputs.customerDetails.map((input, index) => {
                            if (input.type === 'select') {
                                if (input.field === 'Project manager') {
                                    return <Grid xs={2} key={index} style={{ marginLeft: '4px' }}>
                                        <SelectInput
                                            data={{
                                                ...input,
                                                // value: projectManager? projectManager : '',
                                                selectOptions: projectManagers?.employees || [],
                                                handleChange: updateFormData,
                                                error: !!(errors[input.name] && errors[input.name].length),
                                                errorText: errors[input.name],
                                            }}
                                        />
                                    </Grid>
                                }
                                return <Grid xs={2} key={index} style={{ marginLeft: '4px' }}>
                                <SelectInput 
                                        data={{
                                            ...input,
                                            selectOptions: [{ _id: 1, name: 'sales-representative-1' }],
                                            handleChange: updateFormData,
                                        }} />
                                </Grid>
                            }
                            return <Grid xs={2} key={index}>
                                <Input
                                    data={{
                                        ...input,
                                        onChange: updateFormData,
                                        error: false
                                    }} />
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__inputRow'>
                <Grid className='orderDetails__inputRow__rowName'><span>{CUSTOMERS_PAGE.OTHER_DETAILS}</span></Grid>
                <Grid className='orderDetails__inputRow__inputs' style={{ display: 'flex', justifyContent: 'right' }}>
                    {
                        inputs.otherDetails.map((input, index) => {
                            if (input.type === 'select') {
                                return <Grid xs={4} key={index} style={{ marginLeft: '4px' }}>
                                <SelectInput 
                                data={{
                                    ...input,
                                    selectOptions: [{_id: 1, name: 'amendment-1'}],
                                    handleChange: updateFormData,
                                    }} 
                                />
                                </Grid>
                            }else {
                                if (input.field === 'Transaction value'){
                                    return <Grid xs={3} key={index}>
                                    <Input 
                                    data={{ 
                                        ...input, 
                                        value: orderDetailsOfForm.value, 
                                        error: !!(errors[input.name] && errors[input.name].length), 
                                        errorText: errors[input.name], 
                                        onChange: updateFormData 
                                        }} />
                                    </Grid>
                                }else{
                                    return <Grid xs={3} key={index}>
                                    <Input 
                                    data={{
                                        ...input,
                                        onChange: updateFormData,
                                        error: false
                                    }} /></Grid>
                                }
                            }
                           
                        })
                    }
                </Grid>
            </Grid>
            <Grid xs={12} className='orderDetails__btn__Container addOrderFooter'>
                <Button data={add} handleShowPopUp={()=>{
                    handleAddOrder()
                }} />
                &nbsp;
                &nbsp;
                &nbsp;
                <Button data={cancel} handleShowPopUp={handleClosePopup} />
            </Grid>
        </Grid>
    )
}
