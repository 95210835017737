import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import "./Strap.scss"
import { useDispatch, useSelector } from "react-redux";
import SelectInput from '../../InputFields/SelectInput/SelectInput'
import DetailsNavigation from '../../CustomerDetails/DetailsNavigation/DetailsNavigation';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddOrderPopUp from '../../../AddOrder/AddOrderPopUp/AddOrderPopUp'
import OrderFormNewPopup from  '../../../Header/Parts/ReservationPopup/orderFormNewPopup'
import {
  showMainPopup,
} from "../../../../actions/mainPopup_actions";
import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';
import { PROJECT_MANAGER } from '../../../../constants/translations/updateProcessPopUp';

const backBtn = {
  name: DESKTOP_VIEW_TRANSLATION.BACK, 
  width: '100px',
  color: '#fff',
  backgroundColor: "#3C93D6",
  fontSize: '16px',
  fontWeight: 800,
  icon: true,
  iconContainer: false,
  border: false,
  isBoxShadow: false,
  buttonName: 'ArrowBackIosIcon'
}
const addOrder = {
  name: DESKTOP_VIEW_TRANSLATION.ADD_AN_ORDER, 
  width: '100%',
  height: '36px',
  color: '#fff',
  backgroundColor: "#0091FF",
  fontSize: '16px',
  fontWeight: 800,
  icon: true,
  iconContainer: false,
  border: false,
  isBoxShadow: false,
  buttonName: 'AddIcon',
  padding: '5px 10px',
  direction: 'rtl',
}
const navigationData = {
  navigationFor: 'viewBy',
  navigationTab: [
    {
      label: DESKTOP_VIEW_TRANSLATION.DAILY, 
    },
    {
      label: DESKTOP_VIEW_TRANSLATION.WEEKLY, 
    },
    {
      label: DESKTOP_VIEW_TRANSLATION.MONTHLY, 
    },
    {
      label: DESKTOP_VIEW_TRANSLATION.ANNUAL, 
    },
  ]
}

const projectManager = {
  label: '',
  placeholder: PROJECT_MANAGER,
  value: 'Project manager',
  type: 'select',
  width: '100%',
  height: '36px'
}

export default function TopStrap(props) {
  const [isViewInfo, setIsViewInfo] = useState(props.data);
  const [showPopUp, setShowPopUp] = useState(false);
  const mainPopupSlice = useSelector((state) => state.mainPopup);
  const dispatch = useDispatch();


  function changeSelectionTab() {

  }

  function handleShowPopUp() {
    setShowPopUp(!showPopUp)
    dispatch(showMainPopup(!mainPopupSlice.show_popup));

  }

  return (
    <>
      <Grid container spacing={0} className='strap__main'>
        {
          isViewInfo ?
            <>
              <Grid xs={12} md={6} className='strap__colLeft'>
                <Grid xs={4} md={3}>
                  <Button data={addOrder} handleShowPopUp={handleShowPopUp} />
                </Grid>
                &nbsp;
                &nbsp;
                &nbsp;
                <Grid xs={4} md={3} className='strap__select'>
                  <SelectInput data={projectManager} />
                </Grid>
                &nbsp;
                &nbsp;
                <Grid xs={4} md={3} className='strap__select'>
                  <SelectInput data={projectManager} />
                </Grid>
              </Grid>
              <Grid xs={12} md={6} className='strap__colRight'>
                <span className='strap__iconContainer'>
                  <AssignmentIndIcon className='strap__icon' />
                </span>
                <DetailsNavigation data={navigationData} tabNumber={0} changeTab={changeSelectionTab} />
              </Grid>
            </>
            : <div style={{ height: '36px', marginBottom: '3px' }}>
              <Button data={backBtn} />
            </div>
        }

{
    mainPopupSlice.show_popup ? <div className='popUp__background'></div> : null
}

        {mainPopupSlice.show_popup ? (
          <div className='addOrderPopup__position'>
            <OrderFormNewPopup
              closePopup={() => {
                handleShowPopUp(false);
              }}
            />
          </div>
        ) : null}
      </Grid>
      {
        mainPopupSlice.showPopUp ? <AddOrderPopUp handleShowPopUp={handleShowPopUp} /> : null
      }
    </>
  )
}
